// 封装请求
import axios from 'axios'
import { Toast } from 'vant'
import { getUrlParam } from '../getUrl'

// 请求
const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 10000
})
// 请求拦截
http.interceptors.request.use(config => {
  //请求头设置
  // let token = localStorage.getItem('token') || ''
  // config.headers.Authorization = token
  return config
}, err => {
  console.log(err);
})
// 响应拦截
http.interceptors.response.use(response => {
  const data = response.data;

  switch (data.code) {
    // case 210:
    //   Toast(data.message);
    //   break;
    // // 错误
    // case 500:
    //   Toast("联系管理员");
    //   break;
    // default:
    //   return data;
  }
  return data;
}, (err) => {
  // let url = window.location
  // let code = getUrlParam(url, 'code')
  // let corpid = getUrlParam(url, 'organization')
  // let token = window.sessionStorage.getItem('token')
  // let getUrl = window.location.href
  // console.log(err.response.status)
  // if (err.response.status == 401) {
  //   const params = {
  //     code: code,
  //   }
  //   http.post(`/enterprise/auth/login/${corpid}`, params).then((res) => {
  //     console.log(res.data.access_token)
  //     window.sessionStorage.removeItem('token')
  //     window.sessionStorage.setItem('token', res.data.access_token)
  //     const corpid = window.sessionStorage.getItem('corpid')
  //     let urlparams = {
  //       url: getUrl,
  //     }
  //     http
  //       .post(`/enterprise/auth/sign/app`, urlparams, {
  //         headers: { Authorization: `bearer ${res.data.access_token}` },
  //       })
  //       .then((res) => {
  //         wx.agentConfig({
  //           corpid: corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
  //           agentid: '1000002', // 必填，企业微信的应用id （e.g. 1000247）
  //           timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
  //           nonceStr: res.data.data.nonce_str, // 必填，生成签名的随机串
  //           signature: res.data.data.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
  //           jsApiList: [
  //             'selectExternalContact',
  //             'getCurExternalContact',
  //             'sendChatMessage',
  //             'openUserProfile',
  //             'sendChatMessage',
  //           ], //必填，传入需要使用的接口名称
  //           success: function () {
  //             // 回调
  //             wx.invoke('getCurExternalContact', {}, function (res) {
  //               if (res.err_msg == 'getCurExternalContact:ok') {
  //                 console.log(res)
  //                 window.sessionStorage.setItem('userid', res.userId)
  //                 window.location.reload()
  //               } else {
  //                 //错误处理
  //               }
  //             })
  //           },
  //           fail: function (res) {
  //             if (res.errMsg.indexOf('function not exist') > -1) {
  //               alert('版本过低请升级')
  //             }
  //           },
  //         })
  //       })
  //       .catch(() => { })
  //   })
  // }


  return Promise.reject(err.response);
});

// 返出
export default http
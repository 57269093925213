<template>
  <div class="wraps"></div>
</template>

<script>
// eslint-disable-next-line
import { defineComponent, ref, computed, onMounted } from 'vue'
import axios from 'axios'
// eslint-disable-next-line
import http from '../utils/http/http'
import { logins } from '../utils/http/logins'

import { getUrlParam } from '../utils/getUrl'

export default defineComponent({
  name: 'Script',
  props: {
    msg: String,
    id: String,
    key: Number,
  },
  components: {},
  // eslint-disable-next-line
  setup(props) {
    onMounted(() => {
      geturlId()
    })

    let wx = window.wx
    const token = window.localStorage.getItem('token')
    const htmlUrl = ref('')
    let getUrl = window.location.href
    const corpid = window.localStorage.getItem('corpid')
    let urlparams = {
      url: getUrl,
    }
    const agentid = window.localStorage.getItem('agentid')

    axios
      .post(`/api/enterprise/auth/sign/app`, urlparams, {
        headers: { Authorization: `bearer ${token}` },
      })
      .then((res) => {
        wx.agentConfig({
          corpid: corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid: agentid, // 必填，企业微信的应用id （e.g. 1000247）
          timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.data.nonce_str, // 必填，生成签名的随机串
          signature: res.data.data.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
          jsApiList: ['getCurExternalContact', 'sendChatMessage', 'openEnterpriseChat'], //必填，传入需要使用的接口名称
          success: function () {
            // 回调
            console.log('啊啊啊啊啊啊啊')
            wx.invoke('getCurExternalContact', {}, function (res) {
              if (res.err_msg == 'getCurExternalContact:ok') {
                window.localStorage.setItem('userid', res.userId)
              }
            })
          },
          fail: function (res) {
            console.log('useridfail')
            console.log(res)
            if (res.errMsg.indexOf('function not exist') > -1) {
              alert('版本过低请升级')
            }
          },
        })
      })
      .catch((err) => {
        console.log(err.response)
        logins()
        if (err.response.data.code == 401) {
          logins()
        }
      })
    const active = ref(0)
    const dump_userid = ref('')
    const dump_external_user_id = ref('')
    function geturlId() {
      let url = window.location
      dump_userid.value = getUrlParam(url, 'userid')
      dump_external_user_id.value = getUrlParam(url, 'external_user_id')
      wx.openEnterpriseChat({
        // 注意：userIds和externalUserIds至少选填一个。内部群最多2000人；外部群最多500人；如果有微信联系人，最多40人
        userIds: dump_userid.value, //参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
        externalUserIds: dump_external_user_id.value, // 参与会话的外部联系人列表，格式为userId1;userId2;…，用分号隔开。
        groupName: '', // 会话名称。单聊时该参数传入空字符串""即可。
        chatId: '', // 若要打开已有会话，需指定此参数。如果是新建会话，chatId必须为空串
        success: function (res) {
          var chatId = res.chatId //返回当前群聊ID，仅当使用agentConfig注入该接口权限时才返回chatId
          // 回调
        },
        fail: function (res) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级')
          }
        },
      })
    }
    let dumpId = ref(1)
    setTimeout(close(), 1000)

    function close() {
      if (location.href.indexOf('#') == -1) {
        location.href = location.href + '#'
        location.reload()
      }
    }

    return { active, dump_userid, dump_external_user_id, dumpId, geturlId }
  },
})
</script>

<style scoped>
.wraps {
  width: 100%;
  height: 100%;
  background: #f4f7f8;
}
</style>

<style>
.aaa {
  width: 100%;
  height: 100%;
  background: #f4f7f8;
  position: fixed;
  top: 0;
}
/* 。 .van-tabs__content {
  margin-top: 100px;
} */
:root {
  --van-tabs-bottom-bar-color: #3b0aee !important;
}
</style>

<template>
  <div>
    <div class="back">
      <div class="back-left" @click="close">
        <van-icon name="arrow-left" />
        返回
      </div>
      <div class="back-right"><div class="get" @click="open">完成</div></div>
    </div>

    <div class="content">
      <div>跟进方式<span class="startIcon">*</span></div>
      <div>
        <van-field
          v-model="result"
          is-link
          readonly
          name="picker"
          placeholder="请选择跟进方式"
          @click="showPicker = true"
        />
        <van-popup v-model:show="showPicker" position="bottom">
          <van-picker :columns="columns" @confirm="onConfirm" @cancel="showPicker = false" />
        </van-popup>
      </div>
    </div>
  </div>

  <div class="content">
    <div>跟进内容<span class="startIcon">*</span></div>
    <div>
      <van-cell-group inset>
        <van-field v-model="content" type="textarea" placeholder="请输入备注" autosize
      /></van-cell-group>
    </div>
  </div>

  <div class="content">
    <div class="upImg">
      <div>上传图片</div>
      <div><van-icon name="photo-o" size="24px" /></div>
    </div>
    <van-uploader
      v-model="fileList"
      multiple
      style="margin-top: 10px"
      max-count="1"
      :after-read="getFile"
    />
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import axios from 'axios'
import { Toast } from 'vant'

export default defineComponent({
  emits: ['closeFollow'],
  props: {
    key: Number,
  },
  setup(props, ctx) {
    const token = window.localStorage.getItem('token')
    const fileList = ref([])
    const result = ref('')
    const showPicker = ref(false)
    const columns = ['企微联系', '电话联系', '其他跟进']
    const content = ref()
    const onConfirm = (value) => {
      result.value = value
      showPicker.value = false
    }

    function close() {
      ctx.emit('closeFollow')
    }
    function open() {
      if (result.value && content.value) {
        let params = {
          customer_id: window.localStorage.getItem('customer_id'),
          content: content.value,
          title: result.value,
        }
        axios
          .post(`/api/enterprise/addCustomerFollow`, params, {
            headers: { Authorization: `bearer ${token}` },
          })
          .then((res) => {
            console.log(res)
            if (res.data.message == 'success') {
              Toast.success('添加成功')
              ctx.emit('closeFollow')
            }
          })
          .catch((err) => {
            if (err.response.data.code == 401) {
              logins()
            } else {
              Toast(err.response.data.message)
            }
          })
      } else {
        Toast.fail('填写后才可以完成哦')
      }
    }

    function getFile(file, detail) {
      var data = new FormData()
      data.append('file', file.file)
      let config = {
        headers: {
          //添加请求头
          'Content-Type': 'multipart/form-data',
        },
      }
      //上传图片
      axios.post('/api/upload/imgage', data, config).then((res) => {
        console.log('res', res)
      })
    }
    // getBase64(imgUrl, (dataURL) => {
    //   console.log('dataURL :>> ', dataURL)
    // })

    // function getBase64(url, callback) {
    //   var Img = new Image(),
    //     dataURL = ''
    //   Img.src = url + '?v=' + Math.random()
    //   Img.setAttribute('crossOrigin', 'Anonymous')
    //   Img.onload = function () {
    //     var canvas = document.createElement('canvas'),
    //       width = Img.width,
    //       height = Img.height
    //     canvas.width = width
    //     canvas.height = height
    //     canvas.getContext('2d').drawImage(Img, 0, 0, width, height)
    //     dataURL = canvas.toDataURL('image/jpeg')
    //     return callback ? callback(dataURL) : null
    //   }
    // }

    return {
      result,
      token,
      columns,
      onConfirm,
      showPicker,
      close,
      open,
      fileList,
      getFile,
      content,
    }
  },
})
</script>

<style scoped>
.back {
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  box-sizing: border-box;
  background: #fff;
}

.back-left {
  height: 28px;
  width: 45%;
  text-align: left;
  line-height: 28px;
}

.back-right {
  width: 50%;
  display: flex;
  justify-content: end;
}

.get {
  text-align: center;
  color: white;
  width: 50px;
  height: 28px;
  line-height: 28px;
  background: #0052fe;
  border-radius: 5px;
}

.content {
  padding: 10px 20px;
  margin-bottom: 16px;
  background: #fff;
}

.startIcon {
  color: red;
}

.van-cell {
  padding: 10px 0;
  color: #b4b4b4;
}

.van-cell::after {
  content: '';
  border-bottom: none !important;
}

.upImg {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

ul {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

ul li {
  position: relative;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  margin-bottom: 14px;
}

ul li > img {
  width: 40px;
  height: 40px;
}

ul li > span {
  background: #0052fe;
  color: white;
  border-radius: 50%;
  transform: scale(0.6);
  padding: 5px;
  box-sizing: border-box;
  position: absolute;
  right: -12px;
  top: -12px;
}
</style>

<template>
  <div class="wraps">
    <div class="section">
      <div class="usermsg">
        <div class="userimg">
          <img :src="wechatimg" alt="" />
        </div>
        <div class="username">
          <div class="hosname">
            {{ info_msg ? (info_msg.name ? info_msg.name : info_msg.wechat_name) : '' }}
          </div>
          <div class="wechatname">
            <img src="../assets/wechat.png" alt="" />
            <div class="name">{{ wechatname }}</div>
          </div>
          <div class="time">最后跟进:{{ follow_time }}</div>
        </div>
      </div>
      <div class="line"></div>
      <div class="genjin" @click="getgenjin">
        <div class="genjin-left">
          跟进:
          <div v-for="(item, index) in peopleArrayImg">
            <img :src="item.avatar" alt="" />
          </div>
        </div>
        <!-- <div class="genjin-right">
          0个群聊
          <van-icon name="arrow" />
        </div> -->
      </div>
    </div>
    <div class="check">
      <div class="check-left">
        <div class="circle">
          <div class="circle-title" @click="check('stage')">
            <div class="xs">{{ stage }}</div>
            <div class="kf">客户阶段</div>
          </div>
          <div class="circle-line">
            <van-circle
              v-model:current-rate="currentRate"
              :color="gradientColor"
              :rate="rate"
              :stroke-width="100"
              :speed="100"
            />
          </div>
        </div>
        <div class="doctormsg">
          <div class="sort-line"></div>
          <div class="doctor" @click="check('doctor')">
            <div class="doctor-name">{{ doctorName }}</div>
            <div class="doctor-title">咨询医生</div>
          </div>
          <div class="sort-line"></div>
        </div>
      </div>
      <div class="check-right">
        <div class="intention">
          <div class="intention-name" @click="check('intention')">
            <van-tag plain type="primary"> {{ intention }}</van-tag>
          </div>
          <div class="intention-title">意向</div>
        </div>
      </div>
    </div>

    <div class="tabs">
      <van-tabs>
        <van-tab title="画像">
          <Portrait :info="info_msg" v-if="timeshow"></Portrait>
        </van-tab>
        <van-tab title="旅程">
          <Journey></Journey>
        </van-tab>
        <van-tab title="收费记录">
          <ChargeCopy :closebtn="closebtn" :key="Math.random().toString(36)"></ChargeCopy>
          <!-- <Register v-else @goback="goback"></Register> -->
        </van-tab>
        <van-tab title="挂号记录">
          <RegisterList :closebtn="closebtn" :key="Math.random().toString(36)"></RegisterList>

          <!-- <Register v-else @goback="goback"></Register> -->
        </van-tab>
      </van-tabs>
    </div>
    <div class="fotter">
      <div class="left" @click="Need">
        <div class="icon"><van-icon name="todo-list-o" /></div>
        待办
      </div>
      <div class="right" @click="followUp">
        <div class="icon"><van-icon name="add-o" /></div>
        写跟进
      </div>
    </div>
  </div>
  <van-popup v-model:show="show" position="bottom">
    <van-picker
      title="客户阶段"
      :columns="current"
      @confirm="onConfirm"
      @cancel="onCancel"
      v-show="type == 'stage'"
    />
    <van-picker
      title="咨询医生"
      :columns="docotrList"
      @confirm="onConfirmDoctor"
      @cancel="onCancel"
      v-show="type == 'doctor'"
    />
    <van-picker
      title="意向"
      :columns="intentionList"
      @confirm="onConfirmIntention"
      @cancel="onCancel"
      v-show="type == 'intention'"
    />
  </van-popup>

  <!-- 待办 -->

  <van-overlay :show="showNeed" style="z-index: 999">
    <div class="wrapper" style="z-index: 999">
      <div class="block">
        <Need @closeNeed="closeNeed" :key="Math.random().toString(36)"></Need>
      </div>
    </div>
  </van-overlay>

  <!-- 写跟进 -->
  <van-overlay :show="showFollowUp" style="z-index: 999">
    <div class="wrapper" style="z-index: 999">
      <div class="block">
        <FollowUp @closeFollow="closeFollow" :key="Math.random().toString(36)"></FollowUp>
      </div>
    </div>
  </van-overlay>

  <van-overlay :show="showStaffList">
    <div class="wrapper" @click.stop>
      <div class="block">
        <div class="staff">
          <div class="stafftitle">
            <div @click="closestaff"><van-icon name="arrow-left" />请选择同事</div>
            <div class="overstaff" @click="staffjc">完成</div>
          </div>
          <div class="stafflist" v-for="(item, index) in stafflist">
            <div class="staffimg"><img src="../assets/员工.png" alt="" /></div>
            <div class="staffname">{{ item.name }}</div>
            <div @click="checkstaff(index)" class="staffcheck">
              <van-radio-group v-model="checked">
                <van-radio :name="index"></van-radio>
              </van-radio-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </van-overlay>
  <van-popup v-model:show="showgenjin" position="bottom" round :style="{ height: '32%' }">
    <div class="genjinwarp">
      <div class="closegenjin" @click="closegenjin"><van-icon name="cross" /></div>
      <div class="genjinmsg" v-for="(item, index) in peole">
        <div class="peolemsg">
          <div class="peoleimg">
            <img :src="item.avatar" alt="" />
          </div>
          <div class="peolename">
            <div>{{ item.mcn_user_name }}</div>

            <div>{{ item.created_at }}添加好友</div>
          </div>
        </div>
        <div class="zhuanjie" @click="zhuanjie" v-if="item.is_my">转接客户</div>
      </div>
    </div>
  </van-popup>
</template>

<script>
// eslint-disable-next-line
import { defineComponent, ref, computed, onMounted } from 'vue'
import axios from 'axios'
import http from '../utils/http/http'
import { logins } from '../utils/http/logins'
import Portrait from '../component/portrait.vue'
import Journey from '../component/journey.vue'
import ChargeCopy from './chargeCpoy.vue'
import RegisterList from './registerList.vue'
import Register from './register.vue'
import { indexOf, isDate } from 'lodash'
import { restElement } from '@babel/types'
import FollowUp from '../component/followUp.vue'
import Need from '../component/need.vue'
import { Toast } from 'vant'
import { stringify } from 'qs'
import { getUrlParam } from '../utils/getUrl'

export default defineComponent({
  name: 'Script',
  props: {
    id: String,
  },
  components: {
    Portrait,
    Journey,
    RegisterList,
    FollowUp,
    Need,
    Register,
    ChargeCopy,
  },
  // eslint-disable-next-line
  setup(props, { emit }) {
    onMounted(() => {
      getCurrent()
      getDoctor()
      getIntention()
      // getgenjin()
      // getpeople()
      // getinfo()
    })

    console.log(props.id)
    const isNeed = ref(false)
    const showgenjin = ref()
    const current = ref([])
    const doctorList = ref([])
    const intentionList = ref([])
    const doctorName = ref('')
    const currentRate = ref(0)
    const type = ref('stage')
    const rate = ref(0)
    const stage = ref('线索')
    const intention = ref('请选择')
    const docotrList = ref([])
    let wx = window.wx
    const token = window.localStorage.getItem('token')
    const htmlUrl = ref('')
    const corpid = window.localStorage.getItem('corpid')
    const wechatimg = ref('')
    const wechatname = ref('')
    const options = ref([])
    const peole = ref()
    const checked = ref('0')
    let getUrl = window.location.href
    let urlparams = {
      url: getUrl,
    }
    const showStaffList = ref(false)
    const agentid = window.localStorage.getItem('agentid')
    const gradientColor = {
      '0%': '#3fecff',
      '100%': '#6149f6',
    }
    const num = ref(0)
    const show = ref(false)
    const userId = ref('')
    const id = ref('')
    const user_id = props.id
    const oldintentionList = ref([])
    const listCustomerStage = ref([])
    //写跟进
    const showFollowUp = ref(false)
    const showNeed = ref(false)

    //获取线索
    const getIntention = () => {
      let num = []

      axios
        .post(`/api/enterprise/listCustomerIntention`, '', {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          console.log(res)
          num = res.data.data.list
          oldintentionList.value = res.data.data.list
          num.filter((item) => {
            intentionList.value.push(item.title)
          })
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
    }
    const getCurrent = () => {
      let num = []
      axios
        .post(`/api/enterprise/listCustomerStage`, '', {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          num = res.data.data.list
          listCustomerStage.value = res.data.data.list
          let arr = []
          num.filter((item) => {
            current.value.push(item.title)
          })
          getRate()
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
    }
    //获取医生列表
    const getDoctor = () => {
      let params = {
        only_managed: 0,
      }
      axios
        .post('/api/enterprise/organizationDoctorList', params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          console.log(res)
          if (res.data.code == 200) {
            options.value = res.data.data

            docotrList.value = options.value.map((item) => {
              return item.doctor_name
            })
          }
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
    }
    const timeshow = ref(false)
    axios
      .post(`/api/enterprise/auth/sign/app`, urlparams, {
        headers: { Authorization: `bearer ${token}` },
      })
      .then((res) => {
        console.log(1, res)
        wx.agentConfig({
          corpid: corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid: agentid, // 必填，企业微信的应用id （e.g. 1000247）
          timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.data.nonce_str, // 必填，生成签名的随机串
          signature: res.data.data.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
          jsApiList: ['getCurExternalContact', 'sendChatMessage', 'openEnterpriseChat'], //必填，传入需要使用的接口名称
          success: function () {
            console.log(2, res)

            // 回调
            wx.invoke('getCurExternalContact', {}, function (res) {
              console.log(3, res)

              if (res.err_msg == 'getCurExternalContact:ok') {
                console.log(4, res)

                console.log('调咯')
                userId.value = res.userId
                window.localStorage.setItem('userid', res.userId)
                window.sessionStorage.setItem('userid', res.userId)
                timeshow.value = true
                getinfo()
                // geturlId()
              }

              let paramsss = {
                external_user_id: res.userId || window.localStorage.getItem('userid'),
              }
              axios
                .post('/api/enterprise/getExternalUserInfo', paramsss, {
                  headers: { Authorization: `bearer ${token}` },
                })
                .then((res) => {
                  if (res.data.code == 200) {
                    wechatimg.value = res.data.data.external_user_info.external_contact.avatar
                    wechatname.value = res.data.data.external_user_info.external_contact.name
                  }

                  console.log(res)
                })
            })
          },
          fail: function (res) {
            console.log(4, res)

            console.log('useridfail')
            console.log(res)
            if (res.errMsg.indexOf('function not exist') > -1) {
              alert('版本过低请升级')
            }
          },
        })
      })
      .catch((err) => {
        console.log(5, err.response)
        logins()
        if (err.response.data.code == 401) {
          logins()
        }
      })
    const follow_time = ref()
    const username = ref()
    const info_msg = ref()
    const getinfo = () => {
      let paramss = {
        external_user_id: userId.value || window.localStorage.getItem('userid'),
        // external_user_id: userId.value,
        // external_user_id: 'wmrlZ3PgAA4JPZEbqH8yIBTc4JPpy2Ww',
      }
      axios
        .post(`/api/enterprise/infoCustomer`, paramss, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          let info = res.data.data.info
          info_msg.value = JSON.parse(JSON.stringify(res.data.data.info))
          console.log(info_msg.value)
          window.localStorage.setItem('customer_id', info.id)
          window.sessionStorage.setItem('customer_id', info.id)

          isNeed.value = true
          // timeshow.value = true
          console.log(timeshow.value)
          id.value = info.id
          if (info.customer_intention) {
            intention.value = info.customer_intention.title
          }
          if (info.customer_stage) {
            stage.value = info.customer_stage.title
          }
          doctorName.value = info.doctor_name
          follow_time.value = info.follow_time
          username.vlaue = info.name ? info.name : info.wechat_name
          let nums = JSON.parse(JSON.stringify(current.value))
          if (info.customer_stage) {
            let title = info.customer_stage.title
            let a = findAllOccurrences(nums, title)
            let c = a[0]
            let lengthNew = 100 / nums.length

            currentRate.value = lengthNew * (Number(c) + 1)
          }

          getpeople()
        })
      // .catch((err) => {
      //   console.log(err.response)
      //   // if (err.response.data.code == 401) {
      //   //   logins()
      //   // } else {
      //   //   Toast(err.response.data.message)
      //   // }
      // })
    }

    const check = (value) => {
      console.log(value)
      show.value = true
      type.value = value
    }
    const onCancel = () => {
      show.value = false
    }
    const onConfirm = (value, index) => {
      stage.value = value
      currentRate.value = num.value * (index + 1)
      show.value = false

      let list = listCustomerStage.value
      let someUsers = list.filter((item) => item.title === value)
      let nums = JSON.parse(JSON.stringify(someUsers))
      console.log(list)
      let params = {
        customer_stage_id: nums[0].id,
        id: id.value,
      }
      axios
        .post(`/api/enterprise/editCustomer`, params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })

      console.log(currentRate.value)
    }
    const onConfirmDoctor = (value, index) => {
      doctorName.value = value

      show.value = false
      let list = options.value
      let someUsers = list.filter((item) => item.doctor_name === value)
      let nums = JSON.parse(JSON.stringify(someUsers))
      console.log(nums)
      let params = {
        doctor_id: nums[0].doctor_id,
        id: id.value,
      }
      axios
        .post(`/api/enterprise/editCustomer`, params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
    }
    //意向选择
    const onConfirmIntention = (value, index) => {
      let ids = ''
      let list = oldintentionList.value
      let someUsers = list.filter((item) => item.title === value)
      let nums = JSON.parse(JSON.stringify(someUsers))

      let params = {
        customer_intention_id: nums[0].id,
        id: id.value,
      }
      axios
        .post(`/api/enterprise/editCustomer`, params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
      intention.value = value
      show.value = false
    }

    const getRate = () => {
      num.value = 100 / current.value.length
    }
    const editeditCustomer = (obj) => {
      let params = obj
      axios
        .post(`/api/enterprise/editCustomer/`, params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {})
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
    }

    const findAllOccurrences = (arr, target) => {
      return arr.map((elem, index) => (elem === target ? index : -1)).filter((e) => e !== -1)
    }

    function followUp() {
      showFollowUp.value = true
    }

    function closeFollow() {
      showFollowUp.value = false
    }

    function Need() {
      showNeed.value = true
    }
    function closeNeed() {
      showNeed.value = false
    }

    const peopleArrayImg = ref()
    function getpeople() {
      let params = {
        customer_id: window.localStorage.getItem('customer_id') || id.value,
      }
      axios
        .post(`/api/enterprise/listCustomerFollowPeoPle`, params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          peopleArrayImg.value = res.data.data.list
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
    }
    function getgenjin() {
      showgenjin.value = true
      let params = {
        customer_id: window.localStorage.getItem('customer_id') || id.value,
      }
      axios
        .post(`/api/enterprise/listCustomerFollowPeoPle`, params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          console.log(res)
          peole.value = res.data.data.list
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
    }
    function closegenjin() {
      showgenjin.value = false
    }
    const stafflist = ref()
    const showkehu = ref(false)
    function zhuanjie() {
      showgenjin.value = false

      showStaffList.value = true

      axios
        .post(`/api/enterprise/staffList`, '', {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          console.log(res)
          stafflist.value = res.data.data.list
        })
        .catch((err) => {
          console.log(err)
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
    }

    function closestaff() {
      showStaffList.value = false
      checked.value = ''
    }

    const newuserid = ref()
    const newusername = ref()
    function checkstaff(index) {
      checked.value = index
      newuserid.value = stafflist.value[index].userid
      newusername.value = stafflist.value[index].name
    }
    function staffjc() {
      let params = {
        external_user_id: userId.value || window.localStorage.getItem('userid'),
        new_user_id: newuserid.value,
        new_user_name: newusername.value,
      }
      axios
        .post('/api/enterprise/transferCustomer', params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          if (res.data.code == 200) {
            Toast.success('转接成功')

            showStaffList.value = false
            checked.value = ''
          } else {
            console.log(res.data.code)
            console.log(res.data.message)
            let wenan = res.data.message
            Toast.fail(wenan)
          }
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
    }

    const dump_userid = ref('')
    const dump_external_user_id = ref('')

    function geturlId() {
      if (location.href.indexOf('#') == -1) {
        location.href = location.href + '#'
        location.reload()
      }
      let url = window.location
      dump_userid.value = getUrlParam(url, 'userid')
      dump_external_user_id.value = getUrlParam(url, 'external_user_id')
      wx.openEnterpriseChat({
        // 注意：userIds和externalUserIds至少选填一个。内部群最多2000人；外部群最多500人；如果有微信联系人，最多40人
        userIds: dump_userid.value, //参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
        externalUserIds: dump_external_user_id.value, // 参与会话的外部联系人列表，格式为userId1;userId2;…，用分号隔开。
        groupName: '', // 会话名称。单聊时该参数传入空字符串""即可。
        chatId: '', // 若要打开已有会话，需指定此参数。如果是新建会话，chatId必须为空串
        success: function (res) {
          var chatId = res.chatId //返回当前群聊ID，仅当使用agentConfig注入该接口权限时才返回chatId
          // 回调
        },
        fail: function (res) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级')
          }
        },
      })
    }
    const isshouf = ref(false)
    const isguahao = ref(false)

    // function getregisterDate(val) {
    //   isguahao.value = true
    //   console.log(1111)
    //   // window.location.href = url.href.replace('registerList', 'register')
    //   // ghlist.value = val
    // }

    // function chargeid(val) {
    //   isshouf.value = true
    //   // window.location.href = url.href.replace('charge', 'register')
    //   // console.log(val)
    // }
    function goback() {
      console.log(111222)
      window.sessionStorage.setItem('ischaege', true)

      isguahao.value = false
      isshouf.value = false
    }
    let url = window.location

    function chargeid(val) {
      window.location.href = url.href.replace('details', 'register')
      console.log(val)
    }
    function getregisterDate(val) {
      window.location.href = url.href.replace('details', 'register')
      // ghlist.value = val
    }

    const closebtn = ref(1)
    return {
      getpeople,
      closebtn,
      getregisterDate,
      url,
      chargeid,
      goback,
      getregisterDate,
      isshouf,
      isguahao,
      newuserid,
      newusername,
      stafflist,
      checkstaff,
      showStaffList,
      zhuanjie,
      showkehu,
      peole,
      closegenjin,
      showgenjin,
      getgenjin,
      peopleArrayImg,
      id,
      token,
      num,
      htmlUrl,
      getUrl,
      agentid,
      wechatimg,
      wechatname,
      currentRate,
      listCustomerStage,
      rate,
      gradientColor,
      current,
      // params,
      show,
      stage,
      doctorList,
      doctorName,
      type,
      intentionList,
      intention,
      userId,
      oldintentionList,
      docotrList,
      onConfirm,
      onCancel,
      getRate,
      check,
      onConfirmDoctor,
      onConfirmIntention,
      getCurrent,
      getDoctor,
      getIntention,
      editeditCustomer,
      findAllOccurrences,
      getinfo,
      followUp,
      showFollowUp,
      showNeed,
      closeFollow,
      Need,
      closeNeed,
      options,
      follow_time,
      getgenjin,
      closestaff,
      checked,
      staffjc,
      user_id,
      info_msg,
      timeshow,
      isNeed,
      dump_userid,
      dump_external_user_id,
      username,
    }
  },
})
</script>

<style scoped>
.staffimg {
  width: 30%;
  text-align: center;
}
.staffname {
  width: 50%;
}
.staffcheck {
  width: 15%;
}
.stafflist {
  width: 100%;
  display: flex;
  margin-top: 20px;
}
.staffimg img {
  width: 30px;
  height: 30px;
}
.staff {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 80px;
}
.stafftitle {
  width: 90%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-top: 10px;
}
.overstaff {
  width: 50px;
  height: 28px;
  background: #0052fe;
  border-radius: 5px;
  color: white;
  line-height: 28px;
  text-align: center;
}
.peolemsg {
  display: flex;
  margin-top: 20px;
  /* margin-bottom: 10px; */
}
.peoleimg {
  width: 30%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}
.peolename {
  width: 70%;
  margin-top: 20px;
}
.peoleimg img {
  width: 57px;
  height: 57px;
}
.genjinwarp {
  width: 100%;
}
.closegenjin {
  text-align: right;
  margin-top: 10px;
  margin-right: 30px;
}
.genjinmsg {
  width: 345px;
  /* height: 162px; */
  background: #ffffff;
  box-shadow: 0px 1px 5px 2px rgba(220, 220, 220, 0.5);
  border-radius: 12px;
  margin: 20px auto;
}
.zhuanjie {
  width: 305px;
  height: 40px;
  background: #0052fe;
  border-radius: 23px;
  text-align: center;
  line-height: 40px;
  color: white;
  margin: 0 auto;
  margin-bottom: 10px;
}
.wraps {
  width: 100%;
  background: #f4f7f8;
  height: 100vh;
  overflow-x: scroll;
}
.fotter {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #ccc;
  background: white;
  z-index: 2;
}

.left {
  width: 50%;
  text-align: center;
  font-size: 10px;
}
.right {
  text-align: center;
  font-size: 10px;
  width: 50%;
}
.section {
  width: 100%;
  height: 160px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background: #ffffff;
}
.usermsg {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.userimg {
  width: 20%;
}
.username {
  width: 70%;
  margin-left: 20px;
  display: flex;
  flex-wrap: wrap;
}
.userimg img {
  width: 70px;
  height: 70px;
  border-radius: 6px;
}
.hosname {
  width: 100%;

  font-weight: 500;
}
.wechatname {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 5px;

  color: #777777;
}
.wechatname img {
  width: 15px;
  height: 15px;
}
.name {
  margin-left: 5px;
}
.time {
  width: 100%;
  margin-top: 5px;

  color: #777777;
}
.genjin {
  width: 90%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.genjin-left {
  width: 80%;
  color: #777777;
  display: flex;
  align-items: center;
}
.genjin-left img {
  width: 20px;
  color: #777777;
  height: 20px;
  border-radius: 50%;
  margin-left: 10px;
}

.genjin-right {
  width: 30%;
  color: #777777;
  text-align: right;
  border-left: 1px solid #e7e7e7;
}
.line {
  width: 90%;
  height: 0px;
  border: 1px solid #e7e7e7;
  margin-top: 20px;
}

.check {
  width: 100%;
  margin-top: 10px;
  background: #ffffff;
  display: flex;
}
.check-left {
  width: 70%;
  display: flex;
  justify-content: space-between;
}
.circle {
  width: 60%;
  display: flex;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.circle-title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}
.circle-line {
  margin-left: 20px;
}
.check-right {
  width: 30%;
}
.xs {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #282828;
}
.kf {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #777777;
}
.tabs {
  width: 100%;
  height: 200px;
  margin-top: 10px;
  background: #ffffff;
}
.sort-line {
  width: 0px;
  height: 21px;
  border: 1px solid #e7e7e7;
}
.doctormsg {
  width: 40%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
}
.doctor {
  width: 100%;
  text-align: center;
}
.doctor-title {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #777777;
}
.doctor-name {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #282828;
  margin-bottom: 4px;
}
.intention {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}
.intention-title {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #777777;
}
</style>

<style>
:root {
  --van-circle-size: 50px !important;
  --van-circle-layer-color: #c0c7d9 !important;
  --van-tag-padding: 4px 4px 4px 4px !important;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 100%;
  height: 100vh;
  overflow-x: scroll;
  background-color: #f7f7f7;
}
</style>

<template>
  <div class="wrap">
    <div class="tips">
      <div class="title">
        <div class="font">企业标签</div>
        <div class="icon" @click="showTap"><img src="@/assets/icon.png" alt="" /></div>
      </div>
      <div class="taps">
        <div class="for-taps" v-for="(item, index) in tapArray" :key="index" :value="item">
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="own-title">
      <div class="title">
        <div class="font">个人标签</div>
        <div class="icon-set" @click="showPopup">i</div>
      </div>
    </div>

    <div class="from" style="margin-bottom: 50px">
      <van-form @submit="onSubmit" colon="true" ref="formRef">
        <van-cell-group inset>
          <van-field
            v-model="name"
            type="text"
            name="name"
            label="姓名"
            autocomplete="off"
            @blur="getFrom"
          />
          <van-field v-model="wechatname" label="微信昵称" autocomplete="off" readonly />
          <van-field
            v-model="phone"
            label="手机号"
            autocomplete="off"
            name="phone"
            @blur="getFrom"
            maxlength="11"
          />
          <van-field
            v-model="sex"
            is-link
            readonly
            name="sex"
            label="性别"
            autocomplete="off"
            placeholder=""
            @click="showPicker = true"
            @blur="getFrom"
          />
          <van-field v-model="age" name="age" label="年龄" autocomplete="off" @blur="getFrom" />
          <van-field
            v-model="resultArea"
            is-link
            readonly
            autocomplete="off"
            name="area"
            label="省市区"
            @click="showArea = true"
            @change="getArea"
          />
          <van-field
            v-model="idcard"
            name="idcard"
            label="身份证"
            @blur="getFrom"
            autocomplete="off"
            maxlength="18"
          />
          <van-field v-model="isConsultation" label="是否问诊" autocomplete="off" readonly />
          <van-field v-model="isPrescribing" label="是否开方" autocomplete="off" readonly />
          <van-field v-model="ispay" label="是否购药" autocomplete="off" readonly />
          <van-field
            v-model="channel"
            name="channel"
            label="渠道来源"
            autocomplete="off"
            @blur="getFrom"
          />
          <van-field v-model="time" label="添加好友" autocomplete="off" readonly />
        </van-cell-group>
        <div style="margin: 16px">
          <!-- <van-button round block type="primary" native-type="submit"> 提交 </van-button> -->
        </div>
      </van-form>
    </div>
  </div>

  <van-popup v-model:show="show" position="bottom" round>
    <div class="aaasss">
      <div class="close-icon" @click="cancel"><van-icon name="cross" /></div>
      <div class="top-title">点击聊天框顶部【客户名称】-【设置标签】</div>
      <div class="bottom-title">可设置个人标签</div>
      <div class="img">
        <img src="../assets/image.png" alt="" />
      </div>
    </div>
  </van-popup>

  <van-overlay :show="showTaps">
    <div class="wrapper">
      <div class="block">
        <div class="back">
          <div class="back-left">
            <van-icon @click="close" name="arrow-left" />
            请选择标签
          </div>
          <div class="back-right"><div class="get" @click="Taps">完成</div></div>
        </div>

        <div class="tap-title" v-for="(item, index) in tapslist" :key="index" :value="item">
          <div class="check-title">{{ item.title }}</div>
          <div class="check-taps">
            <div
              class="every"
              :class="taps[index] == items.id ? 'every-check' : 'every'"
              v-for="(items, indexs) in item.twoList"
              :key="index"
              :value="item"
              @click="checkTap(items, index)"
            >
              {{ items.title }}
              <!-- {{ taps[index] }}
              {{ items.id }} -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </van-overlay>

  <van-popup v-model:show="showPicker" position="bottom">
    <van-picker :columns="sexlist" @confirm="onConfirm" @cancel="showPicker = false" />
  </van-popup>

  <van-popup v-model:show="showArea" position="bottom">
    <van-area :area-list="areaList" @confirm="onConfirmArea" @cancel="showArea = false" />
  </van-popup>
</template>

<script>
// eslint-disable-next-line
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  getCurrentInstance,
  ComponentInternalInstance,
} from 'vue'
import { FormInstance } from 'vant'
import axios from 'axios'
import { Toast } from 'vant'
import { logins } from '../utils/http/logins'

// import { areaList } from '../'
import { areaList } from '@vant/area-data'
export default defineComponent({
  name: 'portrait',
  props: {
    info: String,
    key: Number,
  },
  components: {},
  // eslint-disable-next-line
  setup(props) {
    onMounted(() => {
      getinfo()
      getTaps()
    })
    console.log(props.id, '01920192019021')
    const token = window.localStorage.getItem('token')

    let getUrl = window.location.href

    const userid = ref('')
    const show = ref(false)
    const showTaps = ref(false)
    const showPicker = ref(false)
    const sexlist = ['男', '女']
    const tapArray = ref([])
    const showArea = ref(false)
    const name = ref('--')
    const wechatname = ref('--')
    const phone = ref('--')
    const sex = ref('--')
    const age = ref('--')
    const resultArea = ref('--')
    const idcard = ref('--')
    const isConsultation = ref('--')
    const isPrescribing = ref('--')
    const ispay = ref('--')
    const channel = ref('--')
    const time = ref('--')
    const id = ref('')
    if (props.id) {
      userid.value = props.id
    }
    const showPopup = () => {
      show.value = true
    }

    const cancel = () => {
      show.value = false
    }

    const onSubmit = (values) => {
      if (values.idcard) {
        idcard.value = values.idcard.replace(/^(.{6})(?:\d+)(.{4})$/, '\$1****\$2')
      }
    }
    const formRef = ref()
    const getFrom = (target) => {
      let params = {
        [target.target.name]: target.target.value,
        id: id.value,
      }
      axios
        .post(`/api/enterprise/editCustomer`, params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          console.log(res)
          if (idcard.value) {
            idcard.value = idcard.value.replace(/^(.{6})(?:\d+)(.{4})$/, '\$1****\$2')
          }
        })
    }

    const showTap = () => {
      showTaps.value = true
    }
    const onConfirm = (value) => {
      sex.value = value
      showPicker.value = false
      let params = {
        sex: value == '男' ? 1 : 0,
        id: id.value,
      }
      axios
        .post(`/api/enterprise/editCustomer`, params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
    }

    const onConfirmArea = (areaValues) => {
      showArea.value = false
      resultArea.value = areaValues
        .filter((item) => !!item)
        .map((item) => item.name)
        .join('/')
      let params = {
        area: resultArea.value,
        id: id.value,
      }
      axios
        .post(`/api/enterprise/editCustomer`, params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
    }

    const getinfo = () => {
      let params = {
        external_user_id: window.sessionStorage.getItem('userid'),
        // external_user_id: 'wmrlZ3PgAA4JPZEbqH8yIBTc4JPpy2Ww',
      }
      axios
        .post(`/api/enterprise/infoCustomer`, params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          console.log(res)
          let info = res.data.data.info
          id.value = info.id
          name.value = info.name
          sex.value = info.sex == 0 ? '女' : '男'
          age.value = info.age
          tapArray.value = info.tags_title
          if (info.tags) {
            taps.value = info.tags.split(',')
          }
          resultArea.value = info.area
          idcard.value = info.idcard
          phone.value = info.phone
          wechatname.value = info.wechat_name
          isConsultation.value = info.is_wz == 1 ? '是' : '否'
          isPrescribing.value = info.is_pres == 1 ? '是' : '否'
          ispay.value = info.is_drug == 1 ? '是' : '否'
          channel.value = info.channel
          time.value = info.created_at
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
    }
    const tapslist = ref()
    const RightTaps = ref()
    function close() {
      showTaps.value = false
    }
    const checkId = ref()
    const chooseData = ref({})
    const arr = ref([])
    const taps = ref([])
    function checkTap(item, index) {
      let ar = taps.value
      ar[index] = item.id
      let newobj = JSON.parse(JSON.stringify(ar))
      taps.value = newobj
    }

    function newArrFn(arr) {
      // .new Set方法，返回是一个类数组，需要结合 ...运算符，转成真实数组
      return [...new Set(arr)]
    }
    function Taps() {
      let params = {
        id: id.value,
        tags: taps.value,
      }
      axios
        .post(`/api/enterprise/editCustomer`, params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          console.log(res)
          if (res.data.message == 'success') {
            Toast.success('修改成功')
            showTaps.value = false
            getinfo()
          }
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
    }

    function getArea() {
      console.log(1)
    }

    function getTaps() {
      axios
        .post(`/api/enterprise/ctypeList`, '', {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          console.log(res)
          tapslist.value = res.data.data.list
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
    }
    return {
      taps,
      arr,
      chooseData,
      checkId,
      RightTaps,
      tapslist,
      id,
      token,
      resultArea,
      showArea,
      sexlist,
      formRef,
      userid,
      tapArray,
      show,
      name,
      wechatname,
      showTaps,
      sex,
      showPicker,
      areaList,
      phone,
      age,
      idcard,
      isConsultation,
      isPrescribing,
      ispay,
      channel,
      time,
      getinfo,
      showPopup,
      onSubmit,
      cancel,
      getFrom,
      showTap,
      onConfirm,
      onConfirmArea,
      close,
      checkTap,
      getTaps,
      Taps,
      newArrFn,
      getArea,
      getUrl,
    }
  },
})
</script>

<style scoped>
.wrap {
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.tips {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.title {
  display: flex;
  /* width: 100%; */
  /* justify-content: center; */
  align-items: center;
  margin-top: 20px;
  margin-left: 20px;
}
.taps {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.for-taps {
  background: #edf1fb;
  border-radius: 4px;
  color: #5d8cff;
  padding: 5px;
  margin-top: 10px;
  margin-left: 20px;
}
.icon img {
  width: 16px;
  height: 16px;
  margin-left: 10px;
}
.font {
  font-size: 17px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #353535;
}
.icon-set {
  background: orange;
  color: white;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  text-align: center;
  font-size: 10px;
  margin-left: 10px;
}
.aaasss {
  width: 100%;
  height: 400px;
  background: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.img {
  width: 90%;
}
.img img {
  width: 100%;
  height: 100%;
}
.van-popup {
  width: 100% !important;
}
.close-icon {
  width: 90%;
  text-align: right;
}
.top-title {
  width: 90%;
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #282828;
}
.bottom-title {
  margin-top: 10px;
  width: 90%;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #282828;
}
.from {
  margin-top: 10px;
}
.block {
  width: 100%;
  /* display: flex; */
  justify-content: center;
  flex-wrap: wrap;
}
.back {
  width: 95%;
  display: flex;
  height: 50px;
  margin: 10px auto;
}
.back-left {
  height: 28px;
  width: 45%;
  text-align: left;
  line-height: 28px;
}
.back-right {
  width: 50%;
  display: flex;
  justify-content: end;
}
.get {
  text-align: center;
  color: white;
  width: 50px;
  height: 28px;
  line-height: 28px;
  background: #0052fe;
  border-radius: 5px;
}
.check-title {
  width: 90px;
  height: 20px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #282828;
  line-height: 20px;
  margin-left: 15px;
  margin-top: 15px;
}
.check-taps {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* margin-left: 15px; */
  margin-top: 15px;
  /* justify-content: center; */
}
.every {
  width: 74px;
  height: 26px;
  /* background: #0052fe; */
  background: #f8f8f8;
  border-radius: 4px;
  color: black;
  text-align: center;
  margin-left: 15px;
  line-height: 26px;
}
.every-check {
  width: 74px;
  height: 26px;
  background: #0052fe;
  border-radius: 4px;
  color: white;
  text-align: center;
  margin-left: 15px;
}
</style>

<style>
:root {
  --van-tabs-bottom-bar-color: #3b0aee !important;
}
.van-popup {
  width: 100% !important;
}
.van-cell-group--inset {
  margin-left: 5px !important;
}
.van-cell:after {
  border-bottom: 2px solid var(--van-cell-border-color) !important;
}
</style>

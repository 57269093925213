<template>
  <div class="listwrap" v-if="!showgy">
    <div class="top_wrap">
      <div class="top_title">
        <div class="line"></div>
        <div class="title">您的合计订单</div>
      </div>
      <div class="total">
        <div class="total_left">
          <div class="left_title">您今日</div>
          <div class="sec">
            <div class="sec_left">挂号</div>
            <div class="sec_right">{{ todaynum }}单</div>
          </div>
          <div class="sec">
            <div class="sec_left">订单金额</div>
            <div class="sec_right">￥{{ todaymoney }}</div>
          </div>
          <div class="sec">
            <div class="sec_left">预收款</div>
            <div class="sec_right">￥{{ todayadvancemoney }}</div>
          </div>
        </div>
        <div class="total_right">
          <div class="right_title">您昨日</div>
          <div class="sec">
            <div class="sec_left">挂号</div>
            <div class="sec_right">{{ lastdaynum }}单</div>
          </div>
          <div class="sec">
            <div class="sec_left">订单金额</div>
            <div class="sec_right">￥{{ lastdaymoney }}</div>
          </div>
          <div class="sec">
            <div class="sec_left">预收款</div>
            <div class="sec_right">￥{{ lastdayadvancemoney }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom_wrap">
      <div class="user">
        当前患者
        <!-- <div class="strong">·</div> -->
        <img :src="imgurl" alt="" class="img" /> {{ wechatname }} 的挂号记录
      </div>
      <div class="nodata" v-if="nodata">
        <div>当前患者暂无挂号记录</div>
      </div>
      <div v-else class="list_wrap" v-for="(item, index) in list" :key="item.value" :label="index">
        <div class="order">
          <div class="order_id">{{ item.order_sn }}</div>
          <div class="order_right_ytk" v-if="item.status == '已退款'">已退款</div>
          <div class="order_right_wzf" v-else-if="item.pay_status == 0">未支付</div>
          <div class="order_right_yzf" v-else>已支付</div>
        </div>
        <div class="time">{{ item.credate }}</div>
        <div class="section">
          <div class="user_msg">
            <div class="lefts">患者信息：</div>
            <div class="tight">
              {{ item.patient_name }}
              {{ item.sex == 1 ? '男' : '女' }}
              {{ item.age }}
              {{ item.patient_phone }}
              | 微信：
              {{ item.wechat_name }}
            </div>
          </div>
          <div class="order_msg">
            <div class="lefts">订单信息：</div>
            <div class="tight">
              {{ item.type == 4 ? '视频问诊' : item.type == 3 ? '电话问诊' : '图文问诊' }}￥{{
                item.pay_money
              }}

              | 医生：
              {{ item.doctor_name }} {{ item.ks2name }}
            </div>
          </div>
          <div class="order_status">
            <div class="lefts">订单状态：</div>
            <div class="tight">
              {{ item.status }}
            </div>
          </div>
          <div class="day">
            <div class="lefts">预约日期：</div>
            <div class="tight" v-if="item.type != 2">
              {{ item.dial_time }}
            </div>
            <div class="tight" v-else>
              {{ item.pay_time }}
            </div>
          </div>
          <div class="pay_day">
            <div class="lefts">支付时间：</div>
            <div class="tight">
              {{ item.pay_time }}
            </div>
          </div>
          <div class="order_sn" v-if="item.advance_money != '0.00' && item.advance_money != '0'">
            <div class="lefts">缴费编号：</div>
            <div class="tight" v-if="item.register_order_no">
              {{ item.register_order_no }}
            </div>
            <div class="tight" v-else>--</div>
          </div>
          <div class="pay_price" v-if="item.advance_money != '0.00' && item.advance_money != '0'">
            <div class="lefts">预收款：</div>
            <div class="tight">
              {{ item.advance_money }}
            </div>
          </div>
        </div>
        <div class="lines"></div>
        <!-- item.status != '已退款' || -->
        <!-- -->
        <div class="button">
          <div
            class="kf"
            style="width: 40px"
            @click="gy(item)"
            v-if="
              item.pay_money == '0.00' &&
              item.advance_money != '0.00' &&
              item.status != '已取消' &&
              item.status != '已结束' &&
              item.status != '已失效' &&
              item.status != '已退款' &&
              item.dial_time_str != thisday &&
              item.is_changetime == 0
            "
          >
            改约
          </div>
          <div
            class="kf"
            @click="yzdf(item)"
            v-if="
              item.order_status == '未支付' && item.status != '已取消' && item.status != '已失效'
            "
          >
            客服代付
          </div>
          <div class="kf" @click="refundReson(item)" v-if="item.status == '已退款'">退款日志</div>
          <div
            class="kf"
            @click="refund(item)"
            v-if="
              item.order_status == '已支付' &&
              item.status !== '退款中' &&
              item.status !== '已退款' &&
              item.pay_money !== '0.00'
            "
          >
            快捷退款
          </div>
          <div class="again" @click="again(item)" v-if="modelBtn != 1">复诊挂号</div>
          <div
            class="senduser"
            @click="senduser(item)"
            v-if="
              item.order_status == '未支付' && item.status != '已取消' && item.status != '已失效'
            "
          >
            发送给患者支付
          </div>
        </div>
      </div>
    </div>

    <div ref="imageWrapper" class="imageWrapper" id="imageWrapper" v-show="ishsow">
      <div class="imageWrap1">
        <img src="../assets/Vector@2x.png" alt="" class="nine" /> 您已成功预约
      </div>
      <div class="imageWrap">{{ doctorName }}医生的线上咨询服务</div>
      <div class="imageWrap">{{ userName }}的挂号二维码</div>
      <img :src="qrcodeUrl" alt="" class="img_canvas" />
      <div class="onlytime">
        <div class="left_img">
          <img src="../assets/Frames@2x.png" alt="" class="qrcode_img_icon" />
        </div>
        <div class="right_title_a">
          <div class="top_title">请30分钟之内</div>
          <div class="bottom_title">微信识别二维码完成支付</div>
        </div>
      </div>
    </div>
  </div>
  <div class="refundModel" v-if="isRefund">
    <div class="refundPrice" v-if="isRefund1">
      <div @click="closeModel"><img src="../assets/close.png" alt="" class="closeimg" /></div>
      <div class="one">快速退款</div>
      <div class="refundOrder">
        <div class="two">订单编号:</div>
        <div class="three">{{ order_id }}</div>
      </div>
      <div class="four">退款原因：</div>
      <div class="text">
        <textarea
          rows="5"
          placeholder="请输入退款原因"
          class="texts"
          v-model="refund_reason"
          maxlength="100"
        ></textarea>
      </div>
      <div class="sure" @click="refoundOk">确认退款</div>
    </div>
    <div class="refundPrice" v-if="isReson">
      <div @click="closeModel"><img src="../assets/close.png" alt="" class="closeimg" /></div>
      <div class="one">退款信息</div>
      <div class="refundOrder">
        <div class="two">订单编号:</div>
        <div class="three">{{ order_id }}</div>
      </div>
      <div class="four">原因：{{ refoundReson }}</div>
      <div class="four">操作人:{{ refunduser }}</div>
      <div class="four">
        时间：
        {{ resontime }}
      </div>
      <div class="sure" @click="resonok">确认</div>
    </div>
    <div ref="imageWrapper" class="imageWrappers" id="imageWrapper" v-show="yz">
      <div @click="closeModel"><img src="../assets/close.png" alt="" class="closeimg" /></div>

      <div class="imageWrap1">
        <img src="../assets/Vector@2x.png" alt="" class="nine" /> 您已成功预约
      </div>
      <div class="imageWrap">{{ doctorName }}医生的线上咨询服务</div>
      <div class="imageWrap">{{ userName }}的挂号二维码</div>
      <img :src="qrcodeUrl" alt="" class="img_canvas" />
      <div class="onlytime">
        <div class="left_img">
          <img src="../assets/Frames@2x.png" alt="" class="qrcode_img_icon" />
        </div>
        <div class="right_title_a">
          <div class="top_title">请30分钟之内</div>
          <div class="bottom_title">微信识别二维码完成支付</div>
        </div>
      </div>
    </div>
  </div>
  <div class="gymodel" v-if="showgy">
    <div class="gaiyue">
      <div class="yydoctor">
        <div class="yydoctor_left">
          预约医生
          <div class="xh">*</div>
        </div>
        <div class="yydoctor_right">
          <div @click="getgydoctor">{{ gyys }}</div>
          <!-- <input type="text" style="text-align: right" v-model="gyys" />/ -->
        </div>
      </div>
      <van-popup v-model:show="showGy" position="bottom">
        <van-picker
          title="选择医生"
          :columns="columns"
          :columns-field-names="customFieldNames"
          @confirm="onConfirm"
          @cancel="onCancel"
          @change="onChange"
        />
      </van-popup>
      <div class="yytime" v-if="hiddentime">
        <div class="yytime_left">
          选择挂号时间
          <div class="xh">*</div>
        </div>
        <div class="yytime_right">
          <div @click="getgytimer">{{ gysj }}</div>
          <!-- <input type="text" style="text-align: right" v-model="gysj" /> -->
        </div>
      </div>
      <div class="tips">温馨提示：一个挂号订单只支持改约一次，请确认无误后再改约哦！</div>
      <van-popup v-model:show="showtime" position="bottom">
        <van-picker
          :title="选择挂号时间"
          :columns="columnss"
          :columns-field-names="customFieldName"
          @change="timechange"
          @confirm="timeconfirm"
          @cancel="onCancels"
        />
      </van-popup>
      <div class="suregy" @click="suergy">确认</div>
      <div class="closegy" @click="closegy">取消</div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import axios from 'axios'
import html2canvas from 'html2canvas'
import { Toast } from 'vant'
import http from '../utils/http/http'
import { logins } from '../utils/http/logins'
import { urlencodeUrl } from '../utils/urlencodeUrl'

export default defineComponent({
  props: {
    id: String,
    closebtn: Number,
    key: Number,
  },
  // eslint-disable-next-line
  setup(props, { emit }) {
    //
    console.log(props)
    let modelBtn = props.closebtn
    let showgy = ref(false)
    let showGy = ref(false)
    let showGyTime = ref(false)
    const token = window.localStorage.getItem('token')
    let list = ref([])
    let todaynum = ref(0)
    let todaymoney = ref(0)
    let todayadvancemoney = ref(0)
    let lastdaynum = ref(0)
    let lastdaymoney = ref(0)
    let lastdayadvancemoney = ref(0)
    let imgurl = ref('')
    let wechatname = ref('')
    let doctorName = ref('')
    let userName = ref('')
    let qrcodeUrl = ref('')
    let wx = window.wx
    let nodata = ref(false)
    let ishsow = ref(true)
    let isRefund = ref(false)
    let isRefund1 = ref(false)
    let refund_reason = ref('')
    let order_id = ref('')
    let refoundid = ref('')
    let isReson = ref(false)
    let refoundReson = ref('')
    let resontime = ref('')
    let yz = ref(false)
    const day = new Date()
    let gyys = ref('')
    let gysj = ref('请选择挂号时间')
    let gytype = ref(0)
    const thisday = ref('')
    console.log(day.getDay() + 1)
    console.log(day.getMonth() + 1)
    console.log(day.getFullYear())
    let days = day.getDay() + 1
    let month = day.getMonth() + 1
    let year = day.getFullYear()
    if (days < 10) {
      days = '0' + days
    }
    if (month < 10) {
      month = '0' + month
    }
    thisday.value = year + '/' + month + '/' + days
    onMounted(() => {
      // getList()
    })
    const agentid = window.localStorage.getItem('agentid')

    let getUrl = window.location.href
    const corpid = window.localStorage.getItem('corpid')
    let urlparams = {
      url: getUrl,
    }
    axios
      .post(`/api/enterprise/auth/sign/app`, urlparams, {
        headers: { Authorization: `bearer ${token}` },
      })
      .then((res) => {
        wx.agentConfig({
          corpid: corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid: agentid, //必填，企业微信的应用id （e.g. 1000247）
          timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.data.nonce_str, // 必填，生成签名的随机串
          signature: res.data.data.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
          jsApiList: ['getCurExternalContact', 'sendChatMessage'], //必填，传入需要使用的接口名称
          success: function () {
            // 回调
            wx.invoke('getCurExternalContact', {}, function (res) {
              if (res.err_msg == 'getCurExternalContact:ok') {
                window.localStorage.setItem('userid', res.userId)
                // uesrid.value = res.userId //返回当前外部联系人userId
                // window.location.reload()

                let paramsss = {
                  external_user_id: res.userId,
                }
                axios
                  .post('/api/enterprise/getExternalUserInfo', paramsss, {
                    headers: { Authorization: `bearer ${token}` },
                  })
                  .then((res) => {
                    if (res.data.code == 200) {
                      imgurl.value = res.data.data.external_user_info.external_contact.avatar
                      wechatname.value = res.data.data.external_user_info.external_contact.name
                    }

                    console.log(res)
                  })

                let params = {
                  external_user_id: res.userId,
                }
                axios
                  .post('/api/enterprise/listQuickWzOrder', params, {
                    headers: { Authorization: `bearer ${token}` },
                  })
                  .then((res) => {
                    console.log(res.data.data.list)
                    list.value = res.data.data.list
                    todaynum.value = res.data.data.todaynum
                    todaymoney.value = res.data.data.todaymoney
                    todayadvancemoney.value = res.data.data.todayadvancemoney
                    lastdaynum.value = res.data.data.lastdaynum
                    lastdaymoney.value = res.data.data.lastdaymoney
                    lastdayadvancemoney.value = res.data.data.lastdayadvancemoney
                    if (list.value.length == 0) {
                      nodata.value = true
                    }
                  })
                  .catch((err) => {
                    if (err.response.data.code == 401) {
                      logins()
                    } else {
                      Toast(err.response.data.message)
                    }
                  })
              } else {
                //错误处理
              }
            })
          },
          fail: function (res) {
            if (res.errMsg.indexOf('function not exist') > -1) {
              alert('版本过低请升级')
            }
          },
        })
      })
      .catch((err) => {
        logins()

        console.log(err.response)
        if (err.response.data.code == 401) {
          logins()
        }
        // login()
      })

    async function getList() {
      let params = {
        external_user_id: window.localStorage.getItem('userid'),
      }
      await axios
        .post('/api/enterprise/listQuickWzOrder', params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          list.value = res.data.data.list
          todaynum.value = res.data.data.todaynum
          todaymoney.value = res.data.data.todaymoney
          todayadvancemoney.value = res.data.data.todayadvancemoney
          lastdaynum.value = res.data.data.lastdaynum
          lastdaymoney.value = res.data.data.lastdaymoney
          lastdayadvancemoney.value = res.data.data.lastdayadvancemoney
          if (list.value.length == 0) {
            nodata.value = true
          }
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
    }
    function again(item) {
      window.sessionStorage.setItem('registerListId', item.id)
      emit('getregisterDate')
      console.log(item)
    }
    function closeModel() {
      isRefund.value = false
      refund_reason.value = null
      isReson.value = false
      yz.value = false
    }
    function refund(item) {
      order_id.value = item.order_sn
      refoundid.value = item.id
      isRefund.value = true
      isRefund1.value = true
    }
    let refunduser = ref('')
    function refundReson(item) {
      order_id.value = item.order_sn
      refoundReson.value = item.refund_reason
      resontime.value = item.refund_time
      refunduser.value = item.refund_user
      isRefund.value = true
      isReson.value = true
      console.log(item)
    }
    function yzdf(item) {
      let params = {
        id: item.id,
        type: 1,
        payer: 2,
      }
      axios
        .post('/api/enterprise/qrCode1', params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          if (res.data.code == 200) {
            qrcodeUrl.value = 'data:image/png;base64,' + res.data.data.code
            isRefund.value = true
            yz.value = true
            doctorName.value = item.doctor_name
            userName.value = item.patient_name
          }
          console.log(res)
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
    }
    function resonok() {
      isRefund.value = false
      isReson.value = false
    }
    function refoundOk() {
      if (refund_reason.value) {
        let params = {
          id: refoundid.value,
          reason: refund_reason.value,
          type: 1,
        }
        axios
          .post('/api/enterprise/refendQuickWzOrder', params, {
            headers: { Authorization: `bearer ${token}` },
          })
          .then((res) => {
            console.log(res)
            if (res.data.code == 200) {
              closeModel()
              Toast('退款成功')
            }
          })
          .catch((err) => {
            if (err.response.data.code == 401) {
              logins()
            } else {
              Toast(err.response.data.message)
            }
          })
      } else {
        Toast('退款原因必填')
      }
    }
    function senduser(item) {
      let organization = window.localStorage.getItem('organization')
      if (organization != 'mingyi') {
        let sendurl = ''
        // let url =
        //   'https://cloud1-5g80ysht3e049128-1306117587.tcloudbaseapp.com/h5/jump-mp.html?sign=047e33b549a034725a9760fd3f5deebd&t=1644572774&mpUrl='
        // sendurl = url + encodeURIComponent(`/pages/pay/purchase-service?id=${item.id}`)

        sendurl = urlencodeUrl('/pages/pay/purchase-service', { id: item.id })
        wx.invoke(
          'sendChatMessage',
          {
            msgtype: 'text', //消息类型，必填
            enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
            text: {
              content: sendurl, //文本内容
            },
          },
          function (res) {
            if (res.err_msg == 'sendChatMessage:ok') {
            }
            Toast('发送成功,请提醒患者尽快支付')
            // setTimeout(() => {
            //   window.history.go(-1)
            // }, 1000)
          }
        )
      } else {
        wx.invoke(
          'sendChatMessage',
          {
            msgtype: 'miniprogram', //消息类型，必填
            enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
            miniprogram: {
              appid: 'wxee26553d4e0052ea', //小程序的appid，企业已关联的任一个小程序
              title: `您已成功预约${item.doctor_name}医生的线上问诊服务`, //小程序消息的title
              imgUrl: 'https://xcxapi.myzx.cn/assets/ghm.png', //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
              page: `pages/pay/purchase-service.html?id=${item.id}`,
            },
          },
          function (res) {
            if (res.err_msg == 'sendChatMessage:ok') {
              Toast('发送成功')
              setTimeout(() => {
                window.location.reload()
              }, 1000)
              //发送成功
            }
          }
        )
      }
    }
    let gyid = ref('')
    let gytimeid = ref('')
    let uid = ref('')
    function gy(item) {
      console.log(item)
      showgy.value = true
      gyys.value = item.doctor_name
      gysj.value = item.dial_time
      gytype.value = item.type
      doctorDid.value = item.cpdid
      doctor_id.value = item.doctor_id
      gyid.value = item.id
      gytimeid.value = item.schedule_id
      if (item.type == 2) {
        hiddentime.value = false
      }
      uid.value = item.uid
    }
    let hiddentime = ref(true)
    const columns = ref([])
    const customFieldNames = {
      text: 'doctor_name',
    }
    function getgydoctor() {
      showGy.value = true
      let params = {
        only_managed: 0,
      }
      axios
        .post('/api/enterprise/organizationDoctorList', params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          columns.value = res.data.data
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            // Toast(err.response.data.message)
          }
        })
    }
    const columnss = ref([])
    const customFieldName = {
      text: 'day',
      children: 'timelist',
    }
    let doctorDid = ref('')
    let doctor_id = ref('')
    let showtime = ref(false)
    function onConfirm(e) {
      gyys.value = e.doctor_name
      doctorDid.value = e.did
      doctor_id.value = e.doctor_id
      gysj.value = '请选择挂号时间'
      gytimeid.value = 0
      showGy.value = false
    }
    function onCancel() {
      showGy.value = false
    }
    function onChange() {}

    function getgytimer() {
      let paramss = {
        did: doctorDid.value,
        type: gytype.value,
      }
      axios
        .post('/api/enterprise/doctorScheduling', paramss, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          columnss.value = res.data.data.schedules
          console.log(res.data.data.schedules.length)
          if (columnss.value.length == 0) {
            Toast('该医生暂无号源')
            setTimeout((res) => {
              showtime.value = false
            }, 1000)
          }
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            // Toast(err.response.data.message)
          }
        })
      showtime.value = true
    }
    function suergy() {
      let params = {
        doctor_id: doctor_id.value,
        id: gyid.value,
        schedule_id: gytimeid.value,
        did: doctorDid.value,
        type: gytype.value,
        user_id: uid.value,
      }
      if (gytype.value == 2) {
        params.schedule_id = 0
      }
      axios
        .post('/api/enterprise/changeQuickWzOrder', params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          if (res.data.code == 200) {
            Toast('改约成功')
            setTimeout((res) => {
              showgy.value = false
              window.location.reload()
            }, 1000)
          }
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
    }
    function closegy() {
      showgy.value = false
    }

    function timeconfirm(value, index) {
      gytimeid.value = columnss.value[index[0]].timelist[index[1]].id
      gysj.value =
        columnss.value[index[0]].day + '  ' + columnss.value[index[0]].timelist[index[1]].day
      showtime.value = false
    }

    return {
      timeconfirm,
      gyid,
      gytimeid,
      showtime,
      getgytimer,
      columnss,
      customFieldName,
      doctorDid,
      doctor_id,
      onConfirm,
      onCancel,
      onChange,
      customFieldNames,
      columns,
      imgurl,
      wechatname,
      token,
      list,
      todaynum,
      todaymoney,
      todayadvancemoney,
      lastdaynum,
      lastdaymoney,
      lastdayadvancemoney,
      doctorName,
      userName,
      qrcodeUrl,
      ishsow,
      again,
      closeModel,
      nodata,
      senduser,
      refund,
      isRefund,
      refund_reason,
      order_id,
      refoundOk,
      refoundid,
      refundReson,
      isReson,
      isRefund1,
      refoundReson,
      resontime,
      resonok,
      yz,
      yzdf,
      thisday,
      showgy,
      gy,
      gyys,
      gysj,
      showGy,
      showGyTime,
      getgydoctor,
      gytype,
      suergy,
      closegy,
      refunduser,
      hiddentime,
      uid,
      getUrl,
      corpid,
      modelBtn,
    }
  },
})
</script>

<style scoped>
.listwrap {
  width: 100%;
  /* background: #f3f5f7; */
  display: flex;
  align-items: center;
  flex-direction: column;
}
.top_wrap {
  width: 90%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: white;
}
.top_title {
  display: flex;
  margin-top: 22px;
  width: 100%;
}
.line {
  width: 5px;
  height: 18px;
  background: #359eff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
}
.title {
  font-size: 15px;
  font-weight: 500;
  color: #343434;
  line-height: 18px;
  margin-left: 10px;
}
.total {
  width: 100%;
  margin-top: 22px;
  margin-bottom: 22px;
  display: flex;
}
.left_title {
  color: #359eff;
}
.right_title {
  color: #359eff;
}
.total_left {
  width: 40%;
}
.total_right {
  width: 40%;
  margin-left: 50px;
}
.sec {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.sec_left {
  color: #999999;
  font-size: 14px;
}
.sec_right {
  margin-left: 30px;
}
.bottom_wrap {
  width: 100%;
  background: #f3f5f7;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding-bottom: 50px;
}
.user {
  width: 90%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-top: 18px;
}
.img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 10px;
}
.list_wrap {
  width: 95%;
  background: white;
  margin-top: 10px;
  color: #999999;
  display: flex;
  flex-direction: column;
  /* justify-content: unset; */
  align-items: center;
  border-radius: 8px 8px 8px 8px;
}
.time {
  width: 90%;
}
.order {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.order_id {
  color: #000000;
  font-weight: bold;
  font-size: 14px;
}
.pay_status {
  font-size: 15px;
  /* margin-top: 5px; */
}
.section {
  width: 90%;
}
.time {
  font-size: 12px;
}

.order_msg {
  display: flex;
  font-size: 12px;
}
.order_status {
  display: flex;
  font-size: 12px;
}
.day {
  display: flex;
  font-size: 12px;
}
.pay_day {
  display: flex;
  font-size: 12px;
}
.order_sn {
  display: flex;
  font-size: 12px;
}
.pay_price {
  display: flex;
  font-size: 12px;
}
.lines {
  width: 90%;
  height: 0px;
  opacity: 1;
  border: 1px solid #ebebeb;
}
.button {
  width: 90%;
}
.again {
  width: 72px;
  height: 25px;
  background: #359eff;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  color: white;
  line-height: 25px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  margin-left: 10px;

  /* padding: 0px 10px; */
}
.senduser {
  width: 107px;
  height: 25px;
  background: #359eff;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  cursor: pointer;
  color: white;
  line-height: 25px;
  text-align: center;
  font-size: 14px;
  padding: 0px 5px;
  margin-left: 10px;
}
.user_msg {
  margin-top: 10px;
  display: flex;
  font-size: 12px;
}
.user_msg .lefts {
  width: 65px;
}

/* .user_msg .lefts {
  flex: 1.5;
}
.user_msg .tight {
  flex: 8.5;
} */
/* .order_msg .lefts {
  flex: 2.2;
}
.order_msg .tight {
  flex: 7.8;
} */

.order_msg {
  margin-top: 4px;
}
.order_status {
  margin-top: 4px;
}
.day {
  margin-top: 4px;
}
.pay_day {
  margin-top: 4px;
}
.order_sn {
  margin-top: 4px;
}
.pay_price {
  margin-top: 4px;
}
.lines {
  margin-top: 15px;
}
.button {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}
.kf {
  width: 72px;
  height: 25px;
  /* background: #359eff; */
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  color: #181818;
  line-height: 25px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  padding: 0px 5px;
  margin-left: 10px;
  border: 1px solid #c9c9c9;
}
.imageWrapper {
  width: 290px;
  background: #359eff;
  flex-wrap: wrap;
  border-radius: 10px;
  margin-top: 30px;
  position: absolute;
  left: -400px;
}

.imageWrap1 {
  width: 100%;
  text-align: center;
  color: white;
  padding-top: 20px;
}
.imageWrap {
  width: 100%;
  text-align: center;
  color: white;
  margin-top: 10px;
}
.left {
  width: 20%;
}
.img_canvas {
  width: 143px;
  height: 133px;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
}
.nine {
  width: 15px;
  height: 15px;
}
.onlytime {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  padding-bottom: 20px;
  align-items: center;
}
.qrcode_img_icon {
  width: 28px;
  height: 28px;
}
.right_title_a {
  display: flex;
  flex-wrap: wrap;
  color: #f9f871;
  width: 70%;
  margin-bottom: 20px;
}
.left_img {
  width: 30%;
  text-align: end;
}
.top_title {
  width: 100%;
}
.bottom_title {
  width: 100%;
}
.bottom_list {
  margin-bottom: 20px;
}
.nodata {
  margin-top: 50px;
}
.refundModel {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.refundPrice {
  width: 70%;
  z-index: 999;
  opacity: 1;
  margin: 200px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  align-items: center;
  position: relative;
}
.one {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.closeimg {
  width: 13px;
  height: 13px;
  position: absolute;
  top: 15px;
  right: 15px;
}
.refundOrder {
  width: 80%;
  margin-top: 20px;
  display: flex;
  margin-left: 20px;
  align-items: center;
}
.text,
.four {
  margin-left: 20px;
  margin-top: 20px;
  width: 80%;
}
.texts {
  width: 90%;
  /* border: 1px solid #f7f7f7; */
}
.sure {
  text-align: center;
  width: 100px;
  height: 30px;
  background: #359eff;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  color: white;
  line-height: 30px;
  margin-bottom: 20px;
  margin-top: 20px;
  cursor: pointer;
}
.imageWrappers {
  width: 290px;
  background: #359eff;
  flex-wrap: wrap;
  border-radius: 10px;
  margin: 150px auto;
  position: relative;
  /* left: -400px; */
}
.order_right_ytk {
  color: #f1ba6d;
}
.order_right_wzf {
  color: #e36060;
}
.order_right_yzf {
  color: #999999;
}
.strong {
  font-weight: 900;
  margin-left: 10px;
  border-radius: 50%;
}
/* :deep(.van-popup--center) {
  width: 100%;
  height: 100%;
}
:deep(.van-popup) {
  width: 100%;
  height: 100%;
} */
.gaiyue {
  width: 100%;
  height: 700px;
  background: white;
  display: flex;
  /* justify-content: center; */
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}
.yydoctor {
  width: 90%;
  display: flex;
  justify-content: space-between;
  opacity: 1;
  border-bottom: 1px solid #ebebeb;
  margin-top: 20px;
  font-size: 14px;
  padding-bottom: 20px;
}
.yydoctor_left {
  flex: 3;
  display: flex;
}

.yydoctor_right {
  flex: 7;
  margin-left: 10px;
  text-align: right;
}

.yytime_left {
  flex: 3;
  display: flex;
}
.yytime_right {
  flex: 7;
  text-align: right;
}
.yydoctor_right input {
  width: 100%;
  border: none;
  outline: none;
}
.yytime_right input {
  width: 100%;
  border: none;
  outline: none;
}
.xh {
  color: red;
}
.yytime {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ebebeb;
  /* margin-bottom: 60px; */
  font-size: 14px;
}
.gymodel {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  position: absolute;
  top: 0;
}
.suregy {
  width: 317px;
  height: 42px;
  line-height: 42px;
  background: #359eff;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  color: white;
  text-align: center;
  cursor: pointer;
  margin-top: 50px;
}
.closegy {
  cursor: pointer;
  margin-top: 10px;
}
.tips {
  width: 90%;
  font-size: 14px;
  color: orange;
}
</style>

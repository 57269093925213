<template>
  <div class="wrap">
    <div class="top">
      <div class="section">
        <div class="title">
          <div class="line"></div>
          <div class="mine">我的二维码</div>
        </div>
        <div class="qrcode">
          <div class="qrcode_top">
            <div class="hospital_name">医云在线互联网医院</div>
            <div class="qrcode_img">
              <img :src="qrocdeUrl" alt="" class="qrocde_img" />
            </div>
            <div :class="isShow ? 'price' : 'prices'">挂号费￥{{ ghPrice }}</div>
          </div>
          <div class="qrcode_bottom" v-show="isShow">
            <div class="send_url" @click="sendUrl">
              <div class="send_url_img">
                <img src="../assets/Frame@2x.png" alt="" class="" />
              </div>
              <div class="send_url_font">发送支付链接</div>
            </div>
            <div class="bottom_line"></div>
            <div class="send_qrcode" @click="sendQrcode('top')">
              <div class="send_qrcode_img">
                <img src="../assets/Frame@2x.png" alt="" />
              </div>
              <div class="send_qrcode_font">发送二维码</div>
            </div>
          </div>
        </div>
        <div style="color: #f5a300">温馨提示:请勿直接截图发送二维码</div>
        <div class="edit" v-if="isShow" @click="show">编辑二维码</div>

        <div class="preservation" v-if="!isShow">
          <div class="register_price">
            <div class="register_price_top">
              <div class="register_price_left">挂号金额</div>
              <input
                type="number"
                class="price_input"
                v-model="price_input"
                autofocus="autofocus"
                oninput="if(value<0)value=0.01 "
                style="text-align: right"
              />
            </div>
            <div class="register_price_bottom"></div>
          </div>
          <div class="doctor_name">
            <div class="register_price_top">
              <div class="register_price_left">医生信息（选填）</div>
              <div>
                <input
                  type="text"
                  style="text-align: right"
                  class="getdoctor"
                  @click="getname"
                  placeholder="请选择医生>"
                  v-model="d_name"
                />

                <!-- <el-select v-model="value" class="m-2" placeholder="请选择医生" size="large"   @click="getname"> -->
                <van-popup v-model:show="showDoctor" position="bottom">
                  <van-picker
                    title="选择医生"
                    :columns="docotrList"
                    @confirm="onConfirm"
                    @cancel="onCancel"
                    @change="onChange"
                  />
                </van-popup>
              </div>
            </div>
            <div class="register_price_bottom"></div>
          </div>
          <div class="doctor_zc">
            <div class="register_price_top">
              <div class="register_price_left">医生职称（选填）</div>
              <input
                type="text"
                class="price_zc"
                style="text-align: right"
                v-model="d_zc"
                disabled
              />
            </div>
          </div>
          <div class="btn">
            <div class="btn-under" @click="under">保存</div>
          </div>
        </div>
      </div>
      <div class="tips">
        <div class="tipstop">注：</div>
        <div class="tipstop">
          1.支付二维码或支付卡片链接永久有效，发送给患者提示的30分钟内支付只是促进患者尽快支付
        </div>
        <div class="tipsbottom">
          2.支付二维码仅收费使用，不代表已挂号成功，给当前患者预约挂号后方可正常问诊
        </div>
      </div>
    </div>
    <div class="mine_mine"></div>
    <div class="bottom_list">
      <div class="title" style="margin-top: 27px">
        <div class="line"></div>
        <div class="mine">历史二维码</div>
      </div>
      <div class="list">
        <div
          class="list_wrap"
          v-for="(item, index) in logArray"
          :key="item.index"
          :value="item.index"
        >
          <div class="list_left">
            <div class="list_price">￥{{ item.money }}</div>
            <div class="list_msg">
              <div class="name">{{ item.doctor_name }}</div>
              <div class="zc">{{ item.doctor_job }}</div>
            </div>
          </div>
          <div class="list_delete" @click="deleteImg(index)">
            <img src="../assets/delete.png" alt="" class="delete" />
          </div>
          <div class="list_right" @click="getImg(item)">查看二维码></div>
        </div>
      </div>
    </div>
    <div class="model" v-if="showModel">
      <div class="qrcodeImg">
        <div class="qrcode_copy">
          <div class="close" @click="close">
            <img src="../assets/close.png" alt="" />
          </div>
          <div class="qrcode_top_copy">
            <div class="hospital_name_copy">医云在线互联网医院</div>
            <div class="qrcode_img">
              <img :src="qrcodeUrl" alt="" class="qrocde_img" />
            </div>
            <div class="doctorMsg">
              <div class="doctorName">{{ doctorName }}</div>
              <div class="doctorZc">{{ doctorZc }}</div>
            </div>
            <div class="prices_copy">挂号费￥{{ price }}</div>
          </div>
          <div class="qrcode_bottom">
            <div class="send_url" @click="sendTopUrl()">
              <div class="send_url_img">
                <img src="../assets/Frame@2x.png" alt="" class="" />
              </div>
              <div class="send_url_font">发送支付链接</div>
            </div>
            <div class="bottom_line"></div>
            <div class="send_qrcode" @click="sendQrcode('bottom')">
              <div class="send_qrcode_img">
                <img src="../assets/Frame@2x.png" alt="" />
              </div>
              <div class="send_qrcode_font">发送二维码</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div ref="imageWrapper" class="imageWrapper" id="imageWrapper">
    <div class="imageWrap1">
      <img src="../assets/Vector@2x.png" alt="" class="nine" /> 您已成功预约
    </div>
    <div class="imageWrap" v-if="d_name">{{ d_name }}医生的线上咨询服务</div>
    <div class="imageWrap" v-else>线上咨询服务</div>
    <div class="imageWrap">挂号二维码</div>
    <img :src="urlCheck" alt="" class="img_canvas" />
    <div class="onlytime">
      <div class="left_img">
        <img src="../assets/Frames@2x.png" alt="" class="qrcode_img_icon" />
      </div>
      <div class="right_title">
        <div class="top_title">请30分钟之内</div>
        <div class="bottom_title">微信识别二维码完成支付</div>
      </div>
    </div>
  </div>
  <div class="userinfo">
    <div class="user_title">当前联系人：</div>
    <div class="user_info">
      <img :src="wechatimg" class="wechatimg" alt="" />
      {{ wechatname }}
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { defineComponent, ref, computed, onMounted } from 'vue'
import axios from 'axios'
import { Toast } from 'vant'
// eslint-disable-next-line
import html2canvas from 'html2canvas'
import http from '../utils/http/http'
import { logins } from '../utils/http/logins'
import { urlencodeUrl } from '../utils/urlencodeUrl'

export default defineComponent({
  name: 'QrCode',
  props: {
    msg: String,
    id: String,
    key: Number,
  },
  // eslint-disable-next-line
  setup(props) {
    onMounted(() => {
      // getQrcode()
      // getDoctor()
      // getList()
    })
    const isShow = ref(true)
    const value = ref('')
    const showModel = ref(false)
    const doctorName = ref('')
    const doctorZc = ref('')
    const qrcodeUrl = ref('')
    const price = ref('')
    const doctor_zc = ref('')
    const ghPrice = ref('')
    const qrocdeUrl = ref('')
    const options = ref([])
    let urlCheck = ref('')
    const logArray = ref([])
    let showDoctor = ref(false)
    let docotrList = ref([])
    let price_input = ref(50)
    let d_name = ref('')
    let d_zc = ref('')
    let d_id = ref('')
    const imageWrapper = ref()
    const wechatimg = ref('')
    const wechatname = ref('')
    let wx = window.wx
    console.log(wx)
    const token = window.localStorage.getItem('token')
    const htmlUrl = ref('')
    let getUrl = window.location.href
    const corpid = window.localStorage.getItem('corpid')

    let urlparams = {
      url: getUrl,
    }
    const agentid = window.localStorage.getItem('agentid')

    axios
      .post(`/api/enterprise/auth/sign/app`, urlparams, {
        headers: { Authorization: `bearer ${token}` },
      })
      .then((res) => {
        console.log('app成功')
        wx.agentConfig({
          corpid: corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid: agentid, // 必填，企业微信的应用id （e.g. 1000247）
          timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.data.nonce_str, // 必填，生成签名的随机串
          signature: res.data.data.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
          jsApiList: ['getCurExternalContact', 'sendChatMessage'], //必填，传入需要使用的接口名称
          success: function () {
            console.log('success失败')

            // 回调
            wx.invoke('getCurExternalContact', {}, function (res) {
              console.log(res)

              if (res.err_msg == 'getCurExternalContact:ok') {
                console.log(res)
                console.log('userid失败')

                window.localStorage.setItem('userid', res.userId)

                let paramsss = {
                  external_user_id: res.userId,
                }
                axios
                  .post('/api/enterprise/getExternalUserInfo', paramsss, {
                    headers: { Authorization: `bearer ${token}` },
                  })
                  .then((res) => {
                    if (res.data.code == 200) {
                      wechatimg.value = res.data.data.external_user_info.external_contact.avatar
                      wechatname.value = res.data.data.external_user_info.external_contact.name
                    }

                    console.log(res)
                  })
                axios
                  .post('/api/enterprise/showRegisterCode', paramsss, {
                    headers: { Authorization: `bearer ${token}` },
                  })
                  .then((res) => {
                    if (res.data.code == 200) {
                      ghPrice.value = res.data.data.money
                      qrocdeUrl.value = 'data:image/png;base64,' + res.data.data.code
                    }
                    console.log(res)
                  })
                  .catch((err) => {
                    if (err.response.data.code == 401) {
                      // logins()
                    } else {
                      // Toast(err.response.data.message)
                    }
                  })
              } else {
                console.log(res)
                console.log('userid失败catch')

                //错误处理
              }
            })
          },
          fail: function (res) {
            console.log('useridfail')
            console.log(res)
            if (res.errMsg.indexOf('function not exist') > -1) {
              alert('版本过低请升级')
            }
          },
        })
      })
      .catch((err) => {
        console.log(err.response)
        // logins()

        if (err.response.data.code == 401) {
          // setTimeout(() => {
          logins()
          // }, 10000)
        }
      })

    function onConfirm(value, index) {
      console.log(value)
      d_name.value = value
      d_zc.value = options.value[index].doctor_title
      d_id.value = options.value[index].doctor_id
      did.value = options.value[index].did
      showDoctor.value = false

      console.log(value, index)
    }
    function onCancel() {
      showDoctor.value = false
    }
    function onChange(value, index) {
      console.log(value, index)
    }
    function getname() {
      showDoctor.value = true
    }

    // let params = {
    //   external_user_id: window.localStorage.getItem('userid'),
    // }
    // axios
    //   .post('/api/enterprise/showRegisterCode', params, {
    //     headers: { Authorization: `bearer ${token}` },
    //   })
    //   .then((res) => {
    //     if (res.data.code == 200) {
    //       ghPrice.value = res.data.data.money
    //       qrocdeUrl.value = 'data:image/png;base64,' + res.data.data.code
    //     }
    //     console.log(res)
    //   })
    //   .catch((err) => {
    //     if (err.response.data.code == 401) {
    // logins()
    //     } else {
    //       // Toast(err.response.data.message)
    //     }
    //   })
    async function getQrcode() {
      let params = {
        external_user_id: window.localStorage.getItem('userid'),
      }
      await axios
        .post('/api/enterprise/showRegisterCode', params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          if (res.data.code == 200) {
            ghPrice.value = res.data.data.money
            qrocdeUrl.value = 'data:image/png;base64,' + res.data.data.code
          }
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            // logins()
          } else {
            // Toast(err.response.data.message)
          }
        })
    }
    async function getDoctor() {
      let params = {
        only_managed: 0,
      }
      await axios
        .post('/api/enterprise/organizationDoctorList', params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          if (res.data.code == 200) {
            console.log(res)
            options.value = res.data.data
            docotrList.value = options.value.map((item) => {
              return item.doctor_name
            })
          }
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            // logins()
          } else {
            // Toast(err.response.data.message)
          }
        })
    }
    let paramss = {
      only_managed: 0,
    }
    axios
      .post('/api/enterprise/organizationDoctorList', paramss, {
        headers: { Authorization: `bearer ${token}` },
      })
      .then((res) => {
        if (res.data.code == 200) {
          console.log(res)
          options.value = res.data.data
          docotrList.value = options.value.map((item) => {
            return item.doctor_name
          })
        }
      })
      .catch((err) => {
        console.log(9898989)
        if (err.response.data.code == 401) {
          // logins()
        } else {
          // Toast(err.response.data.message)
        }
      })
    async function getList() {
      await axios
        .post('/api/enterprise/listRegisterCode', '', {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          if (res.data.code == 200) {
            console.log(res)
            logArray.value = res.data.data
          }
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            // logins()
          } else {
            // Toast(err.response.data.message)
          }
        })
    }
    axios
      .post('/api/enterprise/listRegisterCode', '', {
        headers: { Authorization: `bearer ${token}` },
      })
      .then((res) => {
        console.log(res)
        if (res.data.code == 200) {
          logArray.value = res.data.data
        }
      })
      .catch((err) => {
        if (err.response.data.code == 401) {
          // logins()
        } else {
          // Toast(err.response.data.message)
        }
      })
    async function deleteList(id) {
      let params = {
        id: id,
      }
      await axios
        .post('/api/enterprise/updateRegisterCode', params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          console.log(res.data.code)
          if (res.data.code == 200) {
            Toast('删除成功')
            getList()
            getQrcode()
          }
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            // logins()
          } else {
            // Toast(err.response.data.message)
          }
        })
    }
    //编辑事件
    function show() {
      isShow.value = false
    }
    const did = ref('')
    //保存
    async function under() {
      console.log(typeof price_input.value)

      if (price_input.value <= 0) {
        Toast('挂号金额必须大于0')
      } else {
        isShow.value = true
        let params = {
          doctor_id: d_id.value,
          money: price_input.value,
          doctor_job: d_zc.value,
          doctor_name: d_name.value,
          did: did.value,
          external_user_id: window.localStorage.getItem('userid'),
        }
        await axios
          .post('/api/enterprise/addRegisterCode', params, {
            headers: { Authorization: `bearer ${token}` },
          })
          .then((res) => {
            console.log(res)
            if (res.data.code == 200) {
              Toast('保存成功')
              ghPrice.value = res.data.data.money
              qrocdeUrl.value = 'data:image/png;base64,' + res.data.data.code
              getList()
            }
          })
          .catch((err) => {
            if (err.response.data.code == 401) {
              // logins()
            } else {
              // Toast(err.response.data.message)
            }
          })
      }
    }
    function deleteImg(e) {
      let id = logArray.value[e].id
      deleteList(id)
    }
    let senddoctorname = ref('')
    let sendid = ref('')
    let sendmoney = ref('')
    function getImg(e) {
      console.log(e)
      sendid.value = e.id
      sendmoney.value = e.money
      senddoctorname.value = e.doctor_name
      let params = {
        id: e.id,
        type: 3,
        payer: 1,
        external_user_id: window.localStorage.getItem('userid'),
      }
      axios
        .post('/api/enterprise/qrCode1', params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          if (res.data.code == 200) {
            console.log(res.data.data)
            qrcodeUrl.value = 'data:image/png;base64,' + res.data.data.code
            doctorName.value = e.doctor_name
            doctorZc.value = e.doctor_job
            price.value = e.money
            showModel.value = true
          }
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            // logins()
          } else {
            // Toast(err.response.data.message)
          }
        })
      // console.log(e)
    }
    function close() {
      showModel.value = false
    }
    function sendUrl() {
      console.log(logArray.value[0].id)
      console.log(logArray.value[0].money)
      let organization = window.localStorage.getItem('organization')
      if (logArray.value[0].doctor_name) {
        if (organization != 'mingyi') {
          let sendurl = ''
          // let url =
          //   'https://cloud1-5g80ysht3e049128-1306117587.tcloudbaseapp.com/h5/jump-mp.html?sign=047e33b549a034725a9760fd3f5deebd&t=1644572774&mpUrl='
          // sendurl =
          //   url +
          //   encodeURIComponent(
          //     `/pages/send-code/send-code?id=${logArray.value[0].id}&money=${
          //       logArray.value[0].money
          //     }&userid=${window.localStorage.getItem('userid')}`
          //   )

          sendurl = urlencodeUrl('/pages/send-code/send-code', {
            id: logArray.value[0].id,
            money: logArray.value[0].money,
            userid: window.localStorage.getItem('userid'),
          })

          wx.invoke(
            'sendChatMessage',
            {
              msgtype: 'text', //消息类型，必填
              enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
              text: {
                content: sendurl, //文本内容
              },
            },
            function (res) {
              if (res.err_msg == 'sendChatMessage:ok') {
                Toast('发送成功')
              }
            }
          )
        } else {
          wx.invoke(
            'sendChatMessage',
            {
              msgtype: 'miniprogram', //消息类型，必填
              enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
              miniprogram: {
                appid: 'wxee26553d4e0052ea', //小程序的appid，企业已关联的任一个小程序
                title: `您已成功预约${logArray.value[0].doctor_name}医生的线上问诊服务，请30分钟内完成支付`, //小程序消息的title
                imgUrl: 'https://xcxapi.myzx.cn/assets/sfm.png', //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
                page: `pages/send-code/send-code.html?id=${logArray.value[0].id}&money=${
                  logArray.value[0].money
                }&userid=${window.localStorage.getItem('userid')}`, //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
              },
            },
            function (res) {
              if (res.err_msg == 'sendChatMessage:ok') {
                Toast('发送成功')
              }
            }
          )
        }
      } else {
        if (organization != 'mingyi') {
          let sendurl = ''
          // let url =
          //   'https://cloud1-5g80ysht3e049128-1306117587.tcloudbaseapp.com/h5/jump-mp.html?sign=047e33b549a034725a9760fd3f5deebd&t=1644572774&mpUrl='
          // sendurl =
          //   url +
          //   encodeURIComponent(
          //     `/pages/send-code/send-code?id=${logArray.value[0].id}&money=${
          //       logArray.value[0].money
          //     }&userid=${window.localStorage.getItem('userid')}`
          //   )

          sendurl = urlencodeUrl('/pages/send-code/send-code', {
            id: logArray.value[0].id,
            money: logArray.value[0].money,
            userid: window.localStorage.getItem('userid'),
          })

          wx.invoke(
            'sendChatMessage',
            {
              msgtype: 'text', //消息类型，必填
              enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
              text: {
                content: sendurl, //文本内容
              },
            },
            function (res) {
              if (res.err_msg == 'sendChatMessage:ok') {
                Toast('发送成功')
              }
            }
          )
        } else {
          wx.invoke(
            'sendChatMessage',
            {
              msgtype: 'miniprogram', //消息类型，必填
              enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
              miniprogram: {
                appid: 'wxee26553d4e0052ea', //小程序的appid，企业已关联的任一个小程序
                title: '您已成功预约线上问诊服务，请30分钟内完成支付', //小程序消息的title
                imgUrl: 'https://xcxapi.myzx.cn/assets/sfm.png', //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
                page: `pages/send-code/send-code.html?id=${logArray.value[0].id}&money=${
                  logArray.value[0].money
                }&userid=${window.localStorage.getItem('userid')}`, //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
              },
            },
            function (res) {
              if (res.err_msg == 'sendChatMessage:ok') {
                Toast('发送成功')
                // window.location.reload()
                //发送成功
              }
            }
          )
        }
      }
    }

    function sendQrcode(source) {
      console.log(doctorName.value)
      if (source == 'top') {
        urlCheck.value = qrocdeUrl.value
        d_name.value = logArray.value[0].doctor_name
      } else {
        urlCheck.value = qrcodeUrl.value
        d_name.value = doctorName.value || ''
      }
      setTimeout(() => {
        html2canvas(document.querySelector('#imageWrapper')).then((canvas) => {
          console.log(canvas.toDataURL('image/png'))
          let params = {
            base_img: canvas.toDataURL('image/png'),
          }
          axios
            .post('/api/enterprise/getMediaID', params, {
              headers: { Authorization: `bearer ${token}` },
            })
            .then((res) => {
              if (res.data.code == 200) {
                wx.invoke(
                  'sendChatMessage',
                  {
                    msgtype: 'image', //消息类型，必填
                    enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
                    image: {
                      mediaid: res.data.data.media_id, //图片的素材id
                    },
                  },
                  function (res) {
                    if (res.err_msg == 'sendChatMessage:ok') {
                      Toast('发送成功')
                      showModel.value = false
                      // setTimeout(() => {
                      //   window.location.reload()
                      // }, 1000)
                      //发送成功
                    }
                  }
                )
              }
            })
            .catch((err) => {
              if (err.response.data.code == 401) {
                // logins()
              }
              // Toast(err.response.data.message)
            })
        })
      }, 1000)
    }

    function sendTopUrl() {
      console.log(sendid.value)
      console.log(sendmoney.value)
      let organization = window.localStorage.getItem('organization')
      if (senddoctorname.value) {
        if (organization != 'mingyi') {
          let sendurl = ''
          // let url =
          //   'https://cloud1-5g80ysht3e049128-1306117587.tcloudbaseapp.com/h5/jump-mp.html?sign=047e33b549a034725a9760fd3f5deebd&t=1644572774&mpUrl='
          // sendurl =
          //   url +
          //   encodeURIComponent(
          //     `/pages/send-code/send-code?id=${sendid.value}&money=${
          //       sendmoney.value
          //     }&userid=${window.localStorage.getItem('userid')}`
          //   )

          sendurl = urlencodeUrl('/pages/send-code/send-code', {
            id: sendid.value,
            money: sendmoney.value,
            userid: window.localStorage.getItem('userid'),
          })

          wx.invoke(
            'sendChatMessage',
            {
              msgtype: 'text', //消息类型，必填
              enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
              text: {
                content: sendurl, //文本内容
              },
            },
            function (res) {
              if (res.err_msg == 'sendChatMessage:ok') {
                Toast('发送成功')
              }
            }
          )
        } else {
          wx.invoke(
            'sendChatMessage',
            {
              msgtype: 'miniprogram', //消息类型，必填
              enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
              miniprogram: {
                appid: 'wxee26553d4e0052ea', //小程序的appid，企业已关联的任一个小程序
                title: `您已成功预约${senddoctorname.value}医生的线上问诊服务，请30分钟内完成支付`, //小程序消息的title
                imgUrl: 'https://xcxapi.myzx.cn/assets/sfm.png', //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
                page: `pages/send-code/send-code.html?money=${sendmoney.value}&id=${
                  sendid.value
                }&userid=${window.localStorage.getItem('userid')}`,
              },
            },
            function (res) {
              if (res.err_msg == 'sendChatMessage:ok') {
                Toast('发送成功')
              }
            }
          )
        }
      } else {
        if (organization != 'mingyi') {
          let sendurl = ''
          // let url =
          //   'https://cloud1-5g80ysht3e049128-1306117587.tcloudbaseapp.com/h5/jump-mp.html?sign=047e33b549a034725a9760fd3f5deebd&t=1644572774&mpUrl='
          // sendurl =
          //   url +
          //   encodeURIComponent(
          //     `/pages/send-code/send-code?id=${sendid.value}&money=${
          //       sendmoney.value
          //     }&userid=${window.localStorage.getItem('userid')}`
          //   )
          sendurl = urlencodeUrl('/pages/send-code/send-code', {
            id: sendid.value,
            money: sendmoney.value,
            userid: window.localStorage.getItem('userid'),
          })

          wx.invoke(
            'sendChatMessage',
            {
              msgtype: 'text', //消息类型，必填
              enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
              text: {
                content: sendurl, //文本内容
              },
            },
            function (res) {
              if (res.err_msg == 'sendChatMessage:ok') {
                Toast('发送成功')
              }
            }
          )
        } else {
          wx.invoke(
            'sendChatMessage',
            {
              msgtype: 'miniprogram', //消息类型，必填
              enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
              miniprogram: {
                appid: 'wxee26553d4e0052ea', //小程序的appid，企业已关联的任一个小程序
                title: `您已成功预约线上问诊服务，请30分钟内完成支付`, //小程序消息的title
                imgUrl: 'https://xcxapi.myzx.cn/assets/sfm.png', //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
                page: `pages/send-code/send-code.html?money=${sendmoney.value}&id=${
                  sendid.value
                }&userid=${window.localStorage.getItem('userid')}`,
              },
            },
            function (res) {
              if (res.err_msg == 'sendChatMessage:ok') {
                Toast('发送成功')
                // setTimeout((res) => {
                //   window.location.reload()
                // }, 1000)

                //发送成功
              }
            }
          )
        }
      }

      // .catch((err) => {})
    }

    // eslint-disable-next-line
    return {
      deleteImg,
      getImg,
      show,
      under,
      close,
      getQrcode,
      getDoctor,
      getList,
      getname,
      deleteList,
      sendQrcode,
      onConfirm,
      onCancel,
      sendUrl,
      onChange,
      wechatimg,
      wechatname,
      isShow,
      showDoctor,
      price_input,
      value,
      options,
      logArray,
      showModel,
      doctorName,
      doctorZc,
      qrcodeUrl,
      price,
      doctor_zc,
      ghPrice,
      qrocdeUrl,
      token,
      docotrList,
      imageWrapper,
      htmlUrl,
      d_name,
      d_zc,
      d_id,
      urlCheck,
      sendid,
      sendTopUrl,
      sendmoney,
      did,
      senddoctorname,
      corpid,
    }
  },
})
</script>
<style scoped>
.wrap {
  background: #ffffff;
  height: 100%;
}
.top {
  width: 100%;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
}
.section {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}
.title {
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 20px;
}
.line {
  background: #359eff;
  width: 5px;
  height: 18px;
  border-radius: 10px;
}
.mine {
  margin-left: 10px;
  color: #343434;
  font-size: 14px;
  font-weight: 400;
  color: #343434;
}
.qrcode {
  width: 323px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 4px rgba(215, 215, 215, 0.25);
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  margin-top: 20px;
}

.qrcode_top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}
.qrcode_img {
  margin-top: 16px;
}
.qrcode_bottom {
  width: 100%;
  display: flex;
  height: 52px;
  background: #fafafb;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  margin-top: 22px;
  justify-content: center;
  align-items: center;
}
.price {
  color: #f5a300;
  font-size: 18px;
  margin-top: 19px;
  color: #f5a300;
  font-weight: 500;
}
.prices {
  color: #f5a300;
  font-size: 18px;
  margin-top: 19px;
  color: #f5a300;
  font-weight: 500;
  margin-bottom: 20px;
}
.prices_copy {
  color: #f5a300;
  font-size: 18px;
  margin-top: 5px;
  color: #f5a300;
  font-weight: 500;
  /* margin-bottom: 20px; */
}
.send_url {
  width: 50%;
  font-size: 14px;
  font-weight: 600;
  color: #343434;
  text-align: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}
.send_url_font {
  margin-left: 5px;
}
.send_qrcode_font {
  cursor: pointer;
  margin-left: 5px;
}
.send_qrcode {
  width: 50%;
  font-size: 14px;
  font-weight: 600;
  color: #343434;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.bottom_line {
  height: 35px;
  width: 0px;
  opacity: 1;
  border: 1px solid #ebebeb;
}
.edit {
  text-decoration: underline;
  color: #359eff;
  text-align: center;
  margin-top: 27px;
  cursor: pointer;
}
.preservation {
  height: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 22px;
  width: 85%;
}
.register_price {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.register_price_top {
  width: 100%;
  display: flex;
  align-items: center;
}
.price_input {
  width: 30%;
  background: none;
  outline: none;
  color: black;
  border: none;
  font-size: 14px;
}
.register_price_left {
  width: 90%;
  color: #343434;
  font-size: 14px;
  font-weight: 500;
}
.register_price_bottom {
  width: 100%;
  height: 0px;
  opacity: 1;
  border: 1px solid #ebebeb;
  margin-top: 11px;
}

.doctor_name {
  width: 100%;
  margin-top: 16px;
}
.doctor_zc {
  margin-top: 16px;
  width: 100%;
}
.qrocde_img {
  width: 145px;
  height: 145px;
}
.send_url_img img {
  width: 22px;
  height: 22px;
}
.send_qrcode_img img {
  width: 22px;
  height: 22px;
}
.price_zc {
  color: #000000;
  border: none;
  outline: none;
  font-size: 12px;
  width: 16%;
}
.btn {
  margin-top: 28px;
}
.btn-under {
  width: 104px;
  height: 38px;
  background: #359eff;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  line-height: 38px;
}
.mine_mine {
  height: 9px;
  background: #f3f5f7;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin-top: 28px;
}

.list {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* margin-top: 19px; */
}
.list_wrap {
  width: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-top: 19px;
  margin-left: 30px;
}
.list_left {
  display: flex;
  width: 60%;
  flex-wrap: wrap;
  font-size: 13px;
}
.list_right {
  color: #359eff;
  width: 30%;
  font-size: 13px;
  cursor: pointer;
  font-size: 14px;
}
.list_delete {
  width: 20%;
  font-size: 13px;
  cursor: pointer;
}
.delete {
  width: 20px;
  height: 20px;
}

.list_price {
  width: 100%;
  font-size: 16px;
  color: #f5a300;
}
.list_msg {
  width: 100%;
  display: flex;
}
.zc {
  margin-left: 4px;
}
.qrcodeImg {
  margin: 0 auto;
  position: relative;
}
.model {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
}
.qrcode_copy {
  width: 310px;
  background: #ffffff;
  /* box-shadow: 0px 2px 8px 4px rgba(215, 215, 215, 0.25); */
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  margin-top: 150px;
}
.qrcode_top_copy {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}
.doctorMsg {
  display: flex;
  font-size: 14px;
  margin-top: 19px;
  font-weight: 500;
}
.hospital_name_copy {
  font-size: 16px;
  font-weight: 800;
  color: #343434;
}
.close {
  width: 10px;
  height: 20px;
  opacity: 1;
  color: #b0b4b7;
  position: relative;
  text-align: right;
}
.close img {
  width: 10px;
  height: 10px;
  right: -285px;
  position: absolute;
  top: 14px;
}
.doctorName {
  font-weight: 400;
  font-size: 14px;
}
.doctorZc {
  margin-left: 10px;
  font-weight: 400;
  font-size: 14px;
}
.getdoctor {
  border: none;
  outline: none;
  font-size: 13px;
}
:deep(.el-input__inner) {
  box-shadow: 0px 0px 0px 0px;
}
:deep(.el-select:hover:not(.el-select--disabled) .el-input__inner) {
  box-shadow: 0px 0px 0px 0px;
}
:deep(.el-select) {
  --el-select-input-focus-border-color: var(--el-color-white);
}
.imageWrapper {
  width: 290px;
  background: #359eff;
  flex-wrap: wrap;
  border-radius: 10px;
  margin-top: 30px;
  position: absolute;
  left: -400px;
}
.imageWrap1 {
  width: 100%;
  text-align: center;
  color: white;
  padding-top: 20px;
}
.imageWrap {
  width: 100%;
  text-align: center;
  color: white;
  margin-top: 10px;
}
.img_canvas {
  width: 143px;
  height: 133px;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
}
.nine {
  width: 15px;
  height: 15px;
}
.onlytime {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  padding-bottom: 20px;
  align-items: center;
}
.qrcode_img_icon {
  width: 28px;
  height: 28px;
}
.right_title {
  display: flex;
  flex-wrap: wrap;
  color: #f9f871;
  width: 70%;
}
.left_img {
  width: 30%;
  text-align: end;
}
.top_title {
  width: 90%;
}
.bottom_title {
  width: 90%;
}
.bottom_list {
  margin-bottom: 60px;
}
.tips {
  width: 100%;
  display: flex;
  font-size: 12px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
  color: #f5a300;
}
.tipstop {
  width: 90%;
}
.tipsbottom {
  width: 90%;
}
.userinfo {
  width: 100%;
  height: 35px;
  position: fixed;
  bottom: 0;
  background: white;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #cccccc;
}
.user_title {
  line-height: 40px;
}
.user_info {
  display: flex;
  line-height: 40px;
  text-align: right;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
}
.wechatimg {
  width: 30px;
  margin-right: 10px;
  height: 30px;
  border-radius: 15%;
}
</style>

<template>
  <div class="warp">
    <div class="total">
      <div class="title">您的合计收费记录</div>
      <div class="all">本月总金额</div>
      <div class="price">
        <div class="fh">￥</div>
        <div class="strong">{{ price }}</div>
      </div>
      <div class="charge">
        <div class="left">
          今日收费
          <div class="fh">￥</div>
          <div class="strong">{{ todaymoney }}</div>
        </div>
        <div class="line"></div>
        <div class="right">
          昨日收费
          <div class="fh">￥</div>
          <div class="strong">{{ lastdaymoney }}</div>
        </div>
      </div>
    </div>
    <div class="wechat">
      <img :src="imgurl" alt="" class="wechaturl" />
      <div class="wechatname">{{ wechatname }}</div>
    </div>
    <div class="section" v-for="item in list" :key="item.value" :label="index">
      <div class="order">
        <div class="order_left">收费编号：{{ item.order_no }}</div>
        <!-- <div class="order_right">
          {{ item.pay_status == 2 ? '已退款' : item.pay_status == 0 ? '未支付' : '已支付' }}
        </div> -->
        <div class="order_right_ytk" v-if="item.pay_status == 2">已退款</div>
        <div class="order_right_wzf" v-else-if="item.pay_status == 0">未支付</div>
        <div class="order_right_yzf" v-else>已支付</div>
      </div>
      <div class="user">
        <div class="user_left">患者姓名：{{ item.patient_name }} |</div>
        <div class="user_middle">手机号：{{ item.patient_phone }} |</div>
        <div class="user_right">微信：{{ item.nickname }}</div>
      </div>
      <div class="user_price">
        <div class="price_left">订单金额:￥{{ item.pay_money }} |</div>
        <div class="price_right">支付时间：{{ item.pay_time }}</div>
      </div>
      <div class="order_sn">
        <div class="order_sn_left">缴费编号：{{ item.order_no }}</div>
      </div>
      <div class="doctor">
        <div class="doctor_left" v-if="item.doctor_name">医生姓名：{{ item.doctor_name }} |</div>
        <div class="doctor_left" v-else>医生姓名：-- |</div>

        <div class="doctor_right" v-if="item.wz_order_sn">
          挂号编号：
          <div class="order_wz">{{ item.wz_order_sn }} 已挂号</div>
        </div>
        <div class="doctor_right" v-else>
          挂号编号：
          <div class="order_wz_no">未挂号</div>
          <div class="order_wz_gl" @click="goguanlian(item)">去关联</div>
        </div>
      </div>
      <div class="desc" v-if="item.desc">备注：{{ item.desc }}</div>
      <div class="desc" v-else>备注：暂无备注</div>
      <div class="lines"></div>
      <div class="button">
        <div
          class="registesr"
          v-if="item.refund_status != 2 && item.pay_status == 1"
          @click="tuikuan(item)"
        >
          快捷退款
        </div>
        <div class="register" @click="tuikuanlog(item)" v-if="item.pay_status == 2">退款日志</div>
        <div class="gocharge" @click="gocharge(item)" v-if="!item.wz_order_sn">去挂号</div>
      </div>
    </div>
    <div></div>
  </div>
  <div class="refundModel" v-if="isRefund">
    <div class="refundPrice" v-if="isRefund1">
      <div @click="closeModel"><img src="../assets/close.png" alt="" class="closeimg" /></div>
      <div class="one">快速退款</div>
      <div class="refundOrder">
        <div class="two">订单编号:</div>
        <div class="three">{{ order_id }}</div>
      </div>
      <div class="four">退款原因：</div>
      <div class="text">
        <textarea
          rows="5"
          placeholder="请输入退款原因"
          class="texts"
          v-model="refund_reason"
          maxlength="100"
        ></textarea>
      </div>
      <div class="sure" @click="refoundOk">确认退款</div>
    </div>
    <div class="refundPrice" v-if="isReson">
      <div @click="closeModel"><img src="../assets/close.png" alt="" class="closeimg" /></div>
      <div class="one">退款信息</div>
      <div class="refundOrder">
        <div class="two">订单编号:</div>
        <div class="three">{{ order_id }}</div>
      </div>
      <div class="four">原因：{{ refoundReson }}</div>
      <div class="four">
        时间：
        {{ resontime }}
      </div>
      <div class="sure" @click="resonok">确认</div>
    </div>
  </div>
  <van-popup v-model:show="show">
    <div class="model_gh">
      <div class="model_top">
        <input
          type="text"
          name=""
          id=""
          placeholder="请输入问诊订单编号"
          class="model_input"
          v-model="wz_order"
        />
      </div>
      <div class="model_bottom">
        <div class="model_close" @click="closegy">取消</div>
        <div class="model_sure" @click="gl()">确认</div>
      </div>
    </div>
  </van-popup>
</template>

<script>
// eslint-disable-next-line
import { defineComponent, ref, onMounted } from 'vue'
import axios from 'axios'
import { Toast } from 'vant'
// import { Toast } from 'vant'
import http from '../utils/http/http'
import { logins } from '../utils/http/logins'
// import { VueCookies } from 'VueCookies'

export default defineComponent({
  name: 'charge',
  props: {
    id: String,
    key: Number,
    // closebtn: Number,
  },

  setup(props, { emit }) {
    onMounted(() => {
      // getlist()
    })
    console.log(props)
    // let closebtn = props.closebtn
    let price = ref(0)
    let lastdaymoney = ref(0)
    let todaymoney = ref(0)
    const token = window.localStorage.getItem('token')
    let imgurl = ref('')
    let wechatname = ref('')
    let list = ref()
    let isRefund = ref(false)
    let isRefund1 = ref(false)
    let refund_reason = ref('')
    let isReson = ref(false)
    let order_id = ref('')
    let refoundid = ref('')
    let refoundReson = ref('')
    let resontime = ref('')
    let show = ref(false)
    let wz_order_id = ref('')
    let wz_sn = ref('')
    let wz_order_sn = ref('')
    let wz_order = ref('')
    let uesrid = ref('')
    let getUrl = window.location.href
    const corpid = window.localStorage.getItem('corpid')
    let urlparams = {
      url: getUrl,
    }
    const agentid = window.localStorage.getItem('agentid')
    axios
      .post(`/api/enterprise/auth/sign/app`, urlparams, {
        headers: { Authorization: `bearer ${token}` },
      })
      .then((res) => {
        wx.agentConfig({
          corpid: corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid: agentid, // 必填，企业微信的应用id （e.g. 1000247）
          timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.data.nonce_str, // 必填，生成签名的随机串
          signature: res.data.data.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
          jsApiList: ['getCurExternalContact', 'sendChatMessage'], //必填，传入需要使用的接口名称
          success: function () {
            // 回调
            wx.invoke('getCurExternalContact', {}, function (res) {
              if (res.err_msg == 'getCurExternalContact:ok') {
                window.localStorage.setItem('userid', res.userId)
                uesrid.value = res.userId //返回当前外部联系人userId
                // window.location.reload()

                let paramsss = {
                  // external_user_id: 'wmrlZ3PgAA4JPZEbqH8yIBTc4JPpy2Ww',
                  external_user_id: window.localStorage.getItem('userid'),
                }
                axios
                  .post('/api/enterprise/getExternalUserInfo', paramsss, {
                    headers: { Authorization: `bearer ${token}` },
                  })
                  .then((res) => {
                    if (res.data.code == 200) {
                      imgurl.value = res.data.data.external_user_info.external_contact.avatar
                      wechatname.value = res.data.data.external_user_info.external_contact.name
                    }
                  })

                //获取微信信息
                let params = {
                  external_user_id: res.userId,
                }
                // let params = {
                //   external_user_id: 'wmrlZ3PgAA4JPZEbqH8yIBTc4JPpy2Ww',
                // }
                //获取列表信息
                axios
                  .post('/api/enterprise/listRegisterOrder', params, {
                    headers: { Authorization: `bearer ${token}` },
                  })
                  .then((res) => {
                    price.value = res.data.data.mmoney
                    lastdaymoney.value = res.data.data.lastdaymoney
                    todaymoney.value = res.data.data.todaymoney
                    list.value = res.data.data.list
                  })
                  .catch((err) => {
                    if (err.response.data.code == 401) {
                      logins()
                    }
                  })
              } else {
                //错误处理
              }
            })
          },
          fail: function (res) {
            if (res.errMsg.indexOf('function not exist') > -1) {
              alert('版本过低请升级')
            }
          },
        })
      })
      .catch((err) => {
        logins()

        console.log(err.response)
        if (err.response.data.code == 401) {
          logins()
        }
      })

    //挂号
    function gocharge(item) {
      console.log(item)

      window.sessionStorage.setItem('chargeid', item.id)
      emit('chargeid')
    }

    // VueCookies.set('111', 222)

    //退款
    function tuikuan(item) {
      isRefund.value = true
      isRefund1.value = true
      order_id.value = item.order_no
      refoundid.value = item.id
    }

    //关闭弹框
    function closeModel() {
      isRefund.value = false
      isRefund1.value = false
      isReson.value = false
      refund_reason.value = null
    }

    //确认退款
    function refoundOk() {
      if (refund_reason.value) {
        let params = {
          id: refoundid.value,
          reason: refund_reason.value,
          type: 3,
        }
        axios
          .post('/api/enterprise/refendRegisterOrder', params, {
            headers: { Authorization: `bearer ${token}` },
          })
          .then((res) => {
            if (res.data.code == 200) {
              closeModel()
              Toast('退款成功')
              setTimeout((res) => {
                window.location.reload()
              }, 200)
              refund_reason.value = null
            } else {
              Toast(res.data.message)
            }
          })
          .catch((err) => {
            if (err.response.data.code == 401) {
              logins()
            } else {
              Toast(err.response.data.message)
            }
          })
      } else {
        Toast('退款原因必填')
      }
    }
    //退款日志
    function tuikuanlog(item) {
      isRefund.value = true
      isReson.value = true
      order_id.value = item.order_no
      resontime.value = item.refund_time
      refoundReson.value = item.refund_reason
    }

    //退款日志确认
    function resonok() {
      closeModel()
    }

    //关联
    function goguanlian(item) {
      show.value = true
      wz_order_id.value = item.id
      wz_sn.value = item.order_no
    }
    //确认
    function gl() {
      if (wz_order.value) {
        let params = {
          id: wz_order_id.value,
          order_no: wz_sn.value,
          wz_order_no: wz_order.value,
        }
        axios
          .post('/api/enterprise/relationWzorder', params, {
            headers: { Authorization: `bearer ${token}` },
          })
          .then((res) => {
            console.log(res)
            if (res.data.code == 200) {
              Toast('关联成功')
              setTimeout((res) => {
                window.location.reload()
              }, 1000)
            }
          })
          .catch((err) => {
            if (err.response.data.code == 401) {
              logins()
            } else {
              Toast(err.response.data.message)
            }
          })
      } else {
        Toast('请正确输入问诊订单编号')
      }
    }
    //取消
    function closegy() {
      show.value = false
      wz_order.value = ''
    }

    function getlist() {
      //  let params = {
      //             external_user_id: res.userId,
      //           }
      let params = {
        external_user_id: window.localStorage.getItem('userid'),
      }
      //获取列表信息
      axios
        .post('/api/enterprise/listRegisterOrder', params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          price.value = res.data.data.mmoney
          lastdaymoney.value = res.data.data.lastdaymoney
          todaymoney.value = res.data.data.todaymoney
          list.value = res.data.data.list
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          }
        })
    }
    // let paramss = {
    //   external_user_id: window.localStorage.getItem('userid'),
    // }
    // axios
    //   .post('/api/enterprise/getExternalUserInfo', paramss, {
    //     headers: { Authorization: `bearer ${token}` },
    //   })
    //   .then((res) => {
    //     console.log(res)
    //     imgurl.value = res.data.data.external_user_info.external_contact.avatar || ''
    //     wechatname.value = res.data.data.external_user_info.external_contact.name || ''
    //   })
    //   .catch((err) => {
    //     if (err.response.data.code == 401) {
    //       logins()
    //     } else {
    //       Toast(err.response.data.message)
    //     }
    //   })
    return {
      getlist,
      closegy,
      price,
      imgurl,
      wechatname,
      lastdaymoney,
      todaymoney,
      list,
      gocharge,
      tuikuan,
      isRefund,
      isRefund1,
      order_id,
      refund_reason,
      closeModel,
      isReson,
      refoundOk,
      tuikuanlog,
      refoundReson,
      resontime,
      resonok,
      goguanlian,
      show,
      gl,
      wz_order_id,
      wz_sn,
      wz_order_sn,
      wz_order,
      getUrl,
      corpid,
      token,
      uesrid,
    }
  },
})
</script>

<style scoped>
.warp {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #f3f5f7;
}
.total {
  width: 95%;
  background: #359eff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  color: white;
  margin-top: 20px;
}
.title {
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  line-height: 18px;
  margin-top: 13px;
  margin-left: 13px;
}
.all {
  width: 100%;
  text-align: center;
  margin-top: 18px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  line-height: 25px;
}
.price {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
}
.price_right {
  margin-left: 7px;
}
.fh {
  font-size: 14px;
}
.doctor_right {
  margin-left: 7px;
}
.strong {
  font-size: 20px;
  height: 25px;
}
.charge {
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  margin-bottom: 20px;
}
.left {
  display: flex;
  align-items: center;
  margin-left: 20px;
  font-size: 12px;
}
.line {
  width: 2px;
  height: 17px;
  opacity: 1;
  background: #ffffff;
  margin-top: 5px;
  /* border: 1px solid #ffffff; */
}
.right {
  align-items: center;
  display: flex;
  font-size: 12px;
  margin-right: 20px;
}
.wechat {
  width: 95%;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.wechaturl {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  opacity: 1;
}
.wechatname {
  margin-left: 10px;
}
.section {
  width: 95%;
  margin-top: 10px;
  background: white;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
}
.order {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.order_left {
  font-weight: 400;
  color: #999999;
}
.order_right {
  color: #999999;
}
.user {
  width: 95%;
  font-size: 11px;
  display: flex;
  color: #999999;
  margin-top: 10px;
}
.user_price {
  width: 95%;
  font-size: 11px;
  color: #999999;
  display: flex;
  margin-top: 5px;
}
.order_sn {
  display: flex;
  color: #999999;
  font-size: 11px;
  margin-top: 5px;
  width: 95%;
}
.doctor {
  display: flex;
  color: #999999;
  margin-top: 5px;
  font-size: 11px;
  width: 95%;
}
.desc {
  font-size: 11px;
  margin-top: 5px;
  color: #999999;
  width: 95%;
}
.user_left {
  /* width: 33.3%; */
  font-size: 11px;
}
.user_middle {
  font-size: 11px;
  margin-left: 7px;
  /* width: 33.3%; */
}
.user_right {
  font-size: 11px;
  margin-left: 7px;

  /* width: 33.3%; */
}
.lines {
  width: 95%;
  height: 0px;
  opacity: 1;
  border: 1px solid #ebebeb;
  margin-top: 10px;
}
.gocharge {
  width: 72px;
  height: 27px;
  background: #359eff;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  color: white;
  text-align: center;
  cursor: pointer;

  line-height: 27px;
}
.order_left {
  color: #343434;
}
.registesr {
  width: 80px;
  height: 25px;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  border: 1px solid #c9c9c9;
  color: #181818;
  margin-right: 10px;
  line-height: 25px;
  text-align: center;
  cursor: pointer;
}
.register {
  color: #181818;
  width: 80px;
  height: 25px;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  border: 1px solid #c9c9c9;
  text-align: center;
  line-height: 25px;
  margin-right: 10px;
  cursor: pointer;
}
.button {
  width: 95%;
  display: flex;
  text-align: center;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 10px;
}
.refundModel {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.refundPrice {
  width: 70%;
  z-index: 999;
  opacity: 1;
  margin: 200px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  align-items: center;
  position: relative;
}
.one {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.closeimg {
  width: 13px;
  height: 13px;
  position: absolute;
  top: 15px;
  right: 15px;
}
.refundOrder {
  width: 90%;
  margin-top: 20px;
  display: flex;
  margin-left: 20px;
  align-items: center;
}
.text,
.four {
  margin-left: 20px;
  margin-top: 20px;
  width: 90%;
}
.texts {
  width: 90%;
  /* border: 1px solid #f7f7f7; */
}
.sure {
  text-align: center;
  width: 100px;
  height: 30px;
  background: #359eff;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  color: white;
  line-height: 30px;
  margin-bottom: 20px;
  margin-top: 20px;
  cursor: pointer;
}
.order_right_ytk {
  color: #f1ba6d;
}
.order_right_wzf {
  color: #e36060;
}
.order_right_yzf {
  color: #999999;
}
.doctor_right {
  display: flex;
}
.order_wz {
  color: #f1ba6d;
}
.order_wz_no {
  color: #e36060;
}
.order_wz_gl {
  margin-left: 5px;
  background: #f0f8ff;
  color: #359eff;
  border-radius: 3px 3px 3px 3px;
  opacity: 1;
  cursor: pointer;

  /* opacity: 0.7; */
}
.model_gh {
  width: 300px;
  background: white;
  height: 130px;
  display: flex;
  flex-wrap: wrap;
}
.model_top {
  width: 100%;
  margin-top: 20px;
  text-align: center;
}
.model_input {
  width: 80%;
  border-radius: 3px 3px 3px 3px;
  opacity: 1;
  border: 1px solid #f7f7f7;
  height: 30px;
}
.model_bottom {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: space-around;
}
.model_close {
  text-align: center;
  width: 40%;
  border-radius: 20px 20px 20px 20px;
  height: 30px;
  opacity: 1;
  border: 1px solid #c9c9c9;
  line-height: 30px;
  cursor: pointer;
}
.model_sure {
  text-align: center;
  width: 40%;
  background: #359eff;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  color: white;
  line-height: 30px;
  height: 30px;
  cursor: pointer;
}
</style>

<template>
  <div class="two">
    <div class="ques">
      <div class="ques_warp">
        <van-collapse v-model="activeNames">
          <van-collapse-item v-for="(item, index) in list">
            <template #title>
              <div>{{ item.title }}</div>
            </template>
            <div class="content">
              <div class="content-top" v-if="item.type == 1">
                {{ item.content }}
              </div>
              <div class="content-top" v-if="item.type == 2">
                <img :src="item.content" alt="" class="image" />
              </div>
              <div class="content-top" v-if="item.type == 3">
                <video :src="item.content" class="video" autoplay></video>
              </div>
              <div class="content-bottom" @click="send(item.content, item.type)">一键发送</div>
            </div>
          </van-collapse-item>
        </van-collapse>
      </div>
    </div>
    <div class="none">
      <div class="file" @click="add">
        <img src="../assets/file.png" alt="" class="file-img" />添加话术
      </div>
    </div>
  </div>

  <van-overlay :show="show" @click="show = false">
    <div class="wrapper" @click.stop>
      <div class="block">
        <div class="back">
          <div class="icon" @click="close">
            <div class="backs"><van-icon name="arrow-left" /></div>
            <div class="backstitle">返回</div>
          </div>
          <div class="enter" @click="enter">完成</div>
        </div>
        <div class="addmsg">
          <div class="input">
            <van-field
              v-model="value"
              label="*话术标题"
              placeholder="请输入话术标题"
              maxlength="30"
            />
          </div>
          <div class="task-tilte">*任务类型</div>
          <div class="task-check">
            <van-radio-group v-model="checked" direction="horizontal">
              <van-radio name="1">文本</van-radio>
              <van-radio name="2">图片</van-radio>
              <van-radio name="3">视频</van-radio>
            </van-radio-group>
          </div>
          <div class="task-font" v-show="checked == 1">
            <van-field
              v-model="message"
              rows="2"
              autosize
              type="textarea"
              maxlength="200"
              placeholder="请输入内容"
              show-word-limit
            />
          </div>

          <div class="task-img" v-show="checked == 2">
            <div class="img-div">
              <van-uploader v-model="fileList" multiple max-count="1" :after-read="getFileImg" />
            </div>
          </div>

          <div class="task-img" v-show="checked == 3">
            <div class="img-div">
              <van-uploader multiple max-count="1" accept="video/*" :after-read="getFile" />
              <video
                style="width: 120px; height: 120px; object-fit: cover"
                :src="videourl"
                autoplay
                v-if="videourl"
              ></video>
            </div>
          </div>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
// eslint-disable-next-line
import { defineComponent, ref, computed, onMounted } from 'vue'
import axios from 'axios'
import { Toast } from 'vant'
import { getBase64 } from '../utils/urlencodeUrl'
export default defineComponent({
  name: 'own',
  props: {},
  // eslint-disable-next-line

  setup(props) {
    onMounted(() => {
      getList()
    })

    function getList() {
      axios
        .post(`/api/enterprise/listSmartScriptPersonal`, '', {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          console.log(res)
          if (res.data.code == 200) {
            list.value = res.data.data.list
          }
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
    }
    const activeNames = ref([''])

    const token = window.localStorage.getItem('token')
    const show = ref(false)
    const checked = ref('1')
    const message = ref()
    const fileList = ref()
    const videourl = ref()
    const value = ref()
    const content = ref()
    const list = ref()
    function enter() {
      console.log()
      if (value.value && (message.value || imgurl.value || videourl.value)) {
        let params = {
          title: value.value,
          type: checked.value,
          content:
            checked.value == 1 ? message.value : checked.value == 2 ? imgurl.value : videourl.value,
        }
        axios
          .post(`/api/enterprise/addSmartScriptPersonal`, params, {
            headers: { Authorization: `bearer ${token}` },
          })
          .then((res) => {
            console.log(res)
            if (res.data.code == 200) {
              list.value = res.data.data.list
              show.value = false
              message.value = ''
              value.value = ''

              checked.value = '1'
              imgurl.value = ''
              videourl.value = ''
              getList()
            }
          })
          .catch((err) => {
            if (err.response.data.code == 401) {
              logins()
            } else {
              Toast(err.response.data.message)
            }
          })
      } else {
        Toast.fail('请输入内容')
      }
    }
    const imgurl = ref()

    function getFileImg(file, detail) {
      var data = new FormData()
      data.append('file', file.file)
      let config = {
        headers: {
          //添加请求头
          'Content-Type': 'multipart/form-data',
        },
      }
      //上传图片
      axios.post('/api/upload/imgage', data, config).then((res) => {
        // console.log('res', res)
        console.log(res.data)
        if (res.data.code == 200) {
          imgurl.value = res.data.data.imgurl
        }
      })
    }

    function getFile(file, detail) {
      var data = new FormData()
      data.append('file', file.file)
      let config = {
        headers: {
          //添加请求头
          'Content-Type': 'multipart/form-data',
        },
      }
      //上传图片
      axios.post('/api/upload/imgage', data, config).then((res) => {
        console.log('res', res)
        if (res.data.code == 200) {
          videourl.value = res.data.data.imgurl
        }
      })
    }
    function add() {
      show.value = true
    }
    function close() {
      show.value = false
    }
    function send(item, type) {
      console.log(checked.value)
      console.log(item)
      if (type == 1) {
        wx.invoke(
          'sendChatMessage',
          {
            msgtype: 'text', //消息类型，必填
            enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
            text: {
              content: item, //文本内容
            },
          },
          function (res) {
            if (res.err_msg == 'sendChatMessage:ok') {
              Toast('发送成功')
            }
          }
        )
      } else if (type == 2) {
        getBase64(item, (dataURL) => {
          console.log('dataURL :>> ', dataURL)
          let params = {
            base_img: dataURL,
          }
          axios
            .post('/api/enterprise/getMediaID', params, {
              headers: { Authorization: `bearer ${token}` },
            })
            .then((res) => {
              if (res.data.code == 200) {
                wx.invoke(
                  'sendChatMessage',
                  {
                    msgtype: 'image', //消息类型，必填
                    enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
                    image: {
                      mediaid: res.data.data.media_id, //图片的素材id
                    },
                  },
                  function (res) {
                    if (res.err_msg == 'sendChatMessage:ok') {
                      Toast('发送成功')
                      showModel.value = false
                    }
                  }
                )
              }
            })
        })
      } else {
        let params = {
          base_img: item,
          type: 2,
        }
        axios
          .post('/api/enterprise/getMediaID', params, {
            headers: { Authorization: `bearer ${token}` },
          })
          .then((res) => {
            if (res.data.code == 200) {
              wx.invoke(
                'sendChatMessage',
                {
                  msgtype: 'video', //消息类型，必填
                  enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
                  video: {
                    mediaid: res.data.data.media_id, //图片的素材id
                  },
                },
                function (res) {
                  if (res.err_msg == 'sendChatMessage:ok') {
                    Toast('发送成功')
                    showModel.value = false
                  }
                }
              )
            }
          })
      }
    }
    return {
      imgurl,
      show,
      add,
      close,
      checked,
      message,
      fileList,
      videourl,
      enter,
      token,
      getFileImg,
      getFile,
      value,
      content,
      getList,
      list,
      activeNames,
      send,
    }
  },
})
</script>

<style scoped>
.wrap {
  width: 100%;
  height: 100%;
  background: #f4f7f8;
}
.one {
  width: 100%;
  height: 100%;
  background: #f4f7f8;
}
.two {
  width: 100%;
  height: 100vh;
}
.none {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0px;
}
.folder {
  background: #0025fe;
  width: 80%;
  text-align: center;
  color: white;
  line-height: 45px;
  height: 45px;
  border-radius: 10px;
  display: flex;
  margin-top: 200px;
  justify-content: center;
  align-items: center;
}
.file {
  background: #0025fe;
  color: white;
  width: 80%;
  text-align: center;
  height: 45px;
  line-height: 45px;
  border-radius: 10px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.folder-img {
  width: 20px;
  height: 15px;
  margin-right: 10px;
}
.file-img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.back {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.icon {
  width: 50%;
  display: flex;
  margin-left: 20px;
}
.backs {
  height: 20px;
  line-height: 20px;
}
.backstitle {
  height: 20px;
  line-height: 20px;
}
.enter {
  /* width: 50%; */
  text-align: right;
  margin-right: 20px;
  background: #0052fe;
  border-radius: 5px;
  color: white;
  padding: 2px 5px 2px 5px;
}
.addmsg {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.input {
  width: 100%;
}
.task-tilte {
  color: #777777;
  font-size: 14px;
  padding-left: 15px;
  padding-top: 10px;
}
.task-check {
  width: 100%;
  padding-left: 15px;

  margin-top: 15px;
}
.task-font {
  width: 100%;
  margin-top: 15px;
}
.task-img {
  margin-top: 15px;
  width: 100%;
  background: white;
}
.ques {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: 90vh;
  justify-content: center;
  overflow-x: scroll;
}
.ques_warp {
  width: 90%;
  /* height: 100%;/ */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.content-bottom {
  width: 79px;
  height: 29px;
  background: #0052fe;
  border-radius: 15px;
  color: white;
  text-align: center;
  line-height: 29px;
  margin-top: 10px;
  margin-left: 200px;
}
.image {
  width: 100%;
  height: 100%;
}
.video {
  width: 100%;
  height: 300px;
}
.van-collapse-item {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
</style>

<style>
:root {
  --van-tabs-bottom-bar-color: #3b0aee !important;
}
.van-collapse {
  width: 100% !important;
}
</style>

<template>
  <div class="wraps">
    <van-tabs v-model:active="active">
      <van-tab title="智能话术">
        <Smart></Smart>
      </van-tab>
      <van-tab title="个人话术">
        <Own></Own>
      </van-tab>
      <van-tab title="问答库">
        <Qalibray></Qalibray>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
// eslint-disable-next-line
import { defineComponent, ref, computed, onMounted } from 'vue'
import axios from 'axios'
// eslint-disable-next-line
import http from '../utils/http/http'
import { logins } from '../utils/http/logins'
import Own from '../component/own.vue'
import Qalibray from '../component/qalibray.vue'
import Smart from '../component/smart.vue'
export default defineComponent({
  name: 'Script',
  props: {
    msg: String,
    id: String,
    key: Number,
  },
  components: {
    Own,
    Smart,
    Qalibray,
  },
  // eslint-disable-next-line
  setup(props) {
    onMounted(() => {})
    let wx = window.wx
    const token = window.localStorage.getItem('token')
    const htmlUrl = ref('')
    let getUrl = window.location.href
    const corpid = window.localStorage.getItem('corpid')
    let urlparams = {
      url: getUrl,
    }
    const agentid = window.localStorage.getItem('agentid')

    axios
      .post(`/api/enterprise/auth/sign/app`, urlparams, {
        headers: { Authorization: `bearer ${token}` },
      })
      .then((res) => {
        wx.agentConfig({
          corpid: corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid: agentid, // 必填，企业微信的应用id （e.g. 1000247）
          timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.data.nonce_str, // 必填，生成签名的随机串
          signature: res.data.data.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
          jsApiList: ['getCurExternalContact', 'sendChatMessage'], //必填，传入需要使用的接口名称
          success: function () {
            // 回调
            wx.invoke('getCurExternalContact', {}, function (res) {
              if (res.err_msg == 'getCurExternalContact:ok') {
                window.localStorage.setItem('userid', res.userId)
              }
            })
          },
          fail: function (res) {
            console.log('useridfail')
            console.log(res)
            if (res.errMsg.indexOf('function not exist') > -1) {
              alert('版本过低请升级')
            }
          },
        })
      })
      .catch((err) => {
        console.log(err.response)
        logins()
        if (err.response.data.code == 401) {
          logins()
        }
      })
    const active = ref(0)
    return { active }
  },
})
</script>

<style scoped>
.wraps {
  width: 100%;
  height: 100%;
  background: #f4f7f8;
}
</style>

<style>
.aaa {
  width: 100%;
  height: 100%;
  background: #f4f7f8;
  position: fixed;
  top: 0;
}
/* 。 .van-tabs__content {
  margin-top: 100px;
} */
:root {
  --van-tabs-bottom-bar-color: #3b0aee !important;
}
</style>

<template>
  <div class="section" v-if="!isglshow">
    <!--  -->
    <div @click="goback" class="goback" v-if="id || chargeid">
      <img src="../assets/Group75@2x.png" alt="" class="gobackimg" />{{ textTile }}
    </div>
    <div class="top">
      <div class="wrap_top">
        <div class="list2">
          <div class="user">
            就诊人
            <div class="xh">*</div>
          </div>
          <div class="user_right" v-if="chargeid">
            <input
              type="text"
              class="input_names"
              placeholder="请输入就诊人姓名"
              style="text-align: right"
              v-model="name"
              maxlength="10"
              @change="getReg('name')"
              disabled
            />
          </div>
          <div class="user_right" v-else>
            <input
              type="text"
              class="input_name"
              placeholder="请输入就诊人姓名"
              style="text-align: right"
              v-model="name"
              maxlength="10"
              @change="getReg('name')"
            />
          </div>
          <!-- <div class="nameModel"></div> -->
        </div>
        <div :class="id || chargeid ? 'gz_name1' : 'gz_name'" v-if="showname">请输入正确的姓名</div>
        <div class="list">
          <div class="user">
            年龄
            <div class="xh">*</div>
          </div>

          <div class="user_right">
            <!-- <input
              type="number"
              class="input_name"
              maxlength="3"
              oninput="if(value.length>3)value=value.slice(0,3)"
              onkeyup="value=value.replace(/[^\d]/g,'')"
              style="text-align: right"
              placeholder="请输入年龄"
              v-model="age"
            /> -->
            <van-field
              v-model="age"
              class="input_name"
              type="digit"
              input-align="right"
              maxlength="3"
              placeholder="请输入年龄"
            />

            <!-- <van-field v-model="age" type="digit"    placeholder="请输入年龄"/> -->
          </div>
        </div>
        <div class="list">
          <div class="phone">
            手机号
            <div class="xh">*</div>
          </div>
          <div class="user_right" v-if="chargeid">
            <input
              type="text"
              class="input_names"
              placeholder="请输入手机号"
              v-model="phone"
              maxlength="11"
              @change="getReg('phone')"
              style="text-align: right"
              disabled
            />
          </div>
          <div class="user_right" v-else>
            <input
              type="text"
              class="input_name"
              placeholder="请输入手机号"
              v-model="phone"
              maxlength="11"
              @change="getReg('phone')"
              style="text-align: right"
            />
          </div>
        </div>
        <div :class="id || chargeid ? 'gz_phone1' : 'gz_phone'" v-if="showphone">
          请输入正确的手机号
        </div>
        <div class="list">
          <div class="sex_top">
            性别
            <div class="xh">*</div>
          </div>
          <div class="sex">
            <div :class="checkage == 1 ? 'checkman' : 'man'" @click="checkAge(1)">男</div>
            <div :class="checkage == 0 ? 'checkwoman' : 'woman'" @click="checkAge(0)">女</div>
          </div>
        </div>
        <div class="lists">
          <div>身份证</div>
          <div class="user_right">
            <input
              type="text"
              class="input_sfz"
              v-model="sfz"
              @change="getReg('sfz')"
              style="text-align: right"
              placeholder="请输入身份证"
            />
          </div>
        </div>
        <div :class="id || chargeid ? 'gz_sfz1' : 'gz_sfz'" v-if="showsfz">
          请输入正确的身份证格式
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="middle">
      <div class="wrap_middle">
        <div class="list1">
          <div class="user">
            预约医生
            <div class="xh">*</div>
          </div>
          <div class="user_right">
            <input
              type="text"
              class="input_name"
              placeholder="请选择医生>"
              style="text-align: right"
              @click="getname"
              v-model="doctorname"
              readonly="readonly"
            />

            <van-popup v-model:show="show" position="bottom">
              <van-picker
                title="选择医生"
                :columns="columns"
                :columns-field-names="customFieldNames"
                @confirm="onConfirm"
                @cancel="onCancel"
                @change="onChange"
              />
            </van-popup>
          </div>
        </div>
        <div class="listtype">
          <div class="type">就诊类型</div>
          <div class="type_three">
            <div :class="checktype == 4 ? 'jztypecheck' : 'jztype'" @click="chectype(4)">
              视频问诊
            </div>
            <div :class="checktype == 2 ? 'jztypecheck' : 'jztype'" @click="chectype(2)">图文</div>
            <div :class="checktype == 3 ? 'jztypecheck' : 'jztype'" @click="chectype(3)">电话</div>
          </div>
        </div>

        <div class="titless" v-if="!chargeid">
          <div class="title_top">
            挂号金额
            <div class="xh">*</div>
          </div>
          <div class="title_bottom">
            <input
              type="number"
              v-model="price"
              maxlength="4"
              @input="getprices($event)"
              oninput="if(value<0)value=0 "
              placeholder="请输入挂号金额"
              style="text-align: right"
            />
          </div>
        </div>

        <div class="titless" v-else>
          <div class="title_top">
            挂号金额
            <div class="xh">*</div>
          </div>
          <div class="title_bottom">
            <input
              type="number"
              v-model="price"
              :maxlength="4"
              oninput="if(value<0)value=0 "
              placeholder="请输入挂号金额"
              style="text-align: right; color: #c8c8c8"
              disabled
            />
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="time">
      <div class="checktime" v-show="checktype != 2">
        <div class="checktime_left">
          选择挂号时间
          <div class="xh">*</div>
        </div>
        <div class="checktime_right">
          <!-- <input
            type="text"
            placeholder="请选择挂号时间"
            style="text-align: right"
            @click="gettime"
            v-model="doctortime"
          /> -->
          <div @click="gettime">{{ doctortime }}</div>
          <!-- > -->
          <van-popup v-model:show="showtime" position="bottom">
            <van-picker
              :title="选择挂号时间"
              :columns="columnss"
              :columns-field-names="customFieldName"
              @change="timechange"
              @confirm="timeconfirm"
              @cancel="onCancels"
            />
          </van-popup>
        </div>
      </div>
      <div class="bz">
        <div class="checkbz_left">备注</div>
        <div class="checkbz_right">
          <input type="text" placeholder="请输入备注" style="text-align: right" v-model="remarks" />
        </div>
      </div>
      <div class="longtime" v-if="isZero === '1' || chargeid">
        <div class="longtime_titile">号源有效时长</div>

        <div class="longtime_check">
          <div class="time_check" @click="checktime(525600)">永久</div>
        </div>
      </div>
      <div class="longtime" v-else-if="isZero === '2'">
        <div class="longtime_check">号源有效时长</div>

        <div class="longtime_check" v-if="advance_money && advance_money != '0.00'">
          <div class="time_check" @click="checktime(525600)">永久</div>
        </div>

        <div class="longtime_check">
          <div :class="timecheck == 30 ? 'time_check' : 'time_default'" @click="checktime(30)">
            30分钟
          </div>
          <div :class="timecheck == 480 ? 'time_check' : 'time_default'" @click="checktime(480)">
            8小时
          </div>
          <div :class="timecheck == 1440 ? 'time_check' : 'time_default'" @click="checktime(1440)">
            24小时
          </div>
          <div :class="timecheck == 4320 ? 'time_check' : 'time_default'" @click="checktime(4320)">
            72小时
          </div>
          <div
            :class="timecheck == 525600 ? 'time_check' : 'time_default'"
            @click="checktime(525600)"
          >
            永久
          </div>
        </div>
      </div>
    </div>
    <div class="button_bottom">
      <div class="done" @click="done">仅保存</div>
      <div class="send" @click="send">发送给患者</div>
    </div>
    <div class="model" v-if="showModel">
      <div class="model_middle">
        <img src="../assets/close.png" class="clsoe" alt="" @click="close" />
        <div class="model_middle_title">请确认挂号信息</div>
        <div class="model_middle_people">
          <div>就诊人</div>
          <div>{{ name }} {{ tmphone }}</div>
        </div>
        <div class="model_middle_people">
          <div>问诊类型</div>
          <div>{{ checktype == 4 ? '视频问诊' : checktype == 3 ? '电话问诊' : '图文问诊' }}</div>
        </div>
        <div class="model_middle_people">
          <div>预约医生</div>
          <div>{{ doctorname }}</div>
        </div>
        <div class="model_middle_people">
          <div>订单金额</div>
          <div>{{ price }}</div>
        </div>
        <div class="model_middle_people" v-if="checktype != 2">
          <div>预约时间</div>
          <div>{{ doctortime }}</div>
        </div>
        <div class="model_middle_peoples">
          <div>当前联系人</div>
          <div class="wechat">
            <img :src="wechatimg" alt="" class="wechatimg" />{{ wechatname }}
          </div>
        </div>
        <div class="sends" @click="sends">发送</div>
      </div>
    </div>
  </div>
  <div class="userinfo">
    <div class="user_title">当前联系人：</div>
    <div class="user_info">
      <img :src="wechatimg" class="wechatimg" alt="" />
      {{ wechatname }}
    </div>
  </div>

  <div v-if="isglshow" class="warpp">
    <div class="warpp1">
      <div class="warpp2">关联提示</div>

      <div class="warpp3">当前患者存在已收款未挂号的收费记录，请选择需要关联的收费记录</div>
      <div class="listpp" v-for="(item, index) in gylist" :key="index">
        <van-radio-group v-model="checked">
          <van-radio :name="index">
            患者:{{ item.patient_name }} {{ item.patient_phone }} 医生:{{ item.doctor_name }} ￥{{
              item.money
            }}
          </van-radio>
        </van-radio-group>
      </div>
      <div class="ppbutton">
        <div class="ppsure" @click="getid">确认</div>
        <div class="ppclose" @click="closebd">取消</div>
      </div>
    </div>
  </div>
  <van-popup v-model:show="showloding">
    <van-loading type="spinner" />
  </van-popup>
</template>

<script>
// eslint-disable-next-line
import { defineComponent, ref, onMounted, computed } from 'vue'
import { Button, Toast } from 'vant'
import axios from 'axios'
import http from '../utils/http/http'
// import { getUrlParam } from '../utils/getUrl'
import { logins } from '../utils/http/logins'
import { urlencodeUrl } from '../utils/urlencodeUrl'

export default defineComponent({
  name: 'ReGister',
  components: {
    [Button.name]: Button,
  },
  props: {
    id: String,
    ghlist: Object,
  },
  // eslint-disable-next-line
  setup(props) {
    onMounted(() => {
      getDoctor()
      // getDoctorTime()
    })
    let isZero = ref('2')

    let showloding = ref(false)
    let isglshow = ref(false)
    const data = {
      age: 0,
    }
    const remarks = ref('')
    const showModel = ref(false)
    // let isZero = computed((price) => {
    //   console.log(price)
    //   if (price == '0' || '0.00') {
    //     return true
    //   }
    // })
    // console.log(isZero.value)
    const customFieldNames = {
      text: 'doctor_name',
    }
    const columnss = ref([])
    const customFieldName = {
      text: 'day',
      children: 'timelist',
    }
    const timecheck = ref(30)
    let doctorDid = ref('')
    const price = ref('')
    const doctorname = ref('')
    const columns = ref([])
    const show = ref(false)
    const showtime = ref(false)
    const age = ref('')
    const checkage = ref(3)
    const checktype = ref(4)
    const name = ref('')
    const phone = ref('')
    const tmphone = ref('')
    const sfz = ref('')
    const showname = ref(false)
    const showphone = ref(false)
    const showsfz = ref(false)
    let checked = ref(0)
    const doctortime = ref('请选择挂号时间')
    const doctor_id = ref('')
    let time_id = ref('')
    let wx = window.wx
    let wechatname = ref('')
    let wechatimg = ref('')
    let showteimLoding = ref(false)
    let advance_money = ref('')
    let pay_money = ref('')
    let wxname = ref('')
    let wxid = ref('')
    let money = ref('')
    function goback() {
      window.history.go(-1)
    }
    const token = window.localStorage.getItem('token')

    let getUrl = window.location.href
    const corpid = window.localStorage.getItem('corpid')
    let urlparams = {
      url: getUrl,
    }
    const agentid = window.localStorage.getItem('agentid')

    axios
      .post(`/api/enterprise/auth/sign/app`, urlparams, {
        headers: { Authorization: `bearer ${token}` },
      })
      .then((res) => {
        wx.agentConfig({
          corpid: corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid: agentid, // 必填，企业微信的应用id （e.g. 1000247）
          timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.data.nonce_str, // 必填，生成签名的随机串
          signature: res.data.data.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
          jsApiList: ['getCurExternalContact', 'sendChatMessage'], //必填，传入需要使用的接口名称
          success: function () {
            // 回调
            wx.invoke('getCurExternalContact', {}, function (res) {
              if (res.err_msg == 'getCurExternalContact:ok') {
                window.localStorage.setItem('userid', res.userId)
                // uesrid.value = res.userId //返回当前外部联系人userId
                // window.location.reload()

                let paramsss = {
                  external_user_id: res.userId,
                }
                axios
                  .post('/api/enterprise/getExternalUserInfo', paramsss, {
                    headers: { Authorization: `bearer ${token}` },
                  })
                  .then((res) => {
                    if (res.data.code == 200) {
                      wechatimg.value = res.data.data.external_user_info.external_contact.avatar
                      wechatname.value = res.data.data.external_user_info.external_contact.name
                    }

                    console.log(res)
                  })
              } else {
                //错误处理
              }
            })
          },
          fail: function (res) {
            if (res.errMsg.indexOf('function not exist') > -1) {
              alert('版本过低请升级')
            }
          },
        })
      })
      .catch((err) => {
        logins()

        console.log(err.response)
        if (err.response.data.code == 401) {
          logins()
        }
      })

    //挂号回显
    const id = window.sessionStorage.getItem('registerListId')
    if (id) {
      let params = {
        id: id,
      }
      axios
        .post('/api/enterprise/infoQuickWzOrder', params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          if (res.data.code == 200) {
            let list = res.data.data.info
            name.value = list.patient_name
            phone.value = list.patient_phone
            checkage.value = list.sex
            sfz.value = list.patient.idcard
            doctorname.value = list.doctor_name
            price.value = list.pay_money
            remarks.value = list.order_desc
            checktype.value = list.type
            age.value = list.age
            sfz.value = list.patient.idcard
            doctorDid.value = list.cpdid
            // doctortime.value = list.dial_time
            timecheck.value = list.expired || 30
            getDoctorTime('hx')
            // advance_money.value = list.advance_money || ''
            pay_money.value = list.pay_money
            time_id.value = list.schedule_id
            if (list.pay_money == '0.00' || list.pay_money == '0') {
              isZero.value = '1'
            } else {
              isZero.value = '2'
            }
          }

          // did.value = list.type
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            // Toast(err.response.data.message)
          }
        })
    }
    let patient_id = ref('')
    let user_id = ref('')
    let register_order_id = ref('')
    let register_order_no = ref('')
    //收费回显
    let chargeid = window.sessionStorage.getItem('chargeid')
    console.log(chargeid)
    if (chargeid) {
      let params = {
        id: chargeid,
      }
      axios
        .post('/api/enterprise/infoRegisterOrder', params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          if (res.data.code == 200) {
            let list = res.data.data.info
            name.value = list.patient_name
            phone.value = list.patient_phone
            checkage.value = list.sex
            sfz.value = list.idcard
            doctorname.value = list.doctor_name
            price.value = list.pay_money
            remarks.value = list.order_desc
            checktype.value = list.type
            age.value = list.age
            // sfz.value = list.patient.idcard
            doctorDid.value = list.did
            timecheck.value = list.expired || 30
            // doctortime.value = list.dial_time
            getDoctorTime('hx')
            advance_money.value = list.advance_money
            pay_money.value = list.pay_money
            time_id.value = list.schedule_id
            patient_id.value = list.patient_id
            user_id.value = list.user_id
            register_order_id.value = list.id
            register_order_no.value = list.order_no
            if (list.pay_money == '0.00' || list.pay_money == '0') {
              isZero.value = '1'
            } else {
              isZero.value = '2'
            }
          }

          // did.value = list.type
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            // Toast(err.response.data.message)
          }
        })
    }

    let params = {
      only_managed: 0,
    }
    axios
      .post('/api/enterprise/organizationDoctorList', params, {
        headers: { Authorization: `bearer ${token}` },
      })
      .then((res) => {
        columns.value = res.data.data
      })
      .catch((err) => {
        if (err.response.data.code == 401) {
          logins()
        } else {
          // Toast(err.response.data.message)
        }
      })
    async function getDoctor() {
      let params = {
        only_managed: 0,
      }
      await axios
        .post('/api/enterprise/organizationDoctorList', params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          columns.value = res.data.data
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            // Toast(err.response.data.message)
          }
        })
    }
    const showPopup = () => {
      show.value = true
    }
    const onCancel = () => {
      show.value = false
    }
    const onCancels = () => {
      showtime.value = false
    }
    const onConfirm = (e) => {
      doctortime.value = '请选择挂号时间'
      doctorname.value = e.doctor_name
      doctorDid.value = e.did
      doctor_id.value = e.doctor_id
      show.value = false
      getDoctorTimes(doctorDid.value, checktype.value, 'doctor')
    }
    const onChange = () => {}
    function checktime(e) {
      timecheck.value = e
    }
    function checkAge(e) {
      data.age = e
      checkage.value = e
    }
    function gettime() {
      if (doctorname.value && checktype.value) {
        getDoctorTime(doctorDid.value, checktype.value, 'gettime')
        showtime.value = true
      } else {
        Toast('请先选择医生和就诊类型')
      }
    }
    function getDoctorTime(type) {
      if (type == 'hx') {
        getDoctorTimes(doctorDid.value, checktype.value)
      } else {
        getDoctorTimes(doctorDid.value, checktype.value, 'dandu')
      }
    }
    async function getDoctorTimes(did, type, from) {
      if (from == 'gettime') {
        let params = {
          did: did,
          type: type,
        }
        await axios
          .post('/api/enterprise/doctorScheduling', params, {
            headers: { Authorization: `bearer ${token}` },
          })
          .then((res) => {
            columnss.value = res.data.data.schedules
            if (res.data.data.schedules.length == 0) {
              Toast('当前医生未设置号源')
              setTimeout(() => {
                showtime.value = false
              }, 1000)
            }
          })
          .catch((err) => {
            if (err.response.data.code == 401) {
              logins()
            } else {
              // Toast(err.response.data.message)
            }
          })
      }
      if (from == 'hx') {
        let params = {
          did: did,
          type: type,
        }
        await axios
          .post('/api/enterprise/doctorScheduling', params, {
            headers: { Authorization: `bearer ${token}` },
          })
          .then((res) => {
            if (checktype.value == 4) {
              price.value = res.data.data.amounts.video_price
            } else if (checktype.value == 3) {
              price.value = res.data.data.amounts.call_price
            } else {
              price.value = res.data.data.amounts.view_price
            }
            columnss.value = res.data.data.schedules
            if (price.value == '0' || price.value == '0.00') {
              isZero.value = '1'
            } else {
              isZero.value = '2'
            }
          })
          .catch((err) => {
            if (err.response.data.code == 401) {
              logins()
            } else {
              // Toast(err.response.data.message)
            }
          })
      }
      if (from == 'checktype') {
        let params = {
          did: did,
          type: type,
        }
        await axios
          .post('/api/enterprise/doctorScheduling', params, {
            headers: { Authorization: `bearer ${token}` },
          })
          .then((res) => {
            if (chargeid) {
              return
            } else {
              if (checktype.value == 4) {
                price.value = res.data.data.amounts.video_price
              } else if (checktype.value == 3) {
                price.value = res.data.data.amounts.call_price
              } else {
                price.value = res.data.data.amounts.view_price
              }

              if (price.value == '0' || price.value == '0.00') {
                isZero.value = '1'
              } else {
                isZero.value = '2'
              }
            }
          })
          .catch((err) => {
            if (err.response.data.code == 401) {
              logins()
            } else {
              // Toast(err.response.data.message)
            }
          })
      }
      if (from == 'doctor') {
        if (chargeid) {
          return
        } else {
          let params = {
            did: did,
            type: type,
          }
          await axios
            .post('/api/enterprise/doctorScheduling', params, {
              headers: { Authorization: `bearer ${token}` },
            })
            .then((res) => {
              if (checktype.value == 4) {
                price.value = res.data.data.amounts.video_price
              } else if (checktype.value == 3) {
                price.value = res.data.data.amounts.call_price
              } else {
                price.value = res.data.data.amounts.view_price
              }
              columnss.value = res.data.schedules
              if (price.value == '0' || price.value == '0.00') {
                isZero.value = '1'
              } else {
                isZero.value = '2'
              }
            })
            .catch((err) => {
              if (err.response.data.code == 401) {
                logins()
              } else {
                Toast(err.response.data.message)
              }
            })
        }
      }
      if (from == 'dandu') {
        let params = {
          did: did,
          type: type,
        }
        await axios
          .post('/api/enterprise/doctorScheduling', params, {
            headers: { Authorization: `bearer ${token}` },
          })
          .then((res) => {
            columnss.value = res.data.data.schedules
            if (res.data.data.schedules.length == 0) {
              Toast('当前医生未设置号源')
              setTimeout(() => {
                showtime.value = false
              }, 1000)
            }
          })
          .catch((err) => {
            if (err.response.data.code == 401) {
              logins()
            } else {
              Toast(err.response.data.message)
            }
          })
      }
    }
    function chectype(e) {
      doctortime.value = '请选择挂号时间'
      checktype.value = e
      getDoctorTimes(doctorDid.value, checktype.value, 'checktype')
    }
    function getname() {
      show.value = true
    }
    function getReg(type) {
      let nameReg = /^[a-zA-Z\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/
      let phoneReg = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
      let sfzReg =
        /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
      switch (type) {
        case 'name':
          showname.value = !nameReg.test(name.value)
          break
        case 'phone':
          showphone.value = !phoneReg.test(phone.value)
          break
        case 'sfz':
          showsfz.value = !sfzReg.test(sfz.value)
          break
        default:
          break
      }
    }
    function preservation() {
      if (!isclose.value) {
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
        })
      }

      setTimeout((res) => {
        let params = {}
        if (chargeid) {
          params = {
            patient_name: name.value,
            patient_phone: phone.value,
            patient_age: age.value,
            patient_sex: checkage.value,
            did: doctorDid.value,
            doctor_id: doctor_id.value,
            type: checktype.value,
            is_send_type: 2,
            idcard: sfz.value,
            amount: '0',
            external_user_id: window.localStorage.getItem('userid'),
            schedule_id: time_id.value,
            desc: remarks.value,
            expired: timecheck.value,
            patient_id: patient_id.value,
            user_id: user_id.value,
            register_order_id: register_order_id.value,
            register_order_no: register_order_no.value,
            advance_money: price.value,
          }
        } else {
          params = {
            patient_name: name.value,
            patient_phone: phone.value,
            patient_age: age.value,
            patient_sex: checkage.value,
            did: doctorDid.value,
            doctor_id: doctor_id.value,
            type: checktype.value,
            is_send_type: 2,
            idcard: sfz.value,
            amount: price.value,
            external_user_id: window.localStorage.getItem('userid'),
            schedule_id: time_id.value,
            desc: remarks.value,
            expired: timecheck.value,
            advance_money: 0,
            register_order_id: register_order_id.value,
          }
        }
        axios
          .post('/api/enterprise/addQuickWzOrder', params, {
            headers: { Authorization: `bearer ${token}` },
          })
          .then((resone) => {
            wxname.value = doctorname.value
            wxid.value = resone.data.data.order.order_id
            console.log(isclose.value)
            if (isclose.value) {
              console.log(1111)
              Toast('保存成功')
              window.location.reload()
            } else {
              if (params.amount == '0.00' && register_order_id.value == '') {
                console.log(961)
                let params = {
                  id: resone.data.data.order.order_id,
                }
                axios
                  .post('/api/enterprise/selectObtain', params, {
                    headers: { Authorization: `bearer ${token}` },
                  })
                  .then((res) => {
                    if (res.data.data.list.length != 0) {
                      console.log(962)

                      showModel.value = false
                      isglshow.value = true
                      gylist.value = res.data.data.list
                    } else {
                      console.log(963)

                      Toast('保存成功')
                      window.location.reload()
                      if (id || chargeid) {
                        window.history.go(-1)
                      }
                    }
                  })
                  .catch((err) => {
                    if (err.response.data.code == 401) {
                      logins()
                    } else {
                      Toast(err.response.data.message)
                    }
                  })
              } else if (params.amount == '0' && register_order_id.value == '') {
                console.log(964)

                let params = {
                  id: resone.data.data.order.order_id,
                }
                axios
                  .post('/api/enterprise/selectObtain', params, {
                    headers: { Authorization: `bearer ${token}` },
                  })
                  .then((res) => {
                    if (res.data.data.list.length != 0) {
                      console.log(965)

                      showModel.value = false
                      isglshow.value = true
                      gylist.value = res.data.data.list
                    } else {
                      console.log(966)

                      Toast('保存成功')
                      window.location.reload()
                      if (id || chargeid) {
                        window.history.go(-1)
                      }
                    }
                  })
                  .catch((err) => {
                    if (err.response.data.code == 401) {
                      logins()
                    } else {
                      // Toast(err.response.data.message)
                    }
                  })
              } else {
                console.log(967)

                Toast('保存成功')
                window.location.reload()
                if (id || chargeid) {
                  window.history.go(-1)
                }
              }
            }
          })
          .catch((err) => {
            if (err.response.data.code == 401) {
              logins()
            } else {
              // Toast(err.response.data.message)
            }
          })
      }, 1000)
    }
    function done() {
      switctab.value = 'bc'
      if (sfz.value && sfz.value.length != 18) {
        Toast('请确认身份证是否正确')
      }

      console.log(
        name.value && age.value && phone.value && checkage.value && doctorname.value && price.value
      )
      if (checktype.value == 2) {
        if (
          name.value &&
          age.value &&
          phone.value &&
          (checkage.value === 0 || 1) &&
          doctorname.value &&
          price.value
        ) {
          preservation()
        } else {
          Toast('请检查必填项是否填写完全')
        }
      } else {
        if (
          name.value &&
          age.value &&
          phone.value &&
          (checkage.value === 0 || 1) &&
          doctorname.value &&
          price.value &&
          time_id.value
        ) {
          preservation()
        } else {
          Toast('请检查必填项是否填写完全')
        }
      }
    }
    function send() {
      switctab.value = 'fs'

      let tel = phone.value
      tmphone.value = tel.substr(0, 3) + '****' + tel.substring(7)
      console.log(name.value, age.value, phone.value, checkage.value, doctorname.value, price.value)
      if (checktype.value == 2) {
        if (
          name.value &&
          age.value &&
          phone.value &&
          (checkage.value === 0 || 1) &&
          doctorname.value &&
          price.value
        ) {
          showModel.value = true
        } else {
          Toast('请确认必填项是否填写')
        }
      } else {
        if (sfz.value) {
          if (sfz.value.length == 18) {
            if (
              name.value &&
              age.value &&
              phone.value &&
              (checkage.value === 0 || 1) &&
              doctorname.value &&
              price.value &&
              doctortime.value &&
              doctortime.value != '请选择挂号时间'
            ) {
              if ((checktype.value == 3 || 4) && time_id.value) {
                showModel.value = true
              } else if (checktype.value == 2) {
                showModel.value = true
              } else {
                Toast('请确认必填项是否填写')
              }
            } else {
              Toast('请确认必填项是否填写')
            }
          } else {
            Toast('身份证格式不正确')
          }
        } else {
          console.log(
            name.value,
            age.value,
            phone.value,
            checkage.value,
            doctorname.value,
            price.value,
            doctortime.value
          )
          if (price.value) {
            console.log(1)
          } else {
            console.log(price.value)
          }
          if (
            name.value &&
            age.value &&
            phone.value &&
            (checkage.value === 0 || 1) &&
            doctorname.value &&
            price.value &&
            doctortime.value &&
            doctortime.value != '请选择挂号时间'
          ) {
            if ((checktype.value == 3 || 4) && time_id.value) {
              showModel.value = true
            } else if (checktype.value == 2) {
              showModel.value = true
            } else {
              Toast('请确认必填项是否填写')
            }
          } else {
            Toast('请确认必填项是否填写')
          }
        }
      }
    }

    let bdid = ref('')
    function sends() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      })
      setTimeout((res) => {
        let params = {}
        if (chargeid) {
          params = {
            patient_name: name.value,
            patient_phone: phone.value,
            patient_age: age.value,
            patient_sex: checkage.value,
            did: doctorDid.value,
            doctor_id: doctor_id.value,
            type: checktype.value,
            is_send_type: 1,
            idcard: sfz.value,
            amount: '0',
            external_user_id: window.localStorage.getItem('userid'),
            schedule_id: time_id.value,
            desc: remarks.value,
            expired: timecheck.value,
            patient_id: patient_id.value,
            user_id: user_id.value,
            register_order_id: register_order_id.value,
            register_order_no: register_order_no.value,
            advance_money: price.value,
          }
        } else {
          params = {
            patient_name: name.value,
            patient_phone: phone.value,
            patient_age: age.value,
            patient_sex: checkage.value,
            did: doctorDid.value,
            doctor_id: doctor_id.value,
            type: checktype.value,
            is_send_type: 1,
            idcard: sfz.value,
            amount: price.value,
            external_user_id: window.localStorage.getItem('userid'),
            schedule_id: time_id.value,
            desc: remarks.value,
            expired: timecheck.value,
            advance_money: 0,
            register_order_id: register_order_id.value,
          }
        }

        axios
          .post('/api/enterprise/addQuickWzOrder', params, {
            headers: { Authorization: `bearer ${token}` },
          })
          .then((resone) => {
            if (resone.data.code == 200) {
              console.log(1)
              wxname.value = doctorname.value
              wxid.value = resone.data.data.order.order_id
              if (params.amount == '0' && register_order_id.value == '') {
                console.log(2)

                let params = {
                  id: resone.data.data.order.order_id,
                }
                axios
                  .post('/api/enterprise/selectObtain', params, {
                    headers: { Authorization: `bearer ${token}` },
                  })
                  .then((res) => {
                    if (res.data.data.list.length != 0) {
                      showModel.value = false
                      isglshow.value = true
                      gylist.value = res.data.data.list
                    } else {
                      sendwx(wxname.value, wxid.value)
                    }
                  })
                  .catch((err) => {
                    if (err.response.data.code == 401) {
                      logins()
                    } else {
                      // Toast(err.response.data.message)
                    }
                  })
              } else if (params.amount == '0.00' && register_order_id.value == '') {
                console.log(3)

                let params = {
                  id: resone.data.data.order.order_id,
                }
                axios
                  .post('/api/enterprise/selectObtain', params, {
                    headers: { Authorization: `bearer ${token}` },
                  })
                  .then((res) => {
                    if (res.data.data.list.length != 0) {
                      showModel.value = false
                      isglshow.value = true
                      gylist.value = res.data.data.list
                    } else {
                      sendwx(wxname.value, wxid.value)
                    }
                  })
                  .catch((err) => {
                    // if (err.response.data.code == 401) {
                    //    logins()
                    // }
                  })
              } else {
                sendwx(wxname.value, wxid.value)
              }

              // sendwx(wxname.value, wxid.value)
            }
          })
          .catch((err) => {
            if (err.response.data.code == 401) {
              logins()
            } else {
              Toast(err.response.data.message)
            }
          })
      }, 1000)
    }

    function getid() {
      let params = {
        id: wxid.value,
        register_order_id: gylist.value[checked.value].id,
      }
      axios
        .post('/api/enterprise/addObtain ', params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          if (res.data.code == 200) {
            Toast('绑定成功')
            isglshow.value = false
            if (switctab.value == 'fs') {
              sendwx(wxname.value, wxid.value)
            } else {
              Toast('保存成功')
              window.location.reload()
            }
          }
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            // Toast(err.response.data.message)
          }
        })
    }

    function sendwx(wxname, wxid) {
      console.log(wxname, wxid)
      console.log(pay_money.value, price.value)
      console.log(price.value)
      let organization = window.localStorage.getItem('organization')

      if (chargeid || id) {
        if (organization != 'mingyi') {
          let sendurl = ''
          // let url =
          //   'https://cloud1-5g80ysht3e049128-1306117587.tcloudbaseapp.com/h5/jump-mp.html?sign=047e33b549a034725a9760fd3f5deebd&t=1644572774&mpUrl='
          // sendurl = url + encodeURIComponent(`/pages/pay/purchase-service?id=${wxid}`)
          sendurl = urlencodeUrl('/pages/pay/purchase-service', { id: wxid })

          wx.invoke(
            'sendChatMessage',
            {
              msgtype: 'text', //消息类型，必填
              enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
              text: {
                content: sendurl, //文本内容
              },
            },
            function (res) {
              if (res.err_msg == 'sendChatMessage:ok') {
              }
              Toast('发送成功,请提醒患者尽快支付')
              setTimeout(() => {
                window.history.go(-1)
              }, 1000)
            }
          )
        } else {
          console.log(111111)
          wx.invoke(
            'sendChatMessage',
            {
              msgtype: 'miniprogram', //消息类型，必填
              enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
              miniprogram: {
                appid: 'wxee26553d4e0052ea', //小程序的appid，企业已关联的任一个小程序
                title: `您已成功预约${wxname}医生的线上问诊服务`, //小程序消息的title
                imgUrl: 'https://xcxapi.myzx.cn/assets/ghm.png', //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
                page: `pages/pay/purchase-service.html?id=${wxid}`, //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
              },
            },
            function (res) {
              if (res.err_msg == 'sendChatMessage:ok') {
                //发送成功
              }
              Toast('发送成功,请提醒患者尽快支付')
              setTimeout(() => {
                window.history.go(-1)
              }, 1000)
            }
          )
        }
      } else {
        if (organization != 'mingyi') {
          let sendurl = ''
          // let url =
          //   'https://cloud1-5g80ysht3e049128-1306117587.tcloudbaseapp.com/h5/jump-mp.html?sign=047e33b549a034725a9760fd3f5deebd&t=1644572774&mpUrl='
          // sendurl = url + encodeURIComponent(`/pages/pay/purchase-service?id=${wxid}`)
          sendurl = urlencodeUrl('/pages/pay/purchase-service', { id: wxid })

          wx.invoke(
            'sendChatMessage',
            {
              msgtype: 'text', //消息类型，必填
              enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
              text: {
                content: sendurl, //文本内容
              },
            },
            function (res) {
              if (res.err_msg == 'sendChatMessage:ok') {
              }
              Toast('发送成功,请提醒患者尽快支付')
              setTimeout(() => {
                window.location.reload()
              }, 1000)
            }
          )
        } else {
          console.log(11111111)
          wx.invoke(
            'sendChatMessage',
            {
              msgtype: 'miniprogram', //消息类型，必填
              enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
              miniprogram: {
                appid: 'wxee26553d4e0052ea', //小程序的appid，企业已关联的任一个小程序
                title: `您已成功预约${wxname}医生的线上问诊服务`, //小程序消息的title
                imgUrl: 'https://xcxapi.myzx.cn/assets/ghm.png', //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
                page: `pages/pay/purchase-service.html?id=${wxid}`, //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
              },
            },

            function (res) {
              console.log(res)
              if (res.err_msg == 'sendChatMessage:ok') {
                //发送成功
              }
              Toast('发送成功,请提醒患者尽快支付')
              setTimeout(() => {
                window.location.reload()
              }, 1000)
            }
          )
        }
      }

      // showModel.value = false
    }
    function timechange(value, index) {}
    function timeconfirm(value, index) {
      time_id.value = columnss.value[index[0]].timelist[index[1]].id
      doctortime.value =
        columnss.value[index[0]].day + '  ' + columnss.value[index[0]].timelist[index[1]].day
      showtime.value = false
    }
    function close() {
      showModel.value = false
    }
    const textTile = ref('')
    if (chargeid) {
      textTile.value = '返回'
    } else {
      textTile.value = '返回'
    }

    function getprice(e) {
      // price.value = e.target.value
      // if (String(price.value) == '0.00' || String(price.value) == '0') {
      //   isZero.value = '1'
      // } else {
      //   isZero.value = '2'
      // }
    }
    function getprices(e) {
      price.value = e.target.value
      if (price.value === '0.00') {
        isZero.value = '1'
      } else {
        isZero.value = '2'
      }
      if (price.value === '0') {
        isZero.value = '1'
      } else {
        isZero.value = '2'
      }
    }
    function selectObtain() {
      let params = {
        id,
      }
      axios
        .post('/api/enterprise/selectObtain', params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {})
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            // Toast(err.response.data.message)
          }
        })
    }
    let switctab = ref('')
    let isclose = ref(false)
    function closebd() {
      isglshow.value = false
      isclose.value = true
      if (switctab.value == 'fs') {
        sendwx(wxname.value, wxid.value)
      } else {
        preservation()
      }
    }
    let gylist = ref([])

    let paramsss = {
      external_user_id: window.localStorage.getItem('userid'),
    }
    axios
      .post('/api/enterprise/getExternalUserInfo', paramsss, {
        headers: { Authorization: `bearer ${token}` },
      })
      .then((res) => {
        if (res.data.code == 200) {
          wechatimg.value = res.data.data.external_user_info.external_contact.avatar
          wechatname.value = res.data.data.external_user_info.external_contact.name
        }
      })
      .catch((err) => {})
    // eslint-disable-next-line
    return {
      isclose,
      switctab,
      closebd,
      selectObtain,
      checked,
      textTile,
      timecheck,
      checkage,
      data,
      name,
      showModel,
      showname,
      doctorname,
      showtime,
      doctortime,
      showphone,
      price,
      phone,
      showsfz,
      sfz,
      columns,
      remarks,
      columnss,
      customFieldName,
      checktype,
      show,
      tmphone,
      customFieldNames,
      // token,
      doctorDid,
      age,
      doctor_id,
      time_id,
      wechatname,
      id,
      chargeid,
      wechatimg,
      showPopup,
      timeconfirm,
      showteimLoding,
      getDoctorTime,
      checkAge,
      checktime,
      getname,
      isZero,
      getReg,
      preservation,
      onChange,
      onConfirm,
      gettime,
      chectype,
      onCancel,
      done,
      timechange,
      send,
      getDoctor,
      close,
      sends,
      goback,
      getDoctorTimes,
      advance_money,
      pay_money,
      getprice,
      onCancels,
      isglshow,
      gylist,
      showloding,
      sendwx,
      wxname,
      wxid,
      getid,
      bdid,
      getprices,
      patient_id,
      user_id,
      register_order_id,
      register_order_no,
      money,
      getUrl,
      corpid,
      agentid,
    }
  },
})
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
.section {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 30px;
}
.top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.middle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrap_top {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
}
.wrap_middle {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
}
.list {
  height: 59px;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #343434;
  line-height: 59px;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.listtype {
  height: 59px;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #343434;
  line-height: 59px;
  display: flex;
  border-bottom: 1px solid #ebebeb;
  justify-content: space-between;
  position: relative;
}
.lists {
  height: 59px;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #343434;
  line-height: 59px;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.list1 {
  height: 59px;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #343434;
  line-height: 59px;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.list2 {
  height: 59px;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #343434;
  line-height: 59px;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  justify-content: space-between;
}
.user_right {
  display: flex;
  color: #c8c8c8;
  font-weight: 500;
  flex: 8;
  justify-content: flex-end;
}
.user_right {
  display: flex;
  color: #c8c8c8;
  font-weight: 500;
  flex: 8;
  justify-content: flex-end;
}
input::-webkit-input-placeholder {
  color: #c8c8c8;
}
.input_name {
  width: 44%;
  border: none;
  outline: none;
  color: black;
  line-height: 60px;
}
.input_names {
  width: 44%;
  border: none;
  outline: none;
  color: #c8c8c8;
}
.input_sfz {
  width: 57%;
  border: none;
  outline: none;
  color: black;
}
.user {
  display: flex;
  flex: 2;
  position: relative;
}
.phone {
  display: flex;
}
.xh {
  color: orangered;
}
.sex_top {
  flex: 2;
  display: flex;
}
.sex {
  display: flex;
  flex: 8;
  justify-content: flex-end;
  cursor: pointer;
  align-items: center;
}
.type {
  flex: 2;
}
.type_three {
  display: flex;
  flex: 8;
  justify-content: flex-end;
  align-items: center;
}
.man {
  background: #f6f6f6;
  color: #646369;
  width: 55px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  margin-right: 11px;
}

.woman {
  background: #f6f6f6;
  color: #646369;
  width: 55px;
  height: 25px;
  line-height: 25px;
  text-align: center;
}
.checkman {
  background: #f3f7ff;
  color: #5e99fd;
  width: 55px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  margin-right: 11px;
}
.checkwoman {
  background: #f3f7ff;
  color: #5e99fd;
  width: 55px;
  height: 25px;
  line-height: 25px;
  text-align: center;
}
.jztypecheck {
  background: #f3f7ff;

  color: #5e99fd;

  cursor: pointer;
  height: 25px;
  line-height: 25px;
  text-align: center;
  margin-left: 11px;
  padding: 2px 15px;
}

.jztype {
  background: #f6f6f6;
  color: #646369;
  height: 25px;
  line-height: 25px;
  cursor: pointer;
  text-align: center;
  margin-left: 11px;
  padding: 2px 15px;
}
.gz_name {
  color: red;
  font-size: 12px;
  position: absolute;
  top: 40px;
}
.gz_name1 {
  color: red;
  font-size: 12px;
  position: absolute;
  top: 60px;
}
.gz_phone {
  color: red;
  font-size: 12px;
  position: absolute;
  top: 160px;
}
.gz_phone1 {
  color: red;
  font-size: 12px;
  position: absolute;
  top: 180px;
}
.gz_sfz {
  color: red;
  font-size: 12px;
  position: absolute;
  top: 280px;
}
.gz_sfz1 {
  color: red;
  font-size: 12px;
  position: absolute;
  top: 300px;
}
.line {
  width: 100%;
  height: 9px;
  background: #f3f5f7;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.bottom {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.titless {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 18px;
  font-weight: 500;
  font-size: 14px;
  /* flex-wrap: wrap; */
}
.title_top {
  width: 90%;
  display: flex;
  flex: 3;
  justify-content: flex-start;
  /* margin-top: 19px;  */
}

.title_bottom {
  width: 90%;
  flex: 7;
  justify-content: flex-end;
  display: flex;
  /* justify-content: flex-start; */
}
.needpay {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-bottom: 1px solid #ebebeb;
  height: 40px;
  width: 105%;
  padding-bottom: 10px;
}
.payover {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-top: 10px;
  width: 105%;
  margin-top: 15px;
  margin-bottom: 20px;
}
.needpay_left {
  width: 100%;
}
input {
  outline: none;
  border: none;
}
.time {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.checktime {
  width: 90%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ebebeb;
  margin-top: 19px;
  font-size: 14px;
  padding-bottom: 15px;
  font-weight: 500;
}
.bz {
  width: 90%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ebebeb;
  font-size: 14px;
  margin-top: 15px;
  font-weight: 500;
  padding-bottom: 15px;
}

.checktime_left {
  font-size: 14px;
  width: 90%;
  display: flex;
  flex: 3;
}
.checkbz_left {
  width: 90%;
  display: flex;
  flex: 2;
}
.checkbz_right {
  font-size: 14px;
  flex: 8;
  color: #b0b0b0;
}
.checkbz_right input {
  width: 100%;
}
.checktime_right {
  font-size: 14px;
  flex: 7;
  color: #b0b0b0;
  display: flex;
  justify-content: flex-end;
}
.checktime_right input {
  width: 100%;
}
.longtime {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
}
.longtime_titile {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  margin-top: 18px;
}
.longtime_check {
  display: flex;
  font-size: 14px;
  margin-top: 13px;
  width: 100%;
  justify-content: space-between;
}
.time_default {
  width: 15%;
  background: #f6f6f6;
  color: #646369;
  font-size: 14px;
  line-height: 25px;
  cursor: pointer;
  text-align: center;
}
.time_check {
  width: 15%;
  background: #f3f7ff;
  color: #5e99fd;
  line-height: 25px;
  cursor: pointer;
  text-align: center;
}
.button_bottom {
  width: 90%;
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.done {
  width: 45%;
  height: 38px;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  border: 1px solid #359eff;
  text-align: center;
  line-height: 38px;
  font-size: 14px;
  cursor: pointer;
  color: #359eff;
}
.send {
  text-align: center;
  width: 45%;
  height: 38px;
  background: #359eff;
  border-radius: 20px 20px 20px 20px;
  font-size: 14px;
  opacity: 1;
  color: white;
  cursor: pointer;
  line-height: 38px;
}
.van-radio-group {
  width: 95%;
}

.model {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background: #000000;
  background: rgba(0, 0, 0, 0.65);
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}
.model_middle {
  width: 90%;
  /* height: 450px; */
  background: white;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
}
.clsoe {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 13px;
  cursor: pointer;
  right: 15px;
}
.model_middle_title {
  width: 100%;
  height: 40px;
  text-align: center;
  margin-top: 20px;
  color: #333333;
  font-size: 18px;

  font-weight: bold;
}
.model_middle_people {
  width: 85%;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  border-bottom: 1px solid #f7f7f7;
  padding-bottom: 15px;
}
.model_middle_peoples {
  width: 85%;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding-bottom: 15px;
}
.nameModel {
  width: 50px;
}
.sends {
  width: 136px;
  height: 40px;
  background: #359eff;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  color: white;
  text-align: center;
  line-height: 40px;
  margin-bottom: 20px;
  cursor: pointer;
  margin-top: 20px;
}
.wechatimg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
.wechat {
  display: flex;
  align-items: center;
}
.userinfo {
  width: 100%;
  height: 35px;
  position: fixed;
  bottom: 0;
  background: white;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #cccccc;
}
.user_title {
  line-height: 40px;
}
.user_info {
  display: flex;
  line-height: 40px;
  text-align: right;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
}
.wechatimg {
  width: 30px;
  margin-right: 10px;
  height: 30px;
  border-radius: 15%;
}
.goback {
  width: 100%;
  text-align: left;
  cursor: pointer;
  margin-left: 10px;
  color: #359eff;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.gobackimg {
  width: 20px;
  height: 20px;
}

.warpp {
  width: 100%;
  height: 100%;
}
.warpp1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.warpp2 {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.warpp3 {
  width: 80%;
  text-align: center;
  margin-top: 20px;
}
.listpp {
  margin-top: 20px;
  width: 100%;
  margin-left: 90px;
}
.ppbutton {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
:deep(.van-radio__label) {
  width: 100%;
  display: flex;
}
.ppsure {
  width: 80%;
  background: #359eff;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  text-align: center;
  cursor: pointer;
  color: white;
  height: 40px;
  line-height: 40px;
}
.ppclose {
  width: 100%;
  text-align: center;
  cursor: pointer;
  height: 40px;
  margin-top: 10px;
}
.pp {
  width: 100%;
}
.radio {
  width: 100%;
  margin: 80px;
}
.van-cell {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  /* padding:var(--van-cell-vertical-padding) var(--van-cell-horizontal-padding); */
  overflow: hidden;
  color: var(--van-cell-text-color);
  font-size: var(--van-cell-font-size);
  line-height: var(--van-cell-line-height);
  background: var(--van-cell-background-color);
  line-height: 40px;
  margin-right: -15px;
}
</style>

<template>
  <div>
    <div class="tab">
      <div
        :class="defaultNum == index ? 'tabcheck' : 'tabnocheck'"
        v-for="(item, index) in tabnum"
        @click="checktab(index)"
      >
        {{ item }}
      </div>
    </div>
    <div v-if="isList">暂无记录~</div>
    <div v-else class="sextion">
      <van-steps direction="vertical" class="steps">
        <van-step v-for="(item, index) in list">
          <div>{{ item.created_at }}</div>
          <div class="name">
            <div class="tips">
              {{ item.content }}
            </div>
            <div class="names">
              {{ item.mcn_user_name }}
            </div>
          </div>
        </van-step>
      </van-steps>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { defineComponent, ref, computed, onMounted } from 'vue'
import axios from 'axios'
// eslint-disable-next-line
import http from '../utils/http/http'
import { logins } from '../utils/http/logins'
export default defineComponent({
  name: 'journey',
  props: {},
  // eslint-disable-next-line
  setup(props) {
    onMounted(() => {
      getlist()
    })
    const token = window.localStorage.getItem('token')
    const list = ref()
    const isList = ref(true)
    const defaultNum = ref(0)
    const type = ref('')
    const tabnum = ref(['全部', '行为动态', '操作记录', '跟进记录'])
    function getlist() {
      let params = {
        customer_id: window.localStorage.getItem('customer_id'),
        type: type.value,
      }
      axios
        .post('/api/enterprise/logCustomerList', params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          list.value = res.data.data.list
          if (list.value) {
            isList.value = true
          }
          // num.value = list.value.length
        })
    }
    function checktab(index) {
      defaultNum.value = index
      if (index == 0) {
        type.value = ''
      }
      type.value = index
      getlist()
      if (index == 3) {
        getGen()
      }
    }
    function getGen() {
      let params = {
        customer_id: window.localStorage.getItem('customer_id'),
        type: type.value,
      }
      axios
        .post('/api/enterprise/listCustomerFollow', params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          list.value = res.data.data.list
          if (list.value) {
            isList.value = true
          }
          // num.value = list.value.length
        })
    }
    return {
      token,
      list,
      tabnum,
      checktab,
      defaultNum,
      getGen,
    }
  },
})
</script>

<style scoped>
.tab {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}
.tabcheck {
  width: 20%;
  height: 25px;
  background: #0052fe;
  text-align: center;
  border-radius: 15px;
  border: 1px solid #0052fe;
  color: white;
  font-size: 13px;
  font-weight: 500;
  line-height: 25px;
}
.tabnocheck {
  width: 20%;
  height: 25px;
  background: #ffffff;
  text-align: center;
  border-radius: 15px;
  border: 1px solid #777777;
  color: #777777;
  font-size: 13px;
  font-weight: 500;
  line-height: 25px;
}
.steps {
  margin-top: 20px;
}
.sextion {
  /* overflow-x: scroll;
  height: 100vh; */
  margin-bottom: 100px;
}
.name {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.tips {
  flex: 8;
  display: flex;
  /* flex-wrap: wrap; */
}
.names {
  flex: 2;

  magin-right: 10px;
}
</style>

<style>
:root {
  --van-tabs-bottom-bar-color: #3b0aee !important;
}
</style>

<template>
  <div class="wrap">
    <div class="search">
      <div class="input">
        <van-search v-model="value" placeholder="搜索问题/答案" @click-left-icon="search" />
      </div>
      <div class="collection" @click="opencollection">
        <img src="../assets/collection.png" alt="" />
      </div>
    </div>
    <div class="tab">
      <van-tabs v-model:active="active" @change="onChangeType">
        <div class="sider">
          <van-sidebar v-model="type" @change="onChange">
            <van-sidebar-item title="文本" />
            <van-sidebar-item title="图片" />
            <van-sidebar-item title="视频" />
          </van-sidebar>
        </div>
        <div class="section">
          <van-tab v-for="(item, index) in array" :title="item.title" @click="checkTop(index)">
            <div class="content" v-for="(item, index) in num">
              <div class="content-title" v-show="item.type == 1">
                <div class="itemtitle">
                  {{ item.title }}
                </div>
                <div class="itemcontent">
                  {{ item.content }}
                </div>
              </div>
              <div class="content-sections" v-show="item.type == 2">
                <div class="itemtitle">
                  {{ item.title }}
                </div>
                <img
                  :src="item.content"
                  alt=""
                  class="showpicture"
                  @click="openImg(item.content)"
                />
              </div>
              <div class="content-sections" v-show="item.type == 3">
                <div class="itemtitle">
                  {{ item.title }}
                </div>
                <video
                  style="width: 120px; height: 120px; object-fit: cover"
                  :src="item.content"
                  autoplay
                ></video>
              </div>
              <div class="sc">
                <div class="icon">
                  <img :src="item.is_check ? yes : none" alt="" @click="shoucang(item, index)" />
                  <div class="font-sc">{{ item.is_check ? '取消收藏' : '收藏' }}</div>
                </div>
                <div class="send" @click="send(item.type, item.content)">一键发送</div>
              </div>
            </div>
          </van-tab>
        </div>
      </van-tabs>
    </div>

    <van-image-preview />
  </div>

  <van-overlay :show="show" @click="show = false">
    <div class="wrapper" @click.stop>
      <div class="block">
        <div class="div">
          <div class="sc-title">
            <div class="backsc" @click="closesc"><van-icon name="arrow-left" />收藏</div>
            <!-- <div class="over" @click="closesc">完成</div> -->
          </div>
          <div class="search-title">
            <van-search
              v-model="scvalue"
              placeholder="请输入搜索关键词"
              @click-left-icon="searchsc"
            />
          </div>
          <div>
            <div class="content" v-for="(item, index) in sclist">
              <div class="content-title" v-show="item.question_library.type == 1">
                <div class="itemtitle">
                  {{ item.question_library.title }}
                </div>
                <div class="itemcontent">
                  {{ item.question_library.content }}
                </div>
                <!-- {{ item.question_library.content }} -->
              </div>
              <div class="content-sections" v-show="item.question_library.type == 2">
                <div class="itemtitle">
                  {{ item.question_library.title }}
                </div>
                <img
                  :src="item.question_library.content"
                  alt=""
                  class="showpicture"
                  @click="openImg(item.question_library.content)"
                />
              </div>
              <div class="content-sections" v-show="item.question_library.type == 3">
                <div class="itemtitle">
                  {{ item.question_library.title }}
                </div>
                <video
                  style="width: 120px; height: 120px; object-fit: cover"
                  :src="item.question_library.content"
                  autoplay
                ></video>
              </div>
              <div class="sc">
                <div class="icon">
                  <img :src="item.is_check ? yes : none" alt="" @click="scqx(item, index)" />
                  <div class="font-sc">{{ item.is_check ? '取消收藏' : '收藏' }}</div>
                </div>
                <div
                  class="send"
                  @click="send(item.question_library.type, item.question_library.content)"
                >
                  一键发送
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
// eslint-disable-next-line
import { defineComponent, ref, computed, onMounted } from 'vue'
import none from '../assets/none.png'
import yes from '../assets/yes.png'
import { ImagePreview } from 'vant'
import axios from 'axios'
import { getBase64 } from '../utils/urlencodeUrl'
export default defineComponent({
  name: 'own',
  props: {},
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },

  // eslint-disable-next-line
  setup() {
    onMounted(() => {
      onChange()
      getList()
      getsc()
    })
    const token = window.localStorage.getItem('token')
    const checkLeftId = ref()
    const show = ref(false)
    const sclist = ref()
    function checkTop(index) {
      console.log(array.value)
    }
    function getList() {
      axios
        .post(`/api/enterprise/qtypeList`, '', {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          if (res.data.code == 200) {
            array.value = res.data.data.list
            checkLeftId.value = res.data.data.list[0].id
            getTypeLits()
          }
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
    }
    function getsc() {
      axios
        .post(`/api/enterprise/listQuestionCollection`, '', {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          if (res.data.code == 200) {
            sclist.value = res.data.data.list
            console.log(sclist.value)
          }
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
    }
    function getTypeLits() {
      let params = {
        parent_id: checkLeftId.value,
        type: type.value + 1,
      }
      axios
        .post(`/api/enterprise/qtypeContentList`, params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          if (res.data.code == 200) {
            num.value = res.data.data.list
          }
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
    }
    const VanImagePreview = ImagePreview.Component
    const value = ref('')
    const active = ref('')
    const type = ref(0)
    const newarraylist = ref([])
    // const none = ref(none)
    // const yes = ref(yes)
    const array = ref()

    function opencollection() {
      console.log(1)
      show.value = true
      searchsc()
    }
    const num = ref([])
    function onChange() {
      console.log(type.value)
      getTypeLits()
    }
    function search() {
      console.log(1)

      let params = {
        parent_id: checkLeftId.value,
        type: type.value + 1,
        title: value.value,
      }
      axios
        .post(`/api/enterprise/qtypeContentList`, params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          if (res.data.code == 200) {
            num.value = res.data.data.list
          }
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
    }
    function onChangeType() {
      checkLeftId.value = array.value[active.value].id
      console.log(checkLeftId.value)
      getTypeLits()
    }

    function openImg(imgurl) {
      ImagePreview([imgurl])
    }
    function shoucang(item, index) {
      num.value[index].is_check = !num.value[index].is_check
      let params = {
        question_id: item.id,
        type: item.is_check ? 1 : 2,
        title: item.title,
      }
      axios
        .post(`/api/enterprise/addQuestionCollection`, params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          if (res.data.code == 200) {
          }
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
    }

    function scqx(item, index) {
      sclist.value[index].is_check = !sclist.value[index].is_check
      let params = {
        question_id: item.question_id,
        type: item.is_check ? 1 : 2,
      }
      axios
        .post(`/api/enterprise/addQuestionCollection`, params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          if (res.data.code == 200) {
            // getsc()
          }
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
    }

    function closesc() {
      show.value = false
    }

    function searchsc() {
      let params = {
        // parent_id: checkLeftId.value,
        // type: type.value + 1,
        title: scvalue.value,
      }
      axios
        .post(`/api/enterprise/listQuestionCollection`, params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          if (res.data.code == 200) {
            sclist.value = res.data.data.list
          }
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
    }
    const scvalue = ref()
    function send(type, item) {
      console.log(type, item)
      if (type == 1) {
        wx.invoke(
          'sendChatMessage',
          {
            msgtype: 'text', //消息类型，必填
            enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
            text: {
              content: item, //文本内容
            },
          },
          function (res) {
            if (res.err_msg == 'sendChatMessage:ok') {
              Toast('发送成功')
            }
          }
        )
      } else if (type == 2) {
        // getBase64(item, (dataURL) => {
        // console.log('dataURL :>> ', dataURL)
        let params = {
          base_img: item,
          type: 3,
        }
        axios
          .post('/api/enterprise/getMediaID', params, {
            headers: { Authorization: `bearer ${token}` },
          })
          .then((res) => {
            if (res.data.code == 200) {
              wx.invoke(
                'sendChatMessage',
                {
                  msgtype: 'image', //消息类型，必填
                  enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
                  image: {
                    mediaid: res.data.data.media_id, //图片的素材id
                  },
                },
                function (res) {
                  if (res.err_msg == 'sendChatMessage:ok') {
                    Toast('发送成功')
                    showModel.value = false
                  }
                }
              )
            }
          })
        // })
      } else {
        let params = {
          base_img: item,
          type: 2,
        }
        axios
          .post('/api/enterprise/getMediaID', params, {
            headers: { Authorization: `bearer ${token}` },
          })
          .then((res) => {
            if (res.data.code == 200) {
              wx.invoke(
                'sendChatMessage',
                {
                  msgtype: 'video', //消息类型，必填
                  enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
                  video: {
                    mediaid: res.data.data.media_id, //图片的素材id
                  },
                },
                function (res) {
                  if (res.err_msg == 'sendChatMessage:ok') {
                    Toast('发送成功')
                    showModel.value = false
                  }
                }
              )
            }
          })
      }
    }
    return {
      send,
      scvalue,
      searchsc,
      closesc,
      scqx,
      shoucang,
      value,
      active,
      type,
      array,
      newarraylist,
      none,
      yes,
      num,
      VanImagePreview,
      opencollection,
      onChange,
      onChangeType,
      openImg,
      getList,
      token,
      getTypeLits,
      checkLeftId,
      checkTop,
      search,
      show,
      getsc,
      sclist,
    }
  },
})
</script>

<style scoped>
.wrap {
  width: 100%;
  height: 100%;
  background: white;
}
.search {
  background: white;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input {
  width: 85%;
}
.collection {
  width: 10%;
}
.collection img {
  width: 31px;
  height: 27px;
}
.van-tabs__content {
  width: 100%;
  display: flex;
}
.sider {
  width: 21%;
  height: 600px;
  background: white;
}
.section {
  width: 100%;
  display: flex;
  justify-content: center;
  background: #f4f7f8;
  height: 550px;
  overflow-y: scroll;
}
.content {
  width: 90%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  background: white;
  margin-top: 10px;
  border-radius: 4px;
  margin: 10px auto;
}
.content-title {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  /* height: 50px; */
  font-family: PingFangSC-Medium, PingFang SC;
  color: #333333;
  line-height: 20px;
  padding-left: 15px;
  padding-top: 10px;
  overflow: hidden;
  -webkit-line-clamp: 10;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.content-section {
  width: 90%;
  padding-left: 15px;
  height: auto;
  padding-top: 10px;
  font-size: 12px;
}
.sc {
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
  margin-bottom: 10px;
}
.icon {
  width: 50%;
  height: 20px;
  font-size: 14px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-left: 10px;
}
.font-sc {
  /* width: 17px;
  height: 17px; */
  margin-top: 4px;
  margin-left: 8px;
}
.icon img {
  width: 17px;
  height: 17px;
  /* margin-right: 10px; */
}
.send {
  width: 30%;
  height: 25px;
  font-size: 12px;
  background: #0052fe;
  border-radius: 15px;
  color: white;
  line-height: 25px;
  text-align: center;
  margin-right: 10px;

  /* padding: 10px; */
}
.content-sections {
  padding-left: 15px;
  padding-top: 10px;
  width: 250px;
  height: 165px;
}
.showpicture {
  width: 90%;
  height: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}
.div {
  /* width: 100%;
  display: flex;
  flex-wrap: wrap; */
}
.sc-title {
  display: flex;
  width: 100%;
  height: 40px;
  background: #ffffff;
  justify-content: space-between;
  line-height: 40px;
}
.backsc {
  margin-left: 10px;
}
.over {
  margin-right: 10px;
  background: #0052fe;
  color: white;
  width: 50px;
  height: 28px;
  border-radius: 5px;
  line-height: 28px;
  text-align: center;
  margin-top: 10px;
}
.van-sidebar {
  width: 100% !important;
}
.itemtitle {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.itemcontent {
  margin-top: 5px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #585858;
  overflow: hidden;
  -webkit-line-clamp: 10;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
</style>

<style>
.van-tabs__content {
  display: flex;
}
.van-sidebar-item {
  text-align: center;
}
.van-sidebar-item--select,
.van-sidebar-item--select:active {
  text-align: center;
}
:root {
  --van-sidebar-background-color: white !important;
  --van-sidebar-selected-border-color: #3b0aee !important;
}
</style>

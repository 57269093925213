<template>
  <div class="wraps">
    <div class="khlist">
      <van-search
        v-model="value"
        placeholder="客户姓名/昵称
"
        @click-left-icon="search"
      />
      <div class="num">
        共
        <div class="num-list">{{ num }}</div>
        名客户
      </div>
      <div class="custom-list" v-for="(item, index) in list">
        <div class="list-wrap">
          <div class="img">
            <img :src="item.image_url" alt="" />
          </div>
          <div class="msg">
            <div class="name">
              {{ item.name ? item.name : item.wechat_name }}
              <div v-if="item.customer_stage" class="title">{{ item.customer_stage.title }}</div>
            </div>
            <div class="time">最后跟进时间: {{ item.follow_time }}</div>
            <div class="sex">性别: {{ item.sex == 0 ? '女' : '男' }}</div>
          </div>
          <div class="openicon" @click="gomesg(item)">
            <img src="../assets/open.png" alt="" style="width: 26px; height: 26px" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { defineComponent, ref, computed, onMounted } from 'vue'
import axios from 'axios'
// eslint-disable-next-line
import http from '../utils/http/http'
import { logins } from '../utils/http/logins'

export default defineComponent({
  name: 'Customerlist',
  props: {
    msg: String,
    id: String,
    key: Number,
  },
  components: {},
  // eslint-disable-next-line
  setup(props) {
    onMounted(() => {
      getlist()
    })
    let wx = window.wx
    const token = window.localStorage.getItem('token')
    const corpid = window.localStorage.getItem('corpid')
    const htmlUrl = ref('')
    let getUrl = window.location.href
    const list = ref()
    const num = ref()
    let urlparams = {
      url: getUrl,
    }
    const value = ref('')
    function getlist() {
      axios
        .post('/api/enterprise/listCustomer', '', { headers: { Authorization: `bearer ${token}` } })
        .then((res) => {
          list.value = res.data.data.list
          num.value = list.value.length
        })
    }
    const agentid = window.localStorage.getItem('agentid')

    axios
      .post(`/api/enterprise/auth/sign/app`, urlparams, {
        headers: { Authorization: `bearer ${token}` },
      })
      .then((res) => {
        wx.agentConfig({
          corpid: corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid: agentid, // 必填，企业微信的应用id （e.g. 1000247）
          timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.data.nonce_str, // 必填，生成签名的随机串
          signature: res.data.data.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
          jsApiList: ['getCurExternalContact', 'sendChatMessage', 'openEnterpriseChat'], //必填，传入需要使用的接口名称
          success: function () {
            // 回调
            wx.invoke('getCurExternalContact', {}, function (res) {
              if (res.err_msg == 'getCurExternalContact:ok') {
                window.localStorage.setItem('userid', res.userId)
              }
            })
          },
          fail: function (res) {
            console.log('useridfail')
            console.log(res)
            if (res.errMsg.indexOf('function not exist') > -1) {
              alert('版本过低请升级')
            }
          },
        })
      })
      .catch((err) => {
        console.log(err.response)
        logins()
        if (err.response.data.code == 401) {
          logins()
        }
      })
    const active = ref(0)
    function search() {
      let params = {
        name: value.value,
      }
      axios
        .post('/api/enterprise/listCustomer', params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          list.value = res.data.data.list
          num.value = list.value.length
        })
    }
    function gomesg(item) {
      console.log(item)
      let id = JSON.parse(JSON.stringify(item))
      console.log(id)
      console.log(id.external_user_id)
      let externalUserIds = id.external_user_id
      let userIds = window.localStorage.getItem('userid')
      console.log(externalUserIds)
      console.log(userIds)
      wx.openEnterpriseChat({
        // 注意：userIds和externalUserIds至少选填一个。内部群最多2000人；外部群最多500人；如果有微信联系人，最多40人
        userIds: userIds, //参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
        externalUserIds: externalUserIds, // 参与会话的外部联系人列表，格式为userId1;userId2;…，用分号隔开。
        groupName: '', // 会话名称。单聊时该参数传入空字符串""即可。
        chatId: '', // 若要打开已有会话，需指定此参数。如果是新建会话，chatId必须为空串
        success: function (res) {
          console.log(res)
          var chatId = res.chatId //返回当前群聊ID，仅当使用agentConfig注入该接口权限时才返回chatId
          // 回调
        },
        fail: function (res) {
          console.log(res)

          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级')
          }
        },
      })
    }
    return {
      active,
      getlist,
      list,
      num,
      search,
      value,
      gomesg,
    }
  },
})
</script>

<style scoped>
.wraps {
  width: 100%;
  height: 100vh;
  background: #f4f7f8;
  display: flex;
  flex-wrap: wrap;
  overflow-x: scroll;
}
.khlist {
  background: #f4f7f8;
  width: 100%;
}
.num {
  display: flex;
  align-content: center;
  line-height: 20px;
  height: 20px;
  margin-left: 20px;
  margin-top: 15px;

  color: #707070;
  /* justify-content: center; */
}
.num-list {
  color: #0051ff;
  line-height: 20px;
}
.custom-list {
  width: 90%;
  margin: 0 auto;
  background: white;
  margin-top: 15px;

  border-radius: 4px;
}
.list-wrap {
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}
.img {
  width: 25%;
  text-align: center;
}
.msg {
  width: 60%;
}
.img img {
  width: 57px;
  border-radius: 4px;
  height: 57px;
}
.name {
  font-size: 17px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #282828;
  line-height: 24px;
  display: flex;
  align-items: center;
}
.title {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0052fe;
  line-height: 17px;
  margin-left: 20px;
  background: #edf1fb;
  border-radius: 4px;
  padding: 1px 10px;
}
.time,
.sex {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #777777;
  line-height: 20px;
}
.openicon {
  width: 15%;
  text-align: center;
  /* margin-left: 10px; */
}
</style>

<style></style>

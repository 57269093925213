<template>
  <div class="warp">
    <div class="title">
      <div class="one" @click="close"><van-icon name="arrow-left" /></div>
      <div class="two">待办事项</div>
      <div class="three"></div>
    </div>
    <van-tabs v-model:active="active" @change="checkDb">
      <van-tab title="未开始">
        <div class="list">任务列表({{ num }})</div>
        <div class="section" v-for="(item, index) in list" @click="goDetails(item)">
          <div class="taskLine"></div>
          <div class="main">
            <div class="main-title">{{ item.title }}</div>
            <div class="main-content" v-if="item.type === 1">{{ item.content }}</div>
            <div class="main-content" v-if="item.type === 2">
              <img :src="item.content" alt="" />
            </div>
            <div class="main-content" v-if="item.type === 3">
              <video
                style="width: 120px; height: 120px; object-fit: cover"
                :src="item.content"
                autoplay
              ></video>
            </div>
            <div class="main-time">{{ item.start_time }} - {{ item.end_time }}</div>
          </div>
          <div class="check"></div>
        </div>
      </van-tab>
      <van-tab title="未完成">
        <div class="list">任务列表({{ num }})</div>
        <div class="section" v-for="(item, index) in list" @click="goDetails(item)">
          <div :class="item.status == 4 ? 'taskLine-yq' : 'taskLine'"></div>
          <div class="main">
            <div class="main-title" v-if="item.status != 4">{{ item.title }}</div>
            <div class="main-title-yq" v-else>
              <div>{{ item.title }}</div>
              <div>逾期</div>
            </div>
            <div class="main-content" v-if="item.type === 1">{{ item.content }}</div>
            <div class="main-content" v-if="item.type === 2">
              <img :src="item.content" alt="" />
            </div>
            <div class="main-content" v-if="item.type === 3">
              <video
                style="width: 120px; height: 120px; object-fit: cover"
                :src="item.content"
                autoplay
              ></video>
            </div>
            <div class="main-time">{{ item.start_time }} - {{ item.end_time }}</div>
          </div>
          <div class="check"></div>
        </div>
      </van-tab>
      <van-tab title="已完成">
        <div class="list">任务列表({{ num }})</div>
        <div class="section" v-for="(item, index) in list" @click="goDetails(item)">
          <div class="taskLine"></div>
          <div class="main">
            <div class="main-title">{{ item.title }}</div>
            <div class="main-content" v-if="item.type === 1">{{ item.content }}</div>
            <div class="main-content" v-if="item.type === 2">
              <img :src="item.content" alt="" />
            </div>
            <div class="main-content" v-if="item.type === 3">
              <video
                style="width: 120px; height: 120px; object-fit: cover"
                :src="item.content"
                autoplay
              ></video>
            </div>
            <div class="main-time">{{ item.start_time }} - {{ item.end_time }}</div>
          </div>
          <div class="check"></div>
        </div>
      </van-tab>
    </van-tabs>
    <div class="add" @click="addTask">
      <img src="../assets/add.png" alt="" class="addimg" />
    </div>
  </div>

  <van-overlay :show="showmodel">
    <div class="wrapper" @click.stop>
      <div class="block">
        <Taskmodel
          @closeTask="closeTask"
          @getlist="getlist"
          :key="Math.random().toString(36)"
        ></Taskmodel>
      </div>
    </div>
  </van-overlay>

  <van-overlay :show="showdetail">
    <div class="wrapper" @click.stop>
      <div class="block">
        <Detail
          @closeDetail="closeDetail"
          :item="detail"
          :key="Math.random().toString(36)"
          v-if="showdetail"
        ></Detail>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import axios from 'axios'
import { Toast } from 'vant'
import Taskmodel from './taskmodel.vue'
import Detail from './detail.vue'

export default defineComponent({
  components: {
    Taskmodel,
    Detail,
  },
  emits: ['closeNeed'],
  props: {
    key: Number,
  },
  setup(props, ctx) {
    onMounted(() => {
      getinfo()
    })
    const token = window.localStorage.getItem('token')
    const showmodel = ref(false)
    const checked = ref(true)
    const show = ref(true)
    const active = ref(0)
    const num = ref(0)
    const list = ref([])
    const showdetail = ref(false)
    const detail = ref()
    function close() {
      ctx.emit('closeNeed')
    }

    function addTask() {
      showmodel.value = true
    }
    function checkDb() {
      console.log(active.value)
      getinfo()
    }
    function closeTask() {
      showmodel.value = false
    }
    function getlist() {
      getinfo()
    }
    function closeDetail() {
      showdetail.value = false
    }
    function getinfo() {
      let params = {
        customer_id: window.sessionStorage.getItem('customer_id'),
        status: active.value == 0 ? 1 : active.value == 1 ? 2 : 3,
      }
      axios
        .post(`/api/enterprise/listCustomerTodo`, params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          console.log(res.data.data.list)
          list.value = res.data.data.list
          num.value = list.value.length
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
    }

    function goDetails(item) {
      detail.value = item
      showdetail.value = true
      console.log(showdetail.value)
    }
    return {
      showdetail,
      closeDetail,
      token,
      active,
      num,
      checked,
      list,
      close,
      show,
      addTask,
      closeTask,
      getlist,
      showmodel,
      getinfo,
      goDetails,
      detail,
      checkDb,
    }
  },
})
</script>

<style scoped>
.main-title-yq {
  display: flex;
  justify-content: space-between;
  color: #ff5c64;
}
.warp {
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  z-index: 3;
}
.title {
  width: 100%;
  background: white;
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
.one {
  width: 33.3%;
}
.two {
  width: 33.3%;
  text-align: center;
}
.three {
  width: 33.3%;
}
.list {
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #707070;
  line-height: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.section {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  /* margin-top: 5px; */
  margin-bottom: 20px;
}
.taskLine {
  width: 3px;
  height: 100%;
  background: #0052fe;
  border-radius: 4px 0px 0px 4px;
}
.taskLine-yq {
  width: 3px;
  height: 100%;
  background: #ff5c64;
  border-radius: 4px 0px 0px 4px;
}
.van-tab__panel {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.main {
  width: 85%;
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.main-title {
  /* margin-top: 5px; */
  color: #0052fe;
}
.main-content {
  margin-top: 5px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.main-time {
  margin-top: 5px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #777777;
}
.check {
  margin-right: 10px;
  text-align: center;
}
img {
  width: 70px;
  height: 70px;
}
.add {
  /* width: 100px;
  height: 100px; */
  border-radius: 50%;
  position: fixed;
  bottom: 10px;
  right: 10px;
}
.addimg {
  width: 58px;
  height: 58px;
}
.van-tab__panel {
  margin-bottom: 60px !important;
}
</style>

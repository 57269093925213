<template>
  <div class="wrap">
    <div class="back" @click="close"><van-icon name="arrow-left" />返回</div>
    <div class="input">
      <van-field v-model="value" label="*任务名称" placeholder="请输入任务名称" maxlength="20" />
    </div>
    <div class="task-tilte">*任务类型</div>
    <div class="task-check">
      <van-radio-group v-model="checked" direction="horizontal">
        <van-radio name="1">文本</van-radio>
        <van-radio name="2">图片</van-radio>
        <van-radio name="3">视频</van-radio>
      </van-radio-group>
    </div>
    <div class="task-font" v-show="checked == 1">
      <van-field
        v-model="message"
        rows="2"
        autosize
        type="textarea"
        maxlength="200"
        placeholder="请输入内容"
        show-word-limit
      />
    </div>

    <div class="task-img" v-show="checked == 2">
      <div class="img-div">
        <van-uploader v-model="fileList" multiple max-count="1" :after-read="getFileImg" />
      </div>
    </div>

    <div class="task-img" v-show="checked == 3">
      <div class="img-div">
        <van-uploader multiple max-count="1" accept="video/*" :after-read="getFile" />
        <video
          style="width: 120px; height: 120px; object-fit: cover"
          :src="videourl"
          v-if="videourl"
          autoplay
        ></video>
      </div>
    </div>

    <div class="star_time">
      <van-field
        v-model="star_time"
        label="开始时间"
        placeholder="请选择>"
        input-align="right"
        @click="showTime('left')"
      />
    </div>
    <div class="end_time">
      <van-field
        v-model="end_time"
        label="结束时间"
        placeholder="请选择>"
        input-align="right"
        @click="showTime('right')"
      />
    </div>

    <div class="button">
      <div class="closeBtn" @click="closeBtn">取消</div>
      <div class="sureBtn" @click="sure">确定</div>
    </div>
  </div>
  <van-popup v-model:show="show" position="bottom">
    <van-datetime-picker
      v-model="currentDate"
      type="datetime"
      title="选择年月日小时"
      :min-date="minDate"
      @confirm="getTime"
      @cancel="closetime"
      :max-date="maxDate"
    />
  </van-popup>
</template>

<script>
import { defineComponent, ref } from 'vue'
import axios from 'axios'
import { Toast } from 'vant'

export default defineComponent({
  emits: ['closeTask', 'getlist'],
  props: {
    key: Number,
  },
  setup(props, ctx) {
    // onMounted(() => {
    //   getinfo()
    // })
    const token = window.localStorage.getItem('token')
    const value = ref()
    const checked = ref('1')
    const message = ref()
    const fileList = ref()
    const videourl = ref()
    const star_time = ref()
    const end_time = ref()
    const show = ref(false)
    const timeType = ref()
    const currentDate = ref(new Date())
    function close() {
      ctx.emit('closeTask')
    }
    function closeBtn() {
      ctx.emit('closeTask')
    }

    function getFile(file, detail) {
      var data = new FormData()
      data.append('file', file.file)
      let config = {
        headers: {
          //添加请求头
          'Content-Type': 'multipart/form-data',
        },
      }
      //上传图片
      axios.post('/api/upload/imgage', data, config).then((res) => {
        console.log('res', res)
        if (res.data.code == 200) {
          videourl.value = res.data.data.imgurl
        }
      })
    }

    const imgurl = ref()
    function getFileImg(file, detail) {
      var data = new FormData()
      data.append('file', file.file)
      let config = {
        headers: {
          //添加请求头
          'Content-Type': 'multipart/form-data',
        },
      }
      //上传图片
      axios.post('/api/upload/imgage', data, config).then((res) => {
        // console.log('res', res)
        console.log(res.data)
        if (res.data.code == 200) {
          imgurl.value = res.data.data.imgurl
        }
      })
    }

    function showTime(type) {
      show.value = true
      timeType.value = type
    }
    function getTime(value) {
      let Sign = '-'
      let _Date = new Date(value)
      let b = _Date.getHours()
      let c = _Date.getMonth() + 1
      let d = _Date.getDate()

      if (b < 10) {
        b = '0' + b
      }
      if (c < 10) {
        c = '0' + c
      }
      if (d < 10) {
        d = '0' + d
      }
      console.log(b)

      let a = _Date.getFullYear() + Sign + c + Sign + d + ' ' + b + ':' + _Date.getMinutes()
      ':' + _Date.getSeconds()
      if (timeType.value === 'right') {
        end_time.value = a
      } else {
        star_time.value = a
      }
      show.value = false
      console.log(a)
    }
    function sure() {
      let params = {
        customer_id: window.localStorage.getItem('customer_id'),
        title: value.value,
        type: checked.value,
        content:
          checked.value == 1 ? message.value : checked.value == 2 ? imgurl.value : videourl.value,
        start_time: star_time.value,
        end_time: end_time.value,
      }
      axios
        .post(`/api/enterprise/addCustomerTodo`, params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          console.log(res)
          if (res.data.code == 200) {
            Toast.success('添加成功')
            ctx.emit('closeTask')
            ctx.emit('getlist')
            value.value = ''
            checked.value = '1'
            message.value = ''
            imgurl.value = ''
            videourl.value = ''
            start_time.value = ''
            end_time.value = ''
          } else {
          }
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          }
          Toast.fail(err.response.data.message)
        })
    }

    function closetime() {
      show.value = false
    }
    return {
      // getinfo,
      sure,
      timeType,
      showTime,
      getFile,
      token,
      close,
      value,
      checked,
      message,
      fileList,
      videourl,
      getFileImg,
      star_time,
      end_time,
      show,
      currentDate,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      getTime,
      imgurl,
      closeBtn,
      closetime,
    }
  },
})
</script>

<style scoped>
.back {
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
}
.task-tilte {
  color: #777777;
  font-size: 14px;
  padding-left: 15px;
  padding-top: 10px;
}
.task-check {
  width: 100%;
  padding-left: 15px;

  margin-top: 15px;
}
.task-font {
  margin-top: 15px;
}
.img-div {
  height: 150px;
  line-height: 150px;
  width: 100%;
  background: white;
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.star_time {
  margin-top: 30px;
  border-bottom: 1px solid #ccc;
}
.end_time {
}
.button {
  width: 100%;
  display: flex;
  margin-top: 100px;
  position: fixed;
  bottom: 0;
}
.closeBtn {
  width: 50%;
  text-align: center;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  color: #aaaaaa;
  padding: 10px 0px;
}
.sureBtn {
  width: 50%;
  text-align: center;
  background-color: rgba(22, 155, 213, 1);
  color: white;
  padding: 10px 0px;
}
</style>

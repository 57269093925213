/**
 * Add the object as a parameter to the URL
 * @param path url
 * @param query
 * @returns {string}
 * eg:
 *  urlencodeUrl(path, obj)
 */
export function urlencodeUrl (path, query) {
  let newpath = encodeURIComponent(path)
  let newstr = ''
  for (const key in query) {
    newstr += key + '=' + query[key] + '&'
  }
  let newquery = encodeURIComponent(newstr)
  let baerurl = `https://m.myzx.cn/link/general?path=${newpath}&query=${newquery}`
  return baerurl
}


//获取图片Base64
export function getBase64 (url, callback) {
  var Img = new Image(),
    dataURL = ''
  Img.crossOrigin = 'Anonymous'
  Img.src = url + '?v=' + Math.random()
  Img.setAttribute('crossOrigin', 'Anonymous')
  Img.onload = function () {
    var canvas = document.createElement('canvas'),
      width = Img.width,
      height = Img.height
    canvas.width = width
    canvas.height = height
    canvas.getContext('2d').drawImage(Img, 0, 0, width, height)
    dataURL = canvas.toDataURL('image/png')
    return callback ? callback(dataURL) : null
  }
}
<template>
  <Qrcode v-if="type == 'qrcode'" :id="uesr_id" :key="Math.random().toString(36)"></Qrcode>
  <Register
    v-else-if="type == 'register'"
    :id="uesr_id"
    :ghlist="ghlist"
    :key="Math.random().toString(36)"
  ></Register>
  <RegisterList
    v-else-if="type == 'registerList'"
    :registerDate="registerDate"
    @getregisterDate="getregisterDate"
    :key="Math.random().toString(36)"
  ></RegisterList>
  <Charge
    v-else-if="type == 'charge'"
    @chargeid="chargeid"
    :key="Math.random().toString(36)"
  ></Charge>
  <ScriptList v-else-if="type == 'scriptlist'" :key="Math.random().toString(36)"></ScriptList>
  <Details v-else-if="type == 'details'" :id="uesr_id" :key="Math.random().toString(36)"></Details>
  <Customerlist v-else-if="type == 'customerlist'" :key="Math.random().toString(36)"></Customerlist>
  <Dump v-else :key="Math.random().toString(36)"></Dump>
</template>

<script>
import Qrcode from './components/qrcode.vue'
import Register from './components/register.vue'
import RegisterList from './components/registerList.vue'
import Charge from './components/charge.vue'
import ScriptList from './components/scriptList.vue'
import Details from './components/details.vue'
import Customerlist from './components/customerlist.vue'
import Dump from './components/dump.vue'

// eslint-disable-next-line
import { computed, onMounted, ref } from 'vue'
import { getUrlParam } from '../src/utils/getUrl'
import '@vant/touch-emulator'
import axios from 'axios'
import http from './utils/http/http'

export default {
  name: 'App',
  components: {
    Qrcode,
    Register,
    RegisterList,
    Charge,
    ScriptList,
    Details,
    Customerlist,
    Dump,
  },

  setup() {
    const registerDate = ref({})
    console.log(process.env.VUE_APP_BASE_API)
    let ishow = ref(false)
    let url = window.location
    let getUrl = window.location.href
    let type = getUrlParam(url, 'type')
    let token = window.localStorage.getItem('token')
    let corpid = getUrlParam(url, 'organization')
    let code = getUrlParam(url, 'code')
    let wx = window.wx
    let unloadurl = encodeURIComponent(url.href)
    let qyid = ref('')
    let times = ref('')
    let nonceStrs = ref('')
    let signatures = ref('')
    let uesr_id = ref('')
    const ghlist = ref({})
    // const token = window.localStorage.getItem('token')
    let organization = getUrlParam(url, 'organization')
    window.localStorage.setItem('organization', organization)
    console.log(wx)
    onMounted(() => {
      // login()
    })

    function login() {
      const params = {
        code: code,
      }
      axios.post(`/api/enterprise/auth/login/${corpid}`, params).then((ress) => {
        window.localStorage.setItem('token', ress.data.data.access_token)
        const corpid = window.localStorage.getItem('corpid')
        const agentid = window.localStorage.getItem('agentid')
        let urlparams = {
          url: getUrl,
        }
        axios
          .post(`/api/enterprise/auth/sign/app`, urlparams, {
            headers: { Authorization: `bearer ${ress.data.data.access_token}` },
          })
          .then((res) => {
            wx.agentConfig({
              corpid: corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
              agentid: agentid, // 必填，企业微信的应用id （e.g. 1000247）
              timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.data.nonce_str, // 必填，生成签名的随机串
              signature: res.data.data.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
              jsApiList: [
                'selectExternalContact',
                'getCurExternalContact',
                'sendChatMessage',
                'openUserProfile',
                'openEnterpriseChat',
              ], //必填，传入需要使用的接口名称
              success: function () {
                // 回调
                wx.invoke('getCurExternalContact', {}, function (res) {
                  if (res.err_msg == 'getCurExternalContact:ok') {
                    console.log(res)
                    window.localStorage.setItem('userid', res.userId)
                    window.sessionStorage.setItem('userid', res.userId)
                    uesr_id.value = res.userId //返回当前外部联系人userId
                  } else {
                    console.log(res)
                    //错误处理
                  }
                })
              },
              fail: function (res) {
                console.log(res)
                if (res.errMsg.indexOf('function not exist') > -1) {
                  alert('版本过低请升级')
                }
              },
            })
          })
          .catch((err) => {
            console.log(err.response)
            if (err.response.data.code == 401) {
              logins()
            }
          })
      })
    }
    if (!code) {
      axios.get(`/api/enterprise/auth/corpid/${corpid}`).then((res) => {
        if (res.data.code == 200) {
          qyid.value = res.data.data.corpid
          window.localStorage.setItem('corpid', res.data.data.corpid)
          window.localStorage.setItem('agentid', res.data.data.agent_id)
          if (corpid == 'mingyi' || corpid == 'bjxmhwh' || corpid == 'hntywh' || corpid == 'yscm') {
            location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${res.data.data.corpid}&redirect_uri=${unloadurl}&response_type=code&scope=snsapi_base&state=${corpid}#wechat_redirect`
          } else {
            location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${res.data.data.corpid}&redirect_uri=${unloadurl}&response_type=code&scope=snsapi_privateinfo&state=${corpid}&agentid=${res.data.data.agent_id}#wechat_redirect`
          }
        }
      })
    }
    if (code) {
    }
    function getregisterDate(val) {
      window.location.href = url.href.replace('registerList', 'register')
      ghlist.value = val
    }
    function chargeid(val) {
      window.location.href = url.href.replace('charge', 'register')
      console.log(val)
    }
    return {
      ishow,
      type,
      qyid,
      uesr_id,
      login,
      times,
      nonceStrs,
      signatures,
      getregisterDate,
      registerDate,
      ghlist,
      chargeid,
      token,
    }
  },
}
</script>

<style coped>
body {
  margin: 0;
}
#app {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
</style>

import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import Vant from 'vant';
import 'vant/lib/index.css';


import axios from 'axios'
// Vue.config.productionTip = false
// import VueCookies from 'vue-cookies';
// vue.prototype.$http = axios
axios.defaults.baseURL = '/'
const app = createApp(App)
// app.use(VueCookies)
// console.log(VueCookies)

app.use(ElementPlus)
app.use(Vant);
app.mount('#app')
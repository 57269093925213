<template>
  <div class="wrap">
    <div class="back" @click="close"><van-icon name="arrow-left" />返回</div>
    <div class="input">
      <van-field v-model="values" label="*任务名称" placeholder="请输入任务名称" readonly />
    </div>
    <div class="task-tilte">*任务类型</div>
    <div class="task-check">
      <van-radio-group v-model="checked" direction="horizontal" disabled>
        <van-radio name="1">文本</van-radio>
        <van-radio name="2">图片</van-radio>
        <van-radio name="3">视频</van-radio>
      </van-radio-group>
    </div>
    <div class="task-font" v-show="checked == 1">
      <van-field
        v-model="message"
        rows="2"
        autosize
        type="textarea"
        maxlength="200"
        placeholder="请输入内容"
        show-word-limit
        readonly
      />
    </div>

    <div class="task-img" v-show="checked == 2">
      <div class="img-div">
        <!-- <van-uploader v-model="fileList" multiple max-count="1" :after-read="getFileImg" /> -->
        <img :src="fileList" alt="" style="width: 120px; height: 120px; object-fit: cover" />
      </div>
    </div>

    <div class="task-img" v-show="checked == 3">
      <div class="img-div">
        <!-- <van-uploader multiple max-count="1" accept="video/*" :after-read="getFile" /> -->
        <video
          style="width: 120px; height: 120px; object-fit: cover"
          :src="videourl"
          autoplay
          v-if="videourl"
        ></video>
      </div>
    </div>

    <div class="star_time">
      <van-field
        v-model="star_time"
        label="开始时间"
        placeholder="请选择>"
        input-align="right"
        readonly
      />
    </div>
    <div class="end_time">
      <van-field
        v-model="end_time"
        label="结束时间"
        placeholder="请选择>"
        input-align="right"
        readonly
      />
    </div>

    <div class="end_time">
      <van-field
        v-model="creat_time"
        label="创建时间"
        placeholder="请选择>"
        input-align="right"
        readonly
      />
    </div>

    <div class="button">
      <div class="sureBtn" @click="sure">一键发送</div>
    </div>
  </div>
  <van-popup v-model:show="show" position="bottom">
    <van-datetime-picker
      v-model="currentDate"
      type="datetime"
      title="选择年月日小时"
      :min-date="minDate"
      @confirm="getTime"
      :max-date="maxDate"
    />
  </van-popup>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import axios from 'axios'
import { Toast } from 'vant'
import { getBase64 } from '../utils/urlencodeUrl'

export default defineComponent({
  emits: ['closeDetail'],
  props: {
    item: Object,
    key: Number,
  },
  setup(props, ctx) {
    // onMounted(() => {
    //   getinfo()
    // })

    console.log(props.item)
    const token = window.localStorage.getItem('token')
    const values = ref()
    const checked = ref()
    const message = ref()
    const fileList = ref()
    const videourl = ref()
    const star_time = ref()
    const end_time = ref()
    const show = ref(false)
    const timeType = ref()
    const currentDate = ref(new Date())
    const creat_time = ref()
    const id = ref()
    let detail = JSON.parse(JSON.stringify(props.item))
    console.log(detail)
    values.value = detail.title
    checked.value = detail.type + ''
    message.value = detail.content
    star_time.value = detail.start_time
    end_time.value = detail.end_time
    creat_time.value = detail.created_at
    videourl.value = detail.content
    fileList.value = detail.content
    id.value = detail.id
    function close() {
      ctx.emit('closeDetail')
      // values.value = ''
      // checked.value = ''
      // message.value = ''
      // star_time.value = ''
      // end_time.value = ''
      // creat_time.value = ''
    }
    function getinfo() {
      console.log(1111)
    }
    function getFile(file, detail) {
      var data = new FormData()
      data.append('file', file.file)
      let config = {
        headers: {
          //添加请求头
          'Content-Type': 'multipart/form-data',
        },
      }
      //上传图片
      axios.post('/api/upload/imgage', data, config).then((res) => {
        console.log('res', res)
        if (res.data.code == 200) {
          videourl.value = res.data.data.imgurl
        }
      })
    }

    const imgurl = ref()
    function getFileImg(file, detail) {
      var data = new FormData()
      data.append('file', file.file)
      let config = {
        headers: {
          //添加请求头
          'Content-Type': 'multipart/form-data',
        },
      }
      //上传图片
      axios.post('/api/upload/imgage', data, config).then((res) => {
        // console.log('res', res)
        console.log(res.data)
        if (res.data.code == 200) {
          imgurl.value = res.data.data.imgurl
        }
      })
    }

    function showTime(type) {
      console.log(111)
      show.value = true
      timeType.value = type
    }
    function getTime(value) {
      let Sign = '-'
      let _Date = new Date(value)
      let a =
        _Date.getFullYear() +
        Sign +
        (_Date.getMonth() + 1) +
        Sign +
        _Date.getDate() +
        ' ' +
        _Date.getHours() +
        ':' +
        _Date.getMinutes()
      ':' + _Date.getSeconds()
      if (timeType.value === 'right') {
        end_time.value = a
      } else {
        star_time.value = a
      }

      show.value = false
    }
    function sure() {
      let params = {
        id: id.value,
        status: 3,
      }
      axios
        .post(`/api/enterprise/finishCustomerTodo`, params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          console.log(res.data.data.list)
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
      if (checked.value == 1) {
        wx.invoke(
          'sendChatMessage',
          {
            msgtype: 'text', //消息类型，必填
            enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
            text: {
              content: message.value, //文本内容
            },
          },
          function (res) {
            if (res.err_msg == 'sendChatMessage:ok') {
              Toast('发送成功')
            }
          }
        )
      } else if (checked.value == 2) {
        // getBase64(fileList.value, (dataURL) => {
        let params = {
          base_img: fileList.value,
          type: 3,
        }
        axios
          .post('/api/enterprise/getMediaID', params, {
            headers: { Authorization: `bearer ${token}` },
          })
          .then((res) => {
            if (res.data.code == 200) {
              wx.invoke(
                'sendChatMessage',
                {
                  msgtype: 'image', //消息类型，必填
                  enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
                  image: {
                    mediaid: res.data.data.media_id, //图片的素材id
                  },
                },
                function (res) {
                  if (res.err_msg == 'sendChatMessage:ok') {
                    Toast('发送成功')
                    showModel.value = false
                  }
                }
              )
            }
            // })
          })
      } else {
        // console.log(window.atob(videourl.value))
        // getBase64(videourl.value, (dataURL) => {
        // console.log('dataURL :>> ', dataURL)
        let params = {
          base_img: videourl.value,
          type: 2,
        }
        axios
          .post('/api/enterprise/getMediaID', params, {
            headers: { Authorization: `bearer ${token}` },
          })
          .then((res) => {
            if (res.data.code == 200) {
              wx.invoke(
                'sendChatMessage',
                {
                  msgtype: 'video', //消息类型，必填
                  enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
                  video: {
                    mediaid: res.data.data.media_id, //图片的素材id
                  },
                },
                function (res) {
                  if (res.err_msg == 'sendChatMessage:ok') {
                    Toast('发送成功')
                    showModel.value = false
                  }
                }
              )
            }
          })
        // })
      }
    }
    return {
      getinfo,
      sure,
      timeType,
      showTime,
      getFile,
      token,
      close,
      values,
      checked,
      message,
      fileList,
      videourl,
      getFileImg,
      star_time,
      end_time,
      show,
      currentDate,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      getTime,
      imgurl,
      creat_time,
      id,
    }
  },
})
</script>

<style scoped>
.back {
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
}
.task-tilte {
  color: #777777;
  font-size: 14px;
  padding-left: 15px;
  padding-top: 10px;
}
.task-check {
  width: 100%;
  padding-left: 15px;

  margin-top: 15px;
}
.task-font {
  margin-top: 15px;
}
.img-div {
  height: 150px;
  line-height: 150px;
  width: 100%;
  background: white;
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.star_time {
  margin-top: 30px;
  border-bottom: 1px solid #ccc;
}
.end_time {
}
.button {
  width: 100%;
  display: flex;
  margin-top: 100px;
  position: fixed;
  bottom: 0;
}

.sureBtn {
  width: 100%;
  text-align: center;
  background-color: rgba(22, 155, 213, 1);
  color: white;
  padding: 10px 0px;
}
</style>

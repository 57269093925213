
import { getUrlParam } from '../getUrl'
import axios from 'axios'
import { Toast } from "vant"

export function logins () {
  let url = window.location
  let corpid = getUrlParam(url, 'organization')
  let code = getUrlParam(url, 'code')
  console.log(code, 'codeeeeee')
  let getUrl = window.location.href
  const params = {
    code: code,
  }
  axios.post(`/api/enterprise/auth/login/${corpid}`, params).then((ress) => {
    if (ress.data.code == 200) {

      console.log(ress.data.data.access_token)
      window.sessionStorage.removeItem('token')
      // window.sessionStorage.setItem('token', ress.data.data.access_token)
      window.localStorage.setItem('token', ress.data.data.access_token)
      window.location.reload()

      // let urlparams = {
      //   url: getUrl,
      // }
      // axios
      //   .post(`/api/enterprise/auth/sign/app`, urlparams, {
      //     headers: { Authorization: `bearer ${ress.data.data.access_token}` },
      //   })
      //   .then((res) => {

      //     if (res.data.code == 200) {
      //       const agentid = window.localStorage.getItem('agentid')

      //       wx.agentConfig({
      //         corpid: corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
      //         agentid: '1000002', // 必填，企业微信的应用id （e.g. 1000247）
      //         timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
      //         nonceStr: res.data.data.nonce_str, // 必填，生成签名的随机串
      //         signature: res.data.data.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
      //         jsApiList: [
      //           'selectExternalContact',
      //           'getCurExternalContact',
      //           'sendChatMessage',
      //           'openUserProfile',
      //         ], //必填，传入需要使用的接口名称
      //         success: function () {
      //           // 回调

      //           wx.invoke('getCurExternalContact', {}, function (res) {
      //             if (res.err_msg == 'getCurExternalContact:ok') {
      //               console.log(res)
      //               window.localStorage.setItem('userid', res.userId)

      //               // uesr_id.value = res.userId //返回当前外部联系人userId
      //               window.location.reload()
      //             } else {
      //               //错误处理
      //             }
      //           })
      //         },
      //         fail: function (res) {
      //           if (res.errMsg.indexOf('function not exist') > -1) {
      //             alert('版本过低请升级')
      //           }
      //         },
      //       })
      //     }

      //   })
      //   .catch((err) => {
      //     // console.log(err.response)
      //   })
    }

    // window.location.reload()
  }).catch((err) => {
    // Toast(err.response.message)
  })

}
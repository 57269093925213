<template>
  <div class="one">
    <div class="steps">
      <div class="warp" v-show="isShow">
        <div class="file" v-for="(item, index) in list" @click="getDetail(item)">
          <div class="file-icon"><img src="../assets/fileicon.png" alt="" /></div>
          <div class="title">
            <div class="tap">{{ item.title }}</div>
            <div class="num">{{ item.child }}条话术</div>
          </div>
          <div class="right">
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
      <div class="back" v-show="!isShow" @click="isShow = !isShow">
        <div class="tips">{{ icon }}</div>
        返回
      </div>
      <div v-show="!isShow" class="title-bar">{{ title_bar }}</div>
      <div class="detail" v-show="!isShow">
        <van-collapse v-model="activeNames">
          <van-collapse-item v-for="(item, index) in rightList" @click="open(index, item)">
            <template #title>
              <div>{{ item.title }}</div>
            </template>
            <div class="content">
              <div class="content-top" v-if="item.type == 1">
                {{ item.content }}
              </div>
              <div class="content-top" v-if="item.type == 2">
                <img :src="item.content" alt="" class="image" />
              </div>
              <div class="content-top" v-if="item.type == 3">
                <video :src="item.content" class="video" autoplay></video>
              </div>
              <div class="content-bottom" @click="send(item.content, item.type, item)">
                一键发送
              </div>
            </div>
          </van-collapse-item>
        </van-collapse>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { defineComponent, ref, computed, onMounted } from 'vue'
import axios from 'axios'
import { getBase64 } from '../utils/urlencodeUrl'

export default defineComponent({
  name: 'own',
  props: {},
  // eslint-disable-next-line
  setup(props) {
    onMounted(() => {
      getList()
    })
    const token = window.localStorage.getItem('token')
    const list = ref()
    const show = ref()
    const detail = ref()
    const activeNames = ref([''])
    const isShow = ref(true)
    const checkId = ref()
    const rightList = ref()
    const mbId = ref(0)
    const title_bar = ref()
    const icon = ref('<')
    function getList() {
      axios
        .post(`/api/enterprise/stypeList`, '', {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          console.log(res.data.data.list)
          list.value = res.data.data.list
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
    }
    function getDetail(item) {
      isShow.value = false
      detail.value = item
      checkId.value = item.id
      title_bar.value = item.title
      getOrder()
    }

    function getOrder() {
      let params = {
        parent_id: checkId.value,
      }
      axios
        .post(`/api/enterprise/stypeContentList`, params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {
          rightList.value = res.data.data.list
          console.log(rightList.value)
        })
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })
    }
    function open(idx, item) {
      mbId.value = idx
    }
    function send(item, type, obj) {
      let params = {
        id: obj.id,
      }
      axios
        .post(`/api/enterprise/addSmartNum`, params, {
          headers: { Authorization: `bearer ${token}` },
        })
        .then((res) => {})
        .catch((err) => {
          if (err.response.data.code == 401) {
            logins()
          } else {
            Toast(err.response.data.message)
          }
        })

      if (type == 1) {
        wx.invoke(
          'sendChatMessage',
          {
            msgtype: 'text', //消息类型，必填
            enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
            text: {
              content: item, //文本内容
            },
          },
          function (res) {
            if (res.err_msg == 'sendChatMessage:ok') {
              Toast('发送成功')
            }
          }
        )
      } else if (type == 2) {
        console.log(item)
        // getBase64(item, (dataURL) => {
        // console.log('dataURL :>> ', dataURL)
        let params = {
          base_img: item,
          type: 3,
        }
        axios
          .post('/api/enterprise/getMediaID', params, {
            headers: { Authorization: `bearer ${token}` },
          })
          .then((res) => {
            if (res.data.code == 200) {
              wx.invoke(
                'sendChatMessage',
                {
                  msgtype: 'image', //消息类型，必填
                  enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
                  image: {
                    mediaid: res.data.data.media_id, //图片的素材id
                  },
                },
                function (res) {
                  if (res.err_msg == 'sendChatMessage:ok') {
                    Toast('发送成功')
                    showModel.value = false
                  }
                }
              )
            }
            // })
          })
      } else {
        console.log(item)
        let params = {
          base_img: item,
          type: 2,
        }
        axios
          .post('/api/enterprise/getMediaID', params, {
            headers: { Authorization: `bearer ${token}` },
          })
          .then((res) => {
            if (res.data.code == 200) {
              wx.invoke(
                'sendChatMessage',
                {
                  msgtype: 'video', //消息类型，必填
                  enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
                  video: {
                    mediaid: res.data.data.media_id, //图片的素材id
                  },
                },
                function (res) {
                  if (res.err_msg == 'sendChatMessage:ok') {
                    Toast('发送成功')
                    showModel.value = false
                  }
                }
              )
            }
          })
      }
    }
    return {
      getList,
      token,
      list,
      show,
      detail,
      getDetail,
      activeNames,
      isShow,
      getOrder,
      rightList,
      mbId,
      open,
      title_bar,
      icon,
      send,
    }
  },
})
</script>

<style scoped>
.one {
  height: 100vh;
}
.wrap {
  width: 100%;
  height: 100%;
  background: #f4f7f8;
}
.warp {
  width: 100%;
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow-x: scroll;
  margin-top: 20px;
}
.file {
  margin-top: 20px;
  width: 90%;
  display: flex;
  height: 70px;
  justify-content: space-evenly;
  background: white;
  align-items: center;
  /* padding: 10px 0px; */
}
.file-icon {
  width: 10%;
}
img {
  width: 36px;
  height: 30px;
}

.title {
  width: 50%;
}
.right {
  width: 10%;
  text-align: right;
}
.tap {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;

  font-size: 14px;
}
.num {
  color: #333333;

  font-size: 12px;
}
.detail {
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
}
.back {
  margin-top: 20px;
  margin-left: 20px;
  display: flex;
}
.tips {
  background: #0052fe;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: white;
  text-align: center;
  line-height: 20px;
  margin-right: 5px;
}
.title-bar {
  margin-top: 20px;
  margin-left: 20px;
  color: #777777;
}
.content {
  /* display: flex;
  flex-wrap: wrap;
  justify-content: end; */
}
.content-top {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #585858;
  line-height: 24px;
}
.content-bottom {
  width: 79px;
  height: 29px;
  background: #0052fe;
  border-radius: 15px;
  color: white;
  text-align: center;
  line-height: 29px;
  margin-top: 10px;
  margin-left: 200px;
}
.image {
  width: 100%;
  height: 100%;
}
.video {
  width: 100%;
  height: 300px;
}
</style>

<style>
:root {
  --van-tabs-bottom-bar-color: #3b0aee !important;
}
.van-collapse-item {
  margin-bottom: 20px;
}
</style>
